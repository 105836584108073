import { render, staticRenderFns } from "./OverScreen.vue?vue&type=template&id=292e9fe2&scoped=true"
import script from "./OverScreen.vue?vue&type=script&lang=js"
export * from "./OverScreen.vue?vue&type=script&lang=js"
import style0 from "./OverScreen.vue?vue&type=style&index=0&id=292e9fe2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "292e9fe2",
  null
  
)

export default component.exports