<template>
  <div id="modal-template" type="text/x-template">
    <div class="modal-mask" tabindex="-1" @keydown.27="close">
      <div class="modal-wrapper">
        <div class="container modal-container">
          <div id="modal">
            <section v-if="showHeader" class="modal-header">
              <h4 class="modal-title">
                <strong>{{ titulo }}</strong>
              </h4>
              <button class="btn-transparent modal-close-btn" @click="close()">
                <i class="fa fa-times close-desktop"></i>
                <i class="fa fa-chevron-down close-mobile"></i>
              </button>
            </section>
            <section class="modal-body">
              <slot name="body"> Nenhum conteúdo </slot>
            </section>
            <section v-if="showFooter" class="modal-footer">
              <slot name="footer">
                <button class="btn btn-primary">Fechar</button>
              </slot>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import bus from "@/utils/events/bus";

export default {
  props: {
    titulo: {
      type: String,
      required: false,
    },

    showModal: false,
    showHeader: {
      default: true,
    },
    showFooter: {
      default: true,
    },
    header: false,
  },
  data() {
    return {
      loadingModal: false,
    };
  },
  watch: {
    showModal() {
      const self = this;
      this.$nextTick(() => {
        self.reziseModal();
      });
    },
  },
  created() {
    bus.$emit("modalIsOpen", true);
    document.body.classList.add("no-scroll");
  },
  beforeDestroy() {
    document.body.classList.remove("no-scroll");

    this.$nextTick(() => {
      bus.$emit("modalIsOpen", false);
    });
  },
  mounted() {
    const self = this;

    $(".modal-mask").click((e) => {
      if (e.target._prevClass && e.target._prevClass == "modal-wrapper") {
        self.close();
      }
    });

    $(".modal-mask").focus();
    $(".modal-body *:input[type!=hidden]:first").focus();

    bus.$on("loadingmodal", (e) => {
      self.loadingModal = e;
    });

    this.$nextTick(() => {
      self.reziseModal();
    });

    window.addEventListener("resize", () => {
      self.reziseModal();
    });
  },
  methods: {
    close() {
      this.$emit("close");
    },
    reziseModal() {
      if (
        this.showModal &&
        document.getElementById("modal-body-content") != null
      ) {
        const windowHeight = window.innerHeight - 150;
        if (document.getElementById("modal-body-content") != null) {
          document.getElementById(
            "modal-body-content"
          ).style.maxHeight = `${windowHeight}px`;
        }
      }
    },
    /*
     * Função/Metodo de atalho de teclas
     * Chamado por: Evento 'teclaPrecionada'.
     * @params: e => keyPress
     */
    atalhosDeTecla(e) {
      const self = this;

      if (self.showModal) {
        // Fechar modal - ESC
        if (e.keyCode == 27) {
          if (self.showModal) {
            self.fechar();
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

.modal-mask {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.5s ease;
  overflow-y: scroll;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  transition: all 0.3s;
}

#modal {
  // position: absolute;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.29) 1px 1px 10px -1px;
  // color: rgb(164, 164, 164);
  // font-weight: normal;
  // width: 530px;
  // right: 0px;
  // z-index: 9999;
  border-radius: 6px;
}

.modal-header {
  padding: 15px;
  margin: 0px;
  border-bottom: 1px solid gainsboro;
  color: rgb(102, 102, 102);
  font-weight: 700;
  display: flex;

  .modal-title {
    margin: 0px;
    flex: 1 1 0%;
    line-height: 24px;
  }

  .modal-close-btn {
    background: transparent;
    border: none;
  }
  .close-mobile {
    display: none;
  }
}

.modal-body {
  padding: 15px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 550px;
}

.modal-footer {
  padding: 15px;
  background: rgb(241, 241, 241);
  display: flex;
  border-radius: 0px 0px 6px 6px;
  justify-content: flex-end;
}

//Responsive
@media (max-width: 520px) {
  #modal {
    border-radius: 0;
    height: 100%;
    position: absolute;
    width: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
  }
  .modal-body {
    height: calc(100% - 125px);
    max-height: unset;
  }
  .close-mobile {
    display: block !important;
  }
  .close-desktop {
    display: none;
  }
}
</style>

//
<style lang="scss" scoped>
// @import "~@/assets/css/sass/bootstrap/variables";
// #modal-loading {
//   cursor: wait;
//   position: absolute;
//   background: #ffffffad;
//   z-index: 20;
//   width: 100%;
//   height: 100%;

//   .lds-roller {
//     width: 64px;
//     margin: auto;
//     top: 40%;
//     position: relative;
//     height: 64px;
//     left: -15px;
//   }
//   .lds-roller div {
//     animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
//     transform-origin: 32px 32px;
//   }
//   .lds-roller div:after {
//     content: " ";
//     display: block;
//     position: absolute;
//     width: 6px;
//     height: 6px;
//     border-radius: 50%;
//     background: linear-gradient(to right, #00773d -15%, $brand-primary; 100%);
//     margin: -3px 0 0 -3px;
//   }
//   .lds-roller div:nth-child(1) {
//     animation-delay: -0.036s;
//   }
//   .lds-roller div:nth-child(1):after {
//     top: 50px;
//     left: 50px;
//   }
//   .lds-roller div:nth-child(2) {
//     animation-delay: -0.072s;
//   }
//   .lds-roller div:nth-child(2):after {
//     top: 54px;
//     left: 45px;
//   }
//   .lds-roller div:nth-child(3) {
//     animation-delay: -0.108s;
//   }
//   .lds-roller div:nth-child(3):after {
//     top: 57px;
//     left: 39px;
//   }
//   .lds-roller div:nth-child(4) {
//     animation-delay: -0.144s;
//   }
//   .lds-roller div:nth-child(4):after {
//     top: 58px;
//     left: 32px;
//   }
//   .lds-roller div:nth-child(5) {
//     animation-delay: -0.18s;
//   }
//   .lds-roller div:nth-child(5):after {
//     top: 57px;
//     left: 25px;
//   }
//   .lds-roller div:nth-child(6) {
//     animation-delay: -0.216s;
//   }
//   .lds-roller div:nth-child(6):after {
//     top: 54px;
//     left: 19px;
//   }
//   .lds-roller div:nth-child(7) {
//     animation-delay: -0.252s;
//   }
//   .lds-roller div:nth-child(7):after {
//     top: 50px;
//     left: 14px;
//   }
//   .lds-roller div:nth-child(8) {
//     animation-delay: -0.288s;
//   }
//   .lds-roller div:nth-child(8):after {
//     top: 45px;
//     left: 10px;
//   }
//   @keyframes lds-roller {
//     0% {
//       transform: rotate(0deg);
//     }
//     100% {
//       transform: rotate(360deg);
//     }
//   }
// }
// .modal-mask {
//   position: fixed;
//   z-index: 1002;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5);
//   display: table;
//   transition: opacity 0.5s ease;
//   overflow-y: scroll;
// }

// .modal-mask:focus {
//   outline: none;
// }

// .modal-wrapper {
//   display: table-cell;
//   vertical-align: middle;
//   transition: all 0.3s;
// }

// .modal-container {
//   margin-top: 2%;
//   margin-bottom: 40px;
//   width: 60%;
//   margin: 0px auto;
//   background-color: white;
//   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
//   transition: all 0.3s ease;
//   font-family: "Open Sans", "Avenir", Helvetica, Arial, sans-serif !important;
//   //max-height: 600px;
// }

// .modal-top-header {
//   padding-top: 5px;
//   padding-bottom: 5px;
//   border-bottom: 1px solid #cecece;
//   background: #eee;
//   color: $gray-dark;
//   //position: fixed;
//   //width: 50%;
//   z-index: 9999999;
// }

// .row-modal-content {
//   overflow-y: auto;
//   overflow-x: hidden;
// }

// .border-header,
// .border-footer {
//   position: absolute;
//   margin-top: 34px;
//   z-index: 9999999999;
//   height: 3px;
//   width: 40%;
//   background: linear-gradient(to right, $green-primary 1%, #19b067 100%);
// }

// .border-footer {
//   margin-top: 0;
// }

// .modal-header {
//   padding: 0px;
//   transition: all 0.3s;
// }

// h3 {
//   margin: 0 !important;
//   font-size: 12px !important;
//   margin-top: 0 !important;
//   color: $gray-darker;
// }

// .body-content {
//   //overflow-x: hidden;
//   max-height: 500px;
//   transition: all 0.3s;
// }

// .modal-body {
//   //   overflow-x: hidden !important;
//   //   max-height: 500px;
//   //padding-top: 28px;
//   padding: 0px;
//   padding-top: 8px;
//   transition: all 0.3s;
// }

// .modal-footer {
//   padding: 10px;
//   border: 0;
//   background: $gray-pale;
//   border-top: 01px solid #e6e6e6;
// }

// .modal-enter {
//   opacity: 0;
// }

// .modal-leave-active {
//   opacity: 0;
// }

// .modal-enter .modal-container,
// .modal-leave-active .modal-container {
//   -webkit-transform: scale(1.1);
//   transform: scale(1.1);
// }

// /* FADE */
// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 0.2s;
// }
// .fade-enter,
// .fade-leave-to {
//   opacity: 0;
//   transition: opacity 0.2s;
// }

// /* SLIDER FADE */
// .slide-fade-enter-active {
//   transition: all 0.3s ease;
// }
// .slide-fade-leave-active {
//   transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
// }
// .slide-fade-enter,
// .slide-fade-leave-to {
//   transform: translateX(20px);
//   opacity: 0;
// }

// /* ANIMAÇÃO BOUNCE */
// .bounce-enter-active {
//   animation: bounce-in 0.5s;
// }
// .bounce-leave-active {
//   animation: bounce-in 0.5s reverse;
// }
// @keyframes bounce-in {
//   0% {
//     transform: scale(0);
//     opacity: 0;
//   }
//   50% {
//     transform: scale(1.2);
//     opacity: 0.5;
//   }
//   100% {
//     transform: scale(1);
//   }
// }

// .button {
//   padding-right: 15px;
//   text-align: right;
//   color: #666;

//   & button {
//     background: 0;
//     border: 0;
//   }
// }

// .titulo-modal {
//   text-align: left;
//   font-size: 14px;
//   color: #666;
//   margin-top: 2px;
// }

// .box-header {
//   padding-top: 6px;
//   padding-bottom: 6px;
//   padding-left: 10px;
//   padding-right: 10px;
//   background: #f6f6f6;
//   border-bottom: 1px solid #e5e8e8;
// }

// .null {
//   border: 0;
//   //padding-top: 20px;
// }

// @media (max-width: 1000px) {
//   .modal-container {
//     width: 90%;
//     //max-height: 600px !important;
//   }

//   .border-header {
//     width: 90%;
//   }

//   .modal-body {
//     //max-height: 500px;
//   }
// }
//
</style>
