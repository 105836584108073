<template>
  <div v-if="mySliders && mySliders[0].length > 0" id="slider-full">
    <div v-if="isLoading" id="loading-slider-full">
      <div class="skeleton slider-full">
        <div class="skeleton-wrapper">
          <div class="skeleton-wrapper-inner">
            <div class="skeleton-wrapper-body">
              <div class="skeleton-slider-full slider-full"></div>
            </div>
          </div>
        </div>
        <div class="skeleton-wrapper">
          <div class="skeleton-wrapper-inner">
            <div class="skeleton-wrapper-body">
              <div class="skeleton-slider-full slider-full"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else :key="sliderKey">
      <VueSlickCarousel
        v-for="(slider, index) in mySliders"
        :key="index + 'sd1'"
        class="slider-full img-full"
        v-bind="slideSettings"
      >
        <div v-for="(item, idx) in slider" :key="idx + 'sd2'">
          <div v-if="checkLink(item.link)">
            <a :href="item.link">
              <!-- <img
                rel="preload"
                fetchpriority="high"
                :src="item.src"
                class="img-responsive"
                alt
                @error="
                  imagesWithError.push(item.src);
                  sliderKey = Math.random();
                "
              /> -->

              <ImageItem
                custom-class="img-responsive"
                :source="item.src"
                :fetch-priority="idx == 0 ? 'high' : 'auto'"
                @error="
                  imagesWithError.push(item.src);
                  sliderKey = Math.random();
                "
              />
            </a>
          </div>
          <div v-else>
            <img
              fetchpriority="high"
              :src="item.src"
              class="img-responsive"
              alt
            />
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

import ImageItem from "@/components/image/ImageItem";

export default {
  name: "SliderFull",
  components: { VueSlickCarousel, ImageItem },
  props: {
    sliders: {
      type: Array,
      default: [],
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      imagesWithError: [],
      slideSettings: {
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        autoplay: true,
        autoplaySpeed: 4000,
      },
      sliderKey: 1,
    };
  },
  computed: {
    mySliders(e) {
      if (!this.sliders) return [];

      return this.sliders.map((slider) =>
        slider.filter((item) => !this.imagesWithError.includes(item.src))
      );
    },
  },
  watch: {},
  mounted() {
    // this.handleScriptSlide();
  },
  updated() {
    // this.handleScriptSlide();
  },
  methods: {
    checkLink(itemLink) {
      if (itemLink != "./" && itemLink != "") {
        return true;
      }
      return false;
    },
    // handleScriptSlide() {
    //   const $slider = $(".slider-full");

    //   if (
    //     $slider.hasClass("slick-initialized") &&
    //     this.mySLiders &&
    //     this.mySLiders[0].length <= 0
    //   ) {
    //     $slider.slick("unslick");
    //   }

    //   this.$nextTick(() => {
    //     $slider.not(".slick-initialized").slick({
    //       infinite: true,
    //       speed: 300,
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       dots: true,
    //       autoplay: true,
    //       autoplaySpeed: 4000,
    //     });
    //   });
    // },
  },
};
</script>
<style>
.slider-full .image__item {
  width: 1920px;
}
</style>
<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";
.slider-full {
  max-width: 1920px;
}
.slider-full,
.slider-full img {
  width: 100%;
  z-index: 0;
}
</style>

<style>
.slider-full button.slick-prev {
  left: 10px;
}

.slider-full button.slick-next {
  right: 10px;
}

#slider-full ul.slick-dots {
  bottom: 0px;
}

/* @media (min-width: 768px) {
  .img-mobile {
    display: none;
  }
}
*/
@media (max-width: 767px) {
  /*   .img-full {
    display: none;
  }
 */
  button.slick-next,
  button.slick-prev {
    display: none !important;
  }
}

.skeleton-wrapper {
  background: #fff;
  border-radius: 4px;
  -webkit-border-radius: 4px;
}

@media (min-width: 521px) {
  .skeleton-wrapper-inner {
    height: 65px;
    padding: 65px;
    position: relative;
  }
}

@media (max-width: 520px) {
  .skeleton-wrapper-inner {
    height: 120px;
    padding: 120px;
    position: relative;
  }
}

@media (max-width: 768px) {
  .skeleton-wrapper-inner {
    height: 100px;
    padding: 100px;
    position: relative;
  }
}

@media (min-width: 769px) {
  .skeleton-wrapper-inner {
    height: 198px;
    padding: 198px;
    position: relative;
  }
}

.skeleton-wrapper-body div {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderSkeleton;
  -webkit-animation-timing-function: linear;
  background: #f6f7f8;
  background-image: -webkit-gradient(
    linear,
    left center,
    right center,
    from(#f6f7f8),
    color-stop(0.2, #edeef1),
    color-stop(0.4, #f6f7f8),
    to(#f6f7f8)
  );
  background-image: -webkit-linear-gradient(
    left,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: auto;
  height: auto;
  position: relative;
}
.skeleton-wrapper-body {
  -webkit-animation-name: skeletonAnimate;
  background-image: -webkit-gradient(
    linear,
    center top,
    center bottom,
    from(deg),
    color-stop(0, red),
    color-stop(0.15, orange),
    color-stop(0.3, yellow),
    color-stop(0.45, green),
    color-stop(0.6, blue),
    color-stop(0.75, indigo),
    color-stop(0.8, violet),
    to(red)
  );
  background-image: -webkit-linear-gradient(
    135deg,
    red 0%,
    orange 15%,
    yellow 30%,
    green 45%,
    blue 60%,
    indigo 75%,
    violet 80%,
    red 100%
  );
  background-repeat: repeat;
  background-size: 50% auto;
}
.skeleton-wrapper-body div {
  position: absolute;
  right: 15px;
  left: 15px;
  top: 15px;
}
div.skeleton-slider-full {
  top: 0px;
  height: 100%;
  left: 0px;
  right: 0px;
  width: 100%;
}
@-webkit-keyframes placeholderSkeleton {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 1200px;
  }
}
@-webkit-keyframes skeletonAnimate {
  from {
    background-position: top left;
  }
  to {
    background-position: top right;
  }
}
</style>
