<template>
  <div class="card__save">
    <div class="card__save-title">
      <h1>CARTÕES SALVOS</h1>
    </div>
    <div class="container-cards">
      <label
        v-for="card in cards"
        :key="card.card_id"
        :for="card.card_id"
        class="card__save-box container-label"
        :class="{ selected: selectedCard == card.card_id, [card.brand]: true }"
        @click="selectCard(card.card_id)"
      >
        <div class="card-info">
          <div>
            <input :id="card.card_id" type="radio" name="checkboxCards" />
            <span class="checkmark"></span>
          </div>
          <div>
            <p>{{ card.holderName }}</p>
            <p>{{ toTitleCase(card.brand) }} **** {{ card.desc }}</p>
            <p>{{ card.expiration }}</p>
          </div>
        </div>
        <div>
          <img
            class="flagCard"
            :src="
              require(`@/assets/img/pagamento/${getCardBrandImage(
                card.brand
              )}.svg`)
            "
            alt
          />
        </div>
      </label>
      <div class="flex-commun cvvAndPacels">
        <label for="cvv">CVV</label>
        <input id="cvv" v-model="cvv" type="text" required maxlength="4" />
      </div>
      <div class="cardOptions__bottom">
        <div>
          <button class="btn-newCard" @click="$emit('addNewCard')">
            Novo cartão
          </button>
        </div>
        <div class="cvvAndPacels">
          <div class="flex-commun">
            <label
              for="parcelNumber"
              class="numberParcels"
              @click="getSelected()"
              >Número de parcelas</label
            >
            <select id="parcelNumber" name="parcelNumber" disabled>
              <option :value="paymentData.cardParcels" selected>
                {{ paymentData.cardParcels }}x de R$
                {{ mascaraValor(paymentAmount / paymentData.cardParcels) }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mascaraValor, toTitleCase } from "@/app/global";

export default {
  name: "SaveCard",
  props: {
    paymentAmount: {
      required: false,
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      cards: [],
      selectedCard: null,
      cvv: "",
    };
  },
  computed: {
    paymentData() {
      return this.$store.getters.paymentData;
    },
  },
  watch: {
    cvv() {
      this.$store.dispatch("cvvCardSave", this.cvv);
    },
  },
  mounted() {
    this.getCards();
    // this.getSelected();
  },
  methods: {
    mascaraValor,
    toTitleCase,
    getCardBrandImage(brand) {
      switch (brand) {
        case "visa":
        case "mastercard":
          return brand;
          break;

        default:
          return "nota";
          break;
      }
    },
    getCards() {
      if (this.$store.getters.saveCards.length > 0) {
        this.cards = this.$store.getters.saveCards;
      }
    },
    selectCard(id) {
      this.selectedCard = id;
      this.$store.dispatch("savedCardSelected", id);

      // this.selectedCard = document.getElementsByName("checkboxCards");
      // for (let index = 0; index < this.selectedCard.length; index++) {
      //   const select = this.selectedCard[index];
      //   if (select.checked) {
      //     this.$store.dispatch("savedCardSelected", select.id);
      //   }
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";
.card__save {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.card__save-title h1 {
  all: unset;
  padding-top: 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0.02em;

  color: #000000;
}
.btn-newCard {
  all: unset;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: center;
  width: 207px;
  height: 31px;
  padding: 7px;
  background: var(--brand-primary, $brand-primary);
  color: #fff;
  border-radius: 2px;
  cursor: pointer;
  transition: 200ms ease-in;
}

.btn-newCard:hover {
  background-color: #276f47;
}

.container-cards {
  display: flex;
  flex-wrap: wrap;
}
.card__save-box {
  position: relative;
  user-select: none;
  border-radius: 5px;
  display: flex;
  flex: 1 1 calc(50% - 10px);
  box-sizing: border-box;
  height: 65px;
  padding: 15px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  cursor: pointer;
  background: radial-gradient(
    ellipse farthest-corner at 0 140%,
    #afafaf 0%,
    #969696 70%,
    #8a8a8a 70%
  );
  margin: 5px;
  // transition: 0.35s;

  // &:hover {
  //   transform: scale(1.02);
  // }

  &.selected {
    &.visa {
      background: radial-gradient(
        ellipse farthest-corner at 0 140%,
        #274b82 0%,
        #27586f 70%,
        #245167 70%
      );
    }

    &.mastercard {
      background: radial-gradient(
        ellipse farthest-corner at 0 140%,
        #a5311e 0%,
        #bf8d43 70%,
        #af7d35 70%
      );
    }

    &.hipercard {
      background: radial-gradient(
        ellipse farthest-corner at 0 140%,
        #ad2525 0%,
        #842c2c 70%,
        #752828 70%
      );
    }

    &.elo {
      background: radial-gradient(
        ellipse farthest-corner at 0 140%,
        #324348 0%,
        #424242 70%,
        #313131 70%
      );
    }

    &.amex {
      background: radial-gradient(
        ellipse farthest-corner at 0 140%,
        #276082 0%,
        #27686f 70%,
        #24616d 70%
      );
    }
  }
}
.card-info {
  display: flex;
}
.card-info div {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.card-info input {
  margin-right: 30px;
}
.card-info p {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 40px;
}
.card__save-box p {
  color: #fff;
}
.card__save-box img {
  width: 45px;
}

.cardOptions__bottom {
  width: 100%;
  margin-top: 30px;
}
.cvvAndPacels {
  width: 100%;
  margin-top: 30px;
  display: flex;
}

.cvvAndPacels label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #000000;
}

.cvvAndPacels input {
  width: 79px;
  height: 40px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
}

.flex-commun {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
}

.cvvAndPacels select {
  width: 350px;
  height: 40px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 1px 2px;
}

/* STILE TESTES */
/* The container */

/* Hide the browser's default radio button */
.container-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border-radius: 50%;
  margin-left: 20px;
}

/* On mouse-over, add a grey background color */
.container-label:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container-label input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-label .checkmark:after {
  top: 5.1px;
  left: 5.1px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: white;
}

/** Responsividade **/

@media (max-width: 1215px) {
  // .card-info {
  //   font-size: 12px;
  // }
  .card-info div {
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: flex-start;
  }
  .flagCard {
    margin-right: 10px;
  }
  .checkmark {
    left: -12px;
  }
  .card__save-box {
    padding: unset;
    width: 45%;
    margin-right: 20px;
  }
}
@media (max-width: 1000px) {
  .card__save-title {
    margin-top: 20px;
  }
  .card__save-box {
    padding: unset;
    width: 45%;
    margin-right: 20px;
  }
  .container-cards {
    justify-content: space-between;
  }
  .container-label {
    margin-right: 0px;
    padding: 15px;
  }
}
@media (max-width: 600px) {
  .card__save-box {
    padding: unset;
    width: 100%;
    margin-right: 0px;
  }
  #parcelNumber {
    width: 300px;
  }
}
@media (max-width: 438px) {
  #parcelNumber {
    width: 200px;
  }
}
@media (max-width: 342px) {
  #parcelNumber {
    width: 150px;
  }
}
</style>
