<template>
  <div class="card-list">
    <content-box title="Método de pagamento">
      <div slot="body" class="cl-body">
        <div class="cb-card-list">
          <item-card
            v-for="(card, index) in myCards"
            :id="card.card_id"
            :key="card.card_id"
            ref="itemCard"
            class="cl-my-card"
            :logo="getLogo(card.brand)"
            :desc="`${toTitleCase(card.brand)} **** ${card.desc}`"
            :title="card.holderName"
            :selected="card.card_id === cardSelected"
            :class="{
              selected: card.card_id === cardSelected,
              [card.brand]: true,
              'form-shake-error': errorMessage,
            }"
            :show-radio="true"
            :validation-failed="errorMessage"
            @itemSelected="handleSelectedCard"
          >
            <template slot="action">
              <small class="cl-delete-card" @click="removeCard(card.card_id)">
                <i class="fa fa-trash"></i>
                <span>Remover</span>
              </small>
            </template>
          </item-card>
        </div>
      </div>
      <div slot="footer" class="cl-footer">
        <alert-box
          v-if="errorMessage"
          type="error"
          :message="errorMessage"
        ></alert-box>

        <div class="cf-navigation">
          <button
            class="btn btn-outline btn-secondary-outline"
            @click="goToAddCard"
          >
            <i class="fa fa-credit-card"></i>
            &#32;
            <span>Novo cartão</span>
          </button>
          <button
            class="btn btn-primary"
            :disabled="errorMessage"
            @click="validateAndGoToReview"
          >
            <span>Continuar</span>
            &#32;
            <i class="fa fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </content-box>
  </div>
</template>

<script>
import AlertBox from "@/app/checkout/components/AlertBox";
import ContentBox from "@/app/checkout/components/ContentBox";
import ItemCard from "./ItemCard";
import { toTitleCase } from "@/app/global";
import bus from "@/utils/events/bus";

import CartMixin from "@/app/checkout/cart/CartMixin";

export default {
  name: "CardList",
  components: {
    AlertBox,
    ContentBox,
    ItemCard,
  },
  mixins: [CartMixin],
  data() {
    return {
      errorMessage: null,
    };
  },
  computed: {
    myCards() {
      return this.$store.getters.saveCards;
    },
    cardSelected() {
      if (!this.paymentData || !this.paymentData.card) return;
      return this.paymentData.card.card_id;
    },
  },
  // async activated() {
  //   if (this.myCards.length == 0) {
  //     await this.getMyCards();
  //   }
  // },
  watch: {
    cardSelected(newValue) {
      if (newValue && this.errorMessage) {
        this.errorMessage = null;
      }
    },
    paymentMethod(newValue) {
      if (newValue != this.PAYMENT_METHODS.CREDIT_CARD) {
        this.$router.push({
          name: "checkoutReview",
          query: { ...this.$route.query },
        });
      }
    },
  },
  async mounted() {
    if (this.myCards.length <= 0) {
      await this.getMyCards();
    }
  },
  deactivated() {
    // this.resetPage();
    this.$destroy();
  },
  methods: {
    toTitleCase,
    getLogo(brand) {
      try {
        return require(`@/assets/img/pagamento/${brand}.png`);
      } catch (e) {
        // console.error(e);
      }
    },
    async removeCard(cardId) {
      if (!cardId) return;
      if (!window.confirm("Tem certeza que deseja excluir este cartão?")) {
        return;
      }

      const loadingId = Date.now() + Math.random();
      this.$store.dispatch("setShowLoadingModal", [true, loadingId]);

      try {
        const url = `${process.env.VUE_APP_ECOMMERCE}${api.env.BUSCA_CARTAO_SALVO}/${cardId}`;

        const { data } = await dpcAxios.connection().delete(url, {
          data: { gatewayId: this.dadosPedido.gatewayPagamento },
        });

        if (!data.error) {
          bus.$emit("show-notification", [
            "success",
            "Sucesso!",
            "Cartão removido.",
          ]);

          this.$nextTick(() => {
            this.handleSelectedCard(0);

            this.$store.dispatch("removeStoredCard", cardId);
          });
        } else {
          throw new Error(data.message);
        }
      } catch (e) {
        bus.$emit("show-notification", [
          "error",
          "Ops!",
          "Não foi possível remover o cartão",
        ]);
      }

      this.$store.dispatch("setShowLoadingModal", [false, loadingId]);
    },
    handleSelectedCard(cardId) {
      let savedCardData = this.myCards.find((x) => x.card_id === cardId);

      if (!savedCardData) {
        savedCardData = {
          brand: null,
          card_id: null,
          desc: null,
          expiration: null,
          holderName: null,
        };
      }
      // Se o usuário trocar o cartão selecionado, precisamos limpar o cvv e solicitar de novo.
      if (cardId != this.cardSelected) {
        savedCardData.cardCvv = null;
      }

      this.$store.dispatch("paymentData", {
        card: { ...this.paymentData.card, ...savedCardData },
      });
    },
    async getMyCards() {
      const loadingId = Date.now() + Math.random();
      this.$store.dispatch("setShowLoadingModal", [true, loadingId]);

      try {
        const url = `${process.env.VUE_APP_ECOMMERCE}${api.env.BUSCA_CARTAO_SALVO}`;

        const { data } = await dpcAxios.connection().get(url, {
          params: { gatewayId: this.dadosPedido.gatewayPagamento },
        });

        if (!data.error && data.cards && data.cards.length > 0) {
          this.$store.dispatch("saveCards", data.cards);

          // If there's only one card then select it automatically :)
          if (this.myCards.length == 1) {
            this.handleSelectedCard(this.myCards[0].card_id);
          }
        } else {
          throw new Error(data.message);
        }
      } catch (e) {
        this.goToAddCard();
      }

      this.$store.dispatch("setShowLoadingModal", [false, loadingId]);
    },
    goToAddCard() {
      this.$router.push({
        name: "checkoutAddCard",
        query: { ...this.$route.query },
      });
    },
    validateAndGoToReview() {
      if (!this.cardSelected) {
        this.errorMessage = "Por favor, selecione uma opção para continuar.";
        return;
      }

      this.$router.push({
        name: "checkoutValidateStoredCard",
        query: { ...this.$route.query },
      });
    },
    resetPage() {
      this.errorMessage = null;
      // this.handleSelectedCard(null);
    },
  },
};
</script>

<style lang="scss">
.card-list {
  .cl-body {
    flex: 1;
    display: flex;
    flex-direction: column;

    .cb-card-list {
      flex: 1;
      display: flex;
      flex-flow: wrap;
      margin: 0 -5px 0 -5px;

      .cl-my-card {
        color: #4a4a4a;
        font-weight: bold;
        // flex: 0 1 50%;

        &.selected {
          color: white;

          &.visa {
            background: radial-gradient(
              ellipse farthest-corner at 0 140%,
              #274b82 0%,
              #27586f 70%,
              #245167 70%
            );
          }

          &.mastercard {
            background: radial-gradient(
              ellipse farthest-corner at 0 140%,
              #a5311e 0%,
              #bf8d43 70%,
              #af7d35 70%
            );
          }

          &.hipercard {
            background: radial-gradient(
              ellipse farthest-corner at 0 140%,
              #ad2525 0%,
              #842c2c 70%,
              #752828 70%
            );
          }

          &.elo {
            background: radial-gradient(
              ellipse farthest-corner at 0 140%,
              #324348 0%,
              #424242 70%,
              #313131 70%
            );
          }

          &.amex {
            background: radial-gradient(
              ellipse farthest-corner at 0 140%,
              #276082 0%,
              #27686f 70%,
              #24616d 70%
            );
          }
        }
      }

      .cl-delete-card {
        font-size: 12.5px;
        -webkit-font-smoothing: antialiased;
        font-weight: unset;
        opacity: 0.9;
        display: flex;

        i {
          display: flex;
          width: 16px;
          height: 16px;
          align-items: center;
          justify-content: center;
        }
        span {
          // display: none;
        }

        &:hover {
          opacity: 1;

          span {
            display: unset;
          }
        }
      }
    }
  }

  .cl-footer {
    display: flex;
    flex-direction: column;

    .cf-navigation {
      display: flex;
      justify-content: flex-end;

      .btn {
        margin: 0 4px;
        padding: 10px 40px;
      }
    }
  }
}

@media (max-width: 525px) {
  .cl-footer {
    .cf-navigation {
      .btn {
        flex: 1;
      }
    }
  }
}

@media only screen and (max-width: 633px) {
  .card-list {
    .cl-footer {
      .cf-navigation {
        align-self: center;
      }
    }
  }
}
</style>
