<template>
  <div v-if="$store.getters.categorias.length > 0" class="bg-menu">
    <div class="container container-menu">
      <div id="navbar" class="navbar-collapse">
        <ul class="nav navbar-nav">
          <li
            class="menu-horizontal all-category"
            title="Clique para visualizar todas as categorias"
          >
            <a
              id="menu-principal"
              href="#"
              onclick="return false;"
              class="categorias"
              @click="toggleMenuCategorias()"
            >
              <i class="fa fa-bars fa-2x ic-dep bar" aria-hidden="true"></i>
              <span class="tit">Todas as categorias</span>
              <!-- <i class="fa fa-angle-down" aria-hidden="true"></i> -->
            </a>

            <!-- <ul class="menu clearfix">
              <li class="dropdown" tabindex="-1">
                <a
                  href="#"
                  onclick="return false;"
                  class="categorias"
                  id="menu-principal"
                  @click="toggleMenuCategorias()"
                >
                  <i class="fa fa-bars fa-2x ic-dep bar" aria-hidden="true"></i>
                  <span class="tit">Categorias</span>
                  <!-- <i class="fa fa-angle-down" aria-hidden="true"></i> -->
            <!-- </a> -->
            <!-- <submenu-tree
                  :itemList="$store.getters.categorias"
                ></submenu-tree> -->

            <!-- Nível 1 -->
            <!-- submenu -->
            <!-- <ul class="sub-menu">
                  <li
                    v-for="(o, index) in $store.getters.categorias"
                    :key="o.id"
                    :data-cod-categoria="o.id"
                    v-if="index <= limitationList"
                  >
                    <a href="#" onclick="return false;" class="text-capitalize">
                      {{ o.descricao }}
                      <i
                        aria-hidden="true"
                        class="fa fa-angle-right ic-dep arrow"
                      ></i>
                    </a>
                    <div class="sub-menu painel">
                    <BoxSubCategorias
                        :categoria="o"
                        :sub-categorias="o.filhos"
                      ></BoxSubCategorias> 
                    </div>
                  </li>
             
                  <li data-cod-categoria="TDCTGAS">
                    <a href="#">
                      <strong>Todas as Categorias</strong>
                      <i
                        aria-hidden="true"
                        class="fa fa-angle-right ic-dep arrow"
                      ></i>
                    </a>
                    <div class="sub-menu painel">
                      <BoxSubCategorias
                        :categoria="infoBoxTodasCategorias[0]"
                        :sub-categorias="this.$store.getters.categorias"
                        :tipoSubCategoria="'CAT'"
                      ></BoxSubCategorias>
                    </div>
                  </li>
                </ul> 
                <!-- submenu -->
            <!-- </li> -->
            <!-- </ul> -->
          </li>

          <!-- Novo pedido mobile  -->
          <li
            v-if="isAuthenticated && !hasPedidoAberto"
            class="menu-horizontal text-capitalize novo-pedido-mobile"
          >
            <a @click="novoPedidoClick()">Novo Pedido</a>
          </li>
          <li
            v-for="cat in $store.getters.componente_categorias"
            :key="cat.categoria.codigo"
            class="menu-horizontal text-capitalize"
          >
            <a
              @click="
                menuClick(cat.categoria.integracao_id, cat.categoria.descricao)
              "
              >{{
                cat.categoria.descricao.charAt(0).toUpperCase() +
                cat.categoria.descricao.slice(1).toLowerCase()
              }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!--/.nav-collapse -->
</template>

<script>
import BoxSubCategorias from "@/components/root/header/BoxSubCategorias";
import bus from "@/utils/events/bus";

import { getProdUrlDesc } from "@/app/global";

import SubmenuTree from "./SubmenuTree";

export default {
  name: "HeaderMenu",
  components: { BoxSubCategorias, SubmenuTree },
  data() {
    return {
      limitationList: 10,
      infoBoxTodasCategorias: [
        JSON.parse('{"codigo": "", "descricao": "Todas as categorias"}')
      ]
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.getIsAuthenticated;
    },
    hasPedidoAberto() {
      return Object.keys(this.$store.getters.dadosPedido).length !== 0;
    }
  },
  beforeMount() {
    this.loadComponenteCategorias();
  },
  methods: {
    toggleMenuCategorias() {
      bus.$emit("toggleHeaderMenuResponsible");
    },
    loadComponenteCategorias() {
      if (this.$store.getters.componente_categorias.length === 0) {
        // Get all categories from component
        dpcAxios
          .connection()
          .post(
            `${
              process.env.VUE_APP_ECOMMERCE +
              api.env.BUSCA_COMPONENTES_CATEGORIAS
            }`,
            { with_categorias: true }
          )
          .then((response) => {
            const componentesCategorias = response.data;
            componentesCategorias.sort((item, prev) => item.ordem - prev.ordem);
            this.$store.dispatch(
              "setCategoriasComponente",
              componentesCategorias
            );
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
    menuClick(cod_categoria, descricao) {
      this.$router.push({
        name: "catalogoRota",
        query: {
          // busca: this.$route.query.busca,
          // page: this.$route.query.page,
          categoria: cod_categoria,
          fornecedor: [],
          sub: [],
          ordem: this.$route.query.ordem,
          cmb: this.$route.query.cmb,
          apenas_ofertas: this.$route.query.apenas_ofertas,
          apenas_promocionados: this.$route.query.apenas_promocionados
        },
        params: {
          // categoriaName: getProdUrlDesc(descricao),
          //  categoria: cod_categoria,
        }
      });
    },
    novoPedidoClick() {
      // this.$router.push({
      //   name: "catalogoRota",
      //   query: {
      //     //busca: this.$route.query.busca,
      //     // categoria: null,
      //     fornecedor: [],
      //     sub: [],
      //     ordem: this.$route.query.ordem,
      //   },
      // });

      // Abre o modal para seleção de parametros de pedido para iniciar novo pedido
      bus.$emit("openModalParametros", true);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

a {
  cursor: pointer;
}

.ic-dep {
  padding: 0px 5px 0px 5px;
}

.categorias span,
i {
  color: var(--brand-accent, $brand-accent);
}

.categorias {
  transition: all 0.3s ease-in-out;
  padding: 0 14px 0px 8px;
}

.bar {
  margin-top: 12px;
  margin-right: 4px;
  display: block;
  float: left;
  font-size: 16px;
}

.tit {
  line-height: 41px;
  font-weight: 700;
}

.arrow {
  padding-bottom: 10px;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  line-height: 0;
  font-size: 0;
  visibility: hidden;
  overflow: hidden;
}

.menu {
  > li {
    display: inline-block;
    //white-space: nowrap;
  }

  > li:hover {
    background: #fff;
  }

  li:hover > .sub-menu {
    visibility: visible;
    display: block;
  }

  a {
    display: block;
    color: #fff;
  }
}
.categorias:hover {
  // background-color: var(--brand-primary, $brand-primary) !important;
  // background-color: var(--brand-secondary, $brand-accent) !important;
}
.categorias:hover span,
.categorias:hover i {
  color: white !important;
}

.menu,
.sub-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

// .sub-menu {
//   visibility: hidden;
//   display: none;
//   position: absolute;
//   width: 100%;
//   min-width: 180px;
//   background: #fff;
//   color: #444;
//   border-right: 00px solid #eee;
//   box-shadow: 0 5px 14px rgba(0, 0, 0, 0.15);
//   -webkit-box-shadow: 0 5px 14px rgba(0, 0, 0, 0.15);
//   -moz-box-shadow: 0 5px 14px rgba(0, 0, 0, 0.15);
//   -ms-box-shadow: 0 5px 14px rgba(0, 0, 0, 0.15);
//   -o-box-shadow: 0 5px 14px rgba(0, 0, 0, 0.15);
//   // border-radius: 0px 0px 5px 0px;

//   .sub-menu {
//     width: auto; //height: 100%;
//     top: 0;
//     height: 432px;
//     max-height: 500px;
//     left: 100%;
//     padding: 0px 13px;
//     z-index: -2;
//     // -webkit-transform: translateX(-17em);
//     // -ms-transform: translateX(-17em);
//     // transform: translateX(-17em);
//     display: block;
//     opacity: 0;
//   }

//   li:hover {
//     background: #f1f1f1;

//     .sub-menu {
//       overflow: hidden;
//       opacity: 1;
//       // -webkit-transform: translateX(0em);
//       // -ms-transform: translateX(0em);
//       // transform: translateX(0em);
//       // -webkit-transition: all 0.2s ease;
//       // -moz-transition: all 0.2s ease;
//       // -ms-transition: all 0.2s ease;
//       // -o-transition: all 0.2s ease;
//       // transition: all 0.2s ease;

//       &.painel {
//         z-index: -1;
//         // -webkit-transition-delay: 0.2s;
//         // -moz-transition-delay: 0.2s;
//         // -ms-transition-delay: 0.2s;
//         // -o-transition-delay: 0.2s;
//         // transition-delay: 0.2s;
//       }
//     }
//   }

//   a {
//     display: block;
//     padding: 6px 8px 6px 15px;
//     color: #444 !important;
//     border-bottom: 1px solid #eee;

//     i {
//       color: #444 !important;
//       float: right;
//       margin-top: 4px;
//     }
//   }
// }

.bg-menu {
  background-color: var(--brand-primary, $brand-primary);
  position: relative;
  width: 100%;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
  padding: 0 25px;

  &.menu-scroll {
    -webkit-transform: translateY(-43px);
    -moz-transform: translateY(-43px);
    -ms-transform: translateY(-43px);
    -o-transform: translateY(-43px);
    transform: translateY(-43px);
  }

  &:hover {
    -webkit-transform: translateY(0px) !important;
    -moz-transform: translateY(0px) !important;
    -ms-transform: translateY(0px) !important;
    -o-transform: translateY(0px) !important;
    transform: translateY(0px) !important;
  }
}

.container-menu {
  padding-left: 0;
  font-size: 14px;
  max-width: unset !important;

  #navbar {
    padding-left: 0;
  }
}

.painel {
  height: 100%;
  position: absolute;
  max-height: 100%;
}

.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;

  color: var(--brand-accent, $brand-accent) !important;

  // i {
  //   color: $green-darker;
  // }
}

.navbar-nav {
  display: -webkit-box;
  display: -webkit-inline-box;

  .menu-horizontal:nth-of-type(1n + 11) {
    // display: none;
  }

  .menu-horizontal-opcoes {
    font-weight: 600;
  }

  li a {
    color: #fff;
    z-index: 9;
    line-height: 23px;
  }

  li.all-category a:after,
  li.all-category a:before {
    display: none;
  }

  li a:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60px;
    height: 60px;
    margin-left: -30px;
    margin-top: -30px;
    background: 0 0;
    border-radius: 100%;
    opacity: 0.6;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    transition: all 2s ease;
    background-color: #fff;
    visibility: hidden;
    z-index: -1;
  }

  li a:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: $brand-primary;
    opacity: 0;
    box-sizing: border-box;
    -webkit-transform: scaleX(0.1);
    transform: scaleX(0.1);
    transition: all 0.7s ease;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    z-index: -1;
  }

  li a:active:before,
  li a:hover:before {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  li a:not(:active):after {
    -webkit-animation: h_ripple 2s ease-out;
    animation: h_ripple 2s ease-out;
  }
}

@media (max-width: 1980px) {
  .navbar-nav .menu-horizontal:nth-of-type(1n + 17) {
    display: none !important;
  }
}

@media (max-width: 1900px) {
  .navbar-nav .menu-horizontal:nth-of-type(1n + 16) {
    display: none !important;
  }
}

@media (max-width: 1800px) {
  .navbar-nav .menu-horizontal:nth-of-type(1n + 15) {
    display: none !important;
  }
}
@media (max-width: 1640px) {
  .navbar-nav .menu-horizontal:nth-of-type(1n + 14) {
    display: none !important;
  }
}
@media (max-width: 1540px) {
  .navbar-nav .menu-horizontal:nth-of-type(1n + 13) {
    display: none !important;
  }
}
@media (max-width: 1400px) {
  .navbar-nav .menu-horizontal:nth-of-type(1n + 12) {
    display: none !important;
  }
}

@media (max-width: 1294px) {
  .navbar-nav .menu-horizontal:nth-of-type(1n + 11) {
    display: none !important;
  }
}

@media (max-width: 1210px) {
  .navbar-nav .menu-horizontal:nth-of-type(1n + 10) {
    display: none !important;
  }
}
@media (min-width: 1000px) and (max-width: 1100px) {
  .navbar-nav .menu-horizontal:nth-of-type(1n + 9) {
    display: none !important;
  }
}

.item-submenu:hover #overscreen {
  visibility: visible;
}

.dropdown {
  z-index: 9;
}

// .navbar-nav > .active > a {
//   background-color: transparent;
//   color: #fff;
// }

.novo-pedido-mobile {
  display: none;
}

@media (max-width: 520px) {
  .bg-menu {
    padding: 0 5px;
  }
}

@media (max-width: 991px) {
  .bg-menu {
    background-color: var(--brand-primary, $brand-primary);
    border: none;
  }
  .container-menu {
    padding: 0;
    margin: 0;

    #navbar {
      margin: 0;
      padding: 0;

      .navbar-nav {
        margin: 0;
        padding: 0;
        display: flex;
        height: 40px;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        border-top: 1px solid $brand-primary;

        .novo-pedido-mobile {
          display: block;
          a {
            color: var(--brand-accent, $brand-accent);
          }
        }
        & > li > a {
          color: #fff;
          padding: 10px 15px;

          &:before {
            background-color: $brand-primary;
          }
        }

        &::-webkit-scrollbar {
          display: none;
          height: 3px;
        }

        .all-category {
          display: none;
        }
      }
    }
  }
}
</style>
