import { render, staticRenderFns } from "./credit-card.vue?vue&type=template&id=31d71317&scoped=true"
import script from "./credit-card.vue?vue&type=script&lang=js"
export * from "./credit-card.vue?vue&type=script&lang=js"
import style0 from "./credit-card.vue?vue&type=style&index=0&id=31d71317&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31d71317",
  null
  
)

export default component.exports