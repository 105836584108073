<template>
  <div id="slider-campanha">
    <div class="slider-produtos-home">
      <ItemProduto v-for="(item, index) in produtos" :key="index" :item="item">
      </ItemProduto>
    </div>
  </div>
</template>
<script>
import ItemProduto from "@/components/produtos/ItemProduto";

export default {
  name: "SliderCampanha",
  components: { ItemProduto },
  props: {
    produtos: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      twoRows: true,
    };
  },
  mounted() {
    this.handleRowsSlide();
    $(".slider-produtos-home")
      .not(".slick-initialized")
      .slick({
        dots: true,
        infinite: true,
        rows: this.twoRows ? 2 : 1,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3,
        // autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 760,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
  },
  created() {
    localStorage.setItem("visualizationMode", "lista-1");
    this.$store.dispatch("setModoVisualizacao", "lista-1");
  },
  methods: {
    handleRowsSlide() {
      if (this.produtos.length <= 6) {
        this.twoRows = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/css/sass/bootstrap/variables";

#slider-campanha {
  .item-produto {
    flex: initial;
  }
  .slick-list {
    overflow: initial;
  }
  .slick-track {
    margin-left: initial;
    margin-right: initial;
  }
  .slick-dots {
    position: inherit;
  }
  .lista-1 {
  }
  .item-produto {
    .produto-content {
      min-height: 170px;

      width: 100%;
      .informacoes {
        .produto-price-container {
          flex-direction: row !important;
          justify-content: space-between;
          align-items: center;
        }
        // .item-qty {
        //   border-radius: 4px;
        // }
      }
      .item-qty {
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px 0px !important;
      }
    }
  }

  width: 100%;
  .title-session {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    span {
      text-align: center;
      font-family: "Montserrat";
      font-weight: 700;
      font-size: 2.25rem;
      color: #303932;
      border-bottom: solid 3px var(--brand-primary, $brand-primary);
      line-height: 3rem;
    }
  }
}

@media (max-width: 768px) {
  #slider-campanha {
    .title-session {
      span {
        font-size: 2rem;
      }
    }
  }
}
</style>

<style>
#slider-campanha .slick-next {
  right: -20px;
}

#slider-campanha .slick-prev {
  left: -20px;
}
</style>
