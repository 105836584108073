// original above

import store from "@/vuex";

import * as dpcAxios from "@/dpcAxios";

export default async (to, from, next) => {
  const usuario = store.getters.getUsuarioInfo;
  if (!store.getters.getIsAuthenticated && Object.keys(usuario).length === 0) {
    try {
      store.dispatch(
        "setClienteAtual",
        localStorage.getItem("clienteAtual") || 0
      );

      const { data } = await dpcAxios
        .connection()
        .get(`${process.env.VUE_APP_ECOMMERCE}ec/minha-conta/usuario`);

      if (!data.usuario.novasenha) {
        // this.showModalChangePassword = true;
        store.dispatch("setUserShouldResetPassword", true);
      }

      localStorage.setItem("loggedUser", JSON.stringify(data.usuario));

      // dispatch setUsuarioInfo to vuex
      store.dispatch("setUsuarioInfo", data.usuario);

      store.dispatch("setIsAuthenticated", true); // busca aqui dados do clinete e seta isso se estiver lgoado. Depois, em outro lugar, busca os dados do pedido aberto. Não travar o render da págian por isso. Coloca um loading no carrinho pra dizer que tá buscando dados do pedido aberto, ou algo do tipo.
      gtag("set", { user_id: data.usuario.usuario_id }); // Set the user ID using signed-in user_id.
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      store.dispatch("setIsAuthenticated", false);
      store.dispatch("setUsuarioInfo", { undefined: "undefined" });
    }
  }

  if (
    !store.getters.getIsAuthenticated &&
    to.meta &&
    to.meta.requiresAuthentication
  ) {
    window.location.href = "/login";
    return;
  }

  if (store.getters.isDpcMobileWebView && !to.query.open_in_webview) {
    // Post a message to the WebView to open the URL in the stack navigation
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "open_url_in_stack",
          url: to.fullPath, // window.location.href, // or to.fullPath if you want to include the query and hash
          params: to.params,
        })
      );
    return;
  }

  next();
};
