var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.informacoes.length != '')?_c('div',{attrs:{"id":"institucional"}},[_c('section',{attrs:{"id":"slider-destaque-empresa"}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',[_vm._m(0),_c('img',{staticClass:"img-responsive",attrs:{"loading":"lazy","src":_vm.loadImage('empresa/point-bg.png', '0'),"alt":""}})])])])]),_c('section',{attrs:{"id":"sobre-empresa"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12"},[_c('h3',{staticClass:"session-header"},[_vm._v("Sobre a DPC")]),_c('div',{staticClass:"text-justify sobre-dpc"},[_vm._v(" "+_vm._s(_vm.informacoes[0].sobre)+" ")])]),(false)?_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-6"},[_vm._m(1)]):_vm._e()])])]),(
      _vm.informacoes[0].missao != null ||
      _vm.informacoes[0].visao != null ||
      _vm.informacoes[0].valores != null
    )?_c('section',{attrs:{"id":"missao-visao-valores"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row conteudo"},[(_vm.informacoes[0].missao != null)?_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"item-content"},[_vm._m(2),_vm._v(" "+_vm._s(_vm.informacoes[0].missao)+" ")])]):_vm._e(),(_vm.informacoes[0].visao != null)?_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"item-content green-info"},[_vm._m(3),_vm._v(" "+_vm._s(_vm.informacoes[0].visao)+" ")])]):_vm._e(),(_vm.informacoes[0].valores != null)?_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"item-content"},[_vm._m(4),_vm._v(" "+_vm._s(_vm.informacoes[0].valores)+" ")])]):_vm._e()])])]):_vm._e(),_vm._m(5),_c('section',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row",attrs:{"id":"section-logistica"}},[_c('div',{staticClass:"col-md-6"},[_c('SliderFull',{attrs:{"sliders":_vm.slider_logistica}})],1),_c('div',{staticClass:"col-md-6 text-logistica"},[_c('h1',{staticClass:"session-header"},[_vm._v("Logística")]),_vm._v(" "+_vm._s(_vm.informacoes[0].logistica)+" ")])])])]),_c('section',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.informacoes[0].area_atuacao && _vm.informacoes[0].parcerias),expression:"informacoes[0].area_atuacao && informacoes[0].parcerias"}],staticClass:"container-fluid container-fluid-gray"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row",attrs:{"id":"atuacao-parceiras"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.informacoes[0].area_atuacao),expression:"informacoes[0].area_atuacao"}],staticClass:"col-md-6"},[_vm._m(6),_vm._v(" "+_vm._s(_vm.informacoes[0].area_atuacao)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.informacoes[0].parcerias),expression:"informacoes[0].parcerias"}],staticClass:"col-md-6"},[_vm._m(7),_vm._v(" "+_vm._s(_vm.informacoes[0].parcerias)+" ")])])])])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('h1',{staticClass:"session-header"},[_c('small',[_vm._v("Sobre a")]),_c('br'),_c('strong',[_vm._v("DPC Distribuidor")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"panel panel-view jumbotron-theme"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-6"},[_c('div',{staticClass:"panel-body text-center"},[_c('div',{staticClass:"video-placeholder-lg"},[_c('div',{staticClass:"youtubeVideo"},[_c('iframe',{attrs:{"src":"https://www.youtube.com/embed/ECw_rM7A5eM?rel=0&controls=0&showinfo=0","frameborder":"0","allowfullscreen":""}})])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"session-header green-info"},[_c('i',{staticClass:"fa fa-shield",attrs:{"aria-hidden":"true"}}),_vm._v(" Missão ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"session-header green"},[_c('i',{staticClass:"fa fa-binoculars",attrs:{"aria-hidden":"true"}}),_vm._v(" Visão ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"session-header green-info"},[_c('i',{staticClass:"fa fa-trophy",attrs:{"aria-hidden":"true"}}),_vm._v(" Valores ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"container"},[_c('hr')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"session-header"},[_c('i',{staticClass:"fa fa-briefcase",attrs:{"aria-hidden":"true"}}),_vm._v(" Área de atuação ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"session-header"},[_c('i',{staticClass:"fa fa-users",attrs:{"aria-hidden":"true"}}),_vm._v(" Parcerias ")])
}]

export { render, staticRenderFns }