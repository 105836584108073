var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner-section flex items-center justify-center"},[_c('section',{class:`
      default
      flex flex-col
      items-center
      justify-center
      max-width-section
      w-full
      ${_vm.margin}
    `},[(_vm.showTitle)?_c('span',{staticClass:"flex items-center justify-center"},[_c('h1',{staticClass:"text-default-title-xl text-center mb-10 mt-0 pb-2 border-b-4 border-green-custom font-bold border-green-custom"},[_vm._v(" "+_vm._s(_vm.data.title)+" ")])]):_vm._e(),_c('div',{class:`grid grid-cols-${_vm.cols} grid-rows-${_vm.rows} w-full ${_vm.gap}`},_vm._l((_vm.data),function(image,index){return _c('a',{key:index,class:`w-banner ${_vm.rules[index] ? _vm.rules[index].col : _vm.rules[0].col} ${
          _vm.rules[index] ? _vm.rules[index].row : _vm.rules[0].row
        }`,attrs:{"href":image.link}},[_c('ImageItem',{attrs:{"custom-class":"w-full rounded-default","source":image.src}})],1)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }