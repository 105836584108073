export default {
  setItensFiltro(state, item) {
    state.itens_selecionados = item;
  },
  addItemFiltro(state, item) {
    state.itens_selecionados.push(item);
  },
  addItemFiltroByCodigoTipo(state, item) {
    if (item.tipo == "FOR") {
      state.parceiros.forEach((element) => {
        if (element.codigo == item.codigo) {
          state.itens_selecionados.push({
            ...element,
            ...{ titulo: "Fornecedores", tipo: item.tipo },
          });
        }
      }, this);
    } else if (item.tipo == "CAS") {
      state.itens_selecionados.push({
        ...{
          titulo: "Combo",
          descricao: "Combos",
          tipo: item.tipo,
        },
      });
    } else if (item.tipo == "OFT") {
      state.itens_selecionados.push({
        ...{
          titulo: "Ofertas",
          descricao: "Ofertas",
          tipo: item.tipo,
        },
      });
    } else if (item.tipo == "BON") {
      state.itens_selecionados.push({
        ...{
          titulo: "Packs Promocionais",
          descricao: "Packs Promocionais",
          tipo: item.tipo,
        },
      });
    }
    if (item.tipo == "MAR") {
      state.itens_selecionados.push({
        ...{
          titulo: "Marca",
          codigo: item.codigo,
          descricao: item.codigo,
          tipo: item.tipo,
        },
      });
    } else {
      // SUB
      let found = false;

      // PESQUISANDO NAS CATEGORIAS
      state.categorias.forEach((cat, i) => {
        if (cat.codigo == item.cod_categoria) {
          if (typeof cat.filhos === "undefined") {
            return;
          }

          // PESQUISANDO DENTRO DAS SUB CATEGORIAS DESTA CATEGORIA
          found = cat.filhos.forEach((sub) => {
            if (sub.codigo == item.codigo) {
              state.itens_selecionados.push({
                ...sub,
                ...{ titulo: "SubCategorias", tipo: item.tipo },
              });

              return true;
            }
          }, this);

          // SE TIVER ENCONTRADO A SUB CATEGORIA
          if (found) return;
        }
      });
    }
  },
  removeItemFiltro(state, item) {
    const itemSelectedIndex = state.itens_selecionados.findIndex(
      (x) => x.tipo === item.tipo && x.codigo === item.codigo
    );
    if (itemSelectedIndex > -1) {
      state.itens_selecionados.splice(itemSelectedIndex, 1);
    }
  },
  setCategoriaSelecionada(state, item) {
    state.categoria_selecionada = item;
  },
  setCategoriaSelecionadaByCodigo(state, codigo) {
    const categories = state.categorias;

    if (!categories || categories.length == 0) {
      state.categoria_selecionada = [];
      return;
    }

    const cat = categories.find((x) => x.codigo == codigo);
    if (cat) {
      state.categoria_selecionada = cat;
    } else {
      state.categoria_selecionada = [];
    }
  },
  removeCategoriaSelecionada(state) {
    state.categoria_selecionada = [];
  },
  removeFiltro(state) {
    state.itens_selecionados = [];
  },
  setCategorias(state, item) {
    state.categorias = item;
  },
  setCategoriasComponente(state, item) {
    state.componente_categorias = item;
  },
  setParceiros(state, item) {
    state.parceiros = item;
  },
  setParceirosFiltrados(state, item) {
    state.parceirosFiltrados = item;
  },

  setMarcasFiltradas(state, item) {
    state.marcasFiltradas = item;
  },

  setModoVisualizacao(state, item) {
    state.modoVisualizacao = item;
  },
  setCartaoSucesso(state, item) {
    state.cartaoSucesso = item;
  },
  setCartaoErro(state, item) {
    state.cartaoErro = item;
  },
};
