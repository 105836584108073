import { render, staticRenderFns } from "./ProductVariantButton.vue?vue&type=template&id=82844b16"
import script from "./ProductVariantButton.vue?vue&type=script&lang=js"
export * from "./ProductVariantButton.vue?vue&type=script&lang=js"
import style0 from "./ProductVariantButton.vue?vue&type=style&index=0&id=82844b16&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports