<template>
  <div
    class="product-variant"
    :class="{ 'product-variant--preview': isPreview }"
  >
    <div v-if="!isPreview">
      <span>{{ variantAttributes.descricao }}: </span>
      <template v-if="selectedAttr">
        <span class="text-primary">{{ selectedAttr.descricao }}</span>
      </template>
    </div>

    <div
      v-if="variantAttributes.atributos.length > 1"
      class="product-variant-attr"
      :class="{ 'product-variant-attr--preview': isPreview }"
    >
      <VueSlickCarousel v-if="isPreview" v-bind="slideSettings">
        <product-variant-button
          v-for="(attribute, index) in variantAttributes.atributos"
          :key="index"
          :description="attribute.descricao"
          :image="attribute.imagem"
          :active="
            selectedAttr && selectedAttr.descricao == attribute.descricao
          "
          :disabled="!attrAvailable(attribute)"
          :inactive="
            (attribute.produtos && attribute.produtos.length == 0) ||
            !attribute.produtos
          "
          @click.native="$emit('variantSelected', attribute)"
          @mouseenter.native="toggleImagePreview(attribute)"
          @mouseleave.native="toggleImagePreview(null)"
        />
      </VueSlickCarousel>

      <product-variant-button
        v-for="(attribute, index) in variantAttributes.atributos"
        v-else-if="variantAttributes.tipo == 'button' && !isPreview"
        :key="index"
        :description="attribute.descricao"
        :image="attribute.imagem"
        :active="selectedAttr && selectedAttr.descricao == attribute.descricao"
        :disabled="!attrAvailable(attribute)"
        :inactive="
          (attribute.produtos && attribute.produtos.length == 0) ||
          !attribute.produtos
        "
        @click.native="$emit('variantSelected', attribute)"
        @mouseenter.native="toggleImagePreview(attribute)"
        @mouseleave.native="toggleImagePreview(null)"
      />

      <product-variant-dropdown
        v-else-if="variantAttributes.tipo == 'dropdown' && !isPreview"
        :attributes="variantAttributes.atributos"
        :value="selectedAttr"
        @input="$emit('variantSelected', $event)"
      />
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import ProductVariantButton from "./ProductVariantButton.vue";
import ProductVariantDropdown from "./ProductVariantDropdown.vue";

import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  name: "ProductVariant",
  components: {
    ProductVariantButton,
    VueSlickCarousel,
    ProductVariantDropdown,
  },
  props: {
    selectedVariants: {
      type: Array,
      default: () => [],
    },
    variantAttributes: {
      type: Object,
      default: () => {},
    },
    numberOfVariants: {
      type: Number,
      default: 0,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    isFirstVariant: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      slideSettings: {
        dots: false,
        infinite: true,
        speed: 100,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        dots: false,
        responsive: [],
      },
    };
  },
  computed: {
    selectedAttr() {
      return this.selectedVariants[this.variantAttributes.id];
    },
  },

  mounted() {},
  methods: {
    toggleImagePreview(attr) {
      if (!attr) return this.$emit("imagePreview", null);

      if (!attr || !attr.produtos || !this.variantAttributes.exibe_img) return;

      const attrProducts = attr.produtos.map((p) => p.imagem);

      if (this.numberOfVariants <= 1 || this.isPreview) {
        return this.$emit("imagePreview", attrProducts[0]);
      }

      const selectedProducts = this.selectedVariants
        .filter((x, i) => i != this.variantAttributes.id)
        .map((p) => p.produtos);

      const selectedProductsIds = selectedProducts
        .map((p) => p && p.map((p) => p.imagem))
        .flat();

      const attrProductsInSelected = attrProducts.filter((p) =>
        selectedProductsIds.includes(p)
      );

      if (!attrProductsInSelected) return;

      this.$emit("imagePreview", attrProductsInSelected[0]);
    },
    attrAvailable(attr) {
      if (!attr) return;
      if (
        this.numberOfVariants <= 1 ||
        this.isPreview ||
        this.selectedVariants.length <= 0 ||
        (this.isFirstVariant &&
          attr.produtos.length > 0 &&
          process.env.VUE_APP_PRODUCT_VARIATION_MODE == 1)
      ) {
        return true;
      }

      const attrProducts = attr.produtos
        .filter((p) => !!p.possuiestoque)
        .map((p) => p.id);

      const selectedProducts = this.selectedVariants.map((p) => p.produtos);

      const selectedProductsIds = selectedProducts

        .map((p) => p && p.map((p) => p.id))
        .flat();

      const attrProductsInSelected = attrProducts.filter((p) =>
        selectedProductsIds.includes(p)
      );

      return attrProductsInSelected.length > 0;
    },
  },
};
</script>

<style lang="scss">
.product-variant-button {
  .product-variant--preview {
    margin: 0 -15px;
  }
}

.product-variant-dropdown {
  .product-variant--preview {
    width: 100%;
  }
}

.product-variant--preview {
  .slick-track {
    display: flex;
    gap: 3px;
  }

  .slick-list {
    margin: 0 32.5px;
  }

  .slick-dots {
    display: none !important;
  }

  .slick-prev,
  .slick-next {
    margin: 0 -3.5px;
    width: 30px;
    height: 30px;
    opacity: 1;
    background: unset;
    display: unset !important;

    &:hover,
    &:focus,
    &:active {
      background: unset;
      box-shadow: unset;
    }
  }
}

@media (max-width: 767px) {
  button.slick-next,
  button.slick-prev {
    margin: 0 -5.5px !important;
    width: 15px !important;
    height: 30px !important;
  }

  button.slick-next {
    right: 0 !important;
  }
  button.slick-prev {
    left: 0 !important;
  }

  .product-variant--preview .slick-list {
    margin: 0 13.5px !important;
  }

  .product-variant-button {
    min-width: 30px !important;
    padding: 4px !important;
  }
  .product-variant-button .product-variant-button-img-wrapper {
    height: 28px !important;
  }
}
</style>

<style lang="scss" scoped>
.product-variant {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 14px;
  margin-bottom: 10px;
}

.product-variant-attr {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

  // &--preview {
  //   flex-wrap: nowrap;
  //   overflow: auto;
  // }
}

.product-variant-attr--preview::-webkit-scrollbar {
  height: 7px;
}
</style>
