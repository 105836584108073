var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition-group',{staticClass:"sub-menu",class:{ 'deep-category': _vm.activeMenu.nivel >= 1 },attrs:{"tag":"ul","name":"slide"}},[(_vm.activeMenu.father)?_c('button',{key:_vm.activeMenu.id,attrs:{"id":"voltar-menu"},on:{"click":function($event){return _vm.navigateTo(_vm.activeMenu.father.id)}}},[_c('i',{staticClass:"fa fa-arrow-left",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.capitalize(_vm.activeMenu.father.descricao))+" ")]):_vm._e(),(_vm.activeMenu.descricao)?_c('li',{key:_vm.activeMenu.descricao,on:{"click":function($event){return _vm.$emit('closeside')}}},[_c('router-link',{staticStyle:{"font-weight":"bold"},attrs:{"tag":"a","to":{
        name: 'catalogoRota',
        query: { categoria: _vm.activeMenu.integracao_id },
      }}},[_vm._v(" Tudo em "+_vm._s(_vm.capitalize(_vm.activeMenu.descricao))+" ")])],1):_vm._e(),_vm._l((_vm.activeMenu.filhos),function(item,index){return _c('li',{key:item.id,staticClass:"text-capitalize"},[(item.filhos)?_c('a',{attrs:{"href":"#","onclick":"return false;"},on:{"click":function($event){return _vm.navigateTo(item.id)}}},[_vm._v(" "+_vm._s(_vm.capitalize(item.descricao))+" "),_c('i',{staticClass:"fa fa-angle-right ic-dep arrow",attrs:{"aria-hidden":"true"}})]):_c('span',{on:{"click":function($event){return _vm.$emit('closeside')}}},[_c('router-link',{attrs:{"tag":"a","to":{
          name: 'catalogoRota',
          query: { categoria: item.integracao_id },
        }}},[_vm._v(" "+_vm._s(_vm.capitalize(item.descricao))+" ")])],1)])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }