<template>
  <button class="place_order_btn" :disabled="loading" @click="placeOrder()">
    <template v-if="loading">
      <i class="fa fa-spinner fa fa-spin"></i>
      <span>CARREGANDO</span>
    </template>
    <span v-else>{{ btnText }}</span>
  </button>
</template>

<script>
export default {
  name: "PlaceOrderBtn",
  props: {
    loading: {
      required: false,
      type: Boolean,
      default: false,
    },
    btnText: {
      required: false,
      type: String,
      default: "FINALIZAR",
    },
  },
  data() {
    return {};
  },
  methods: {
    placeOrder() {
      this.$emit("placeOrder");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

.place_order_btn {
  // all: unset;
  border: none;
  width: 100%;
  height: 50px;
  background: var(--brand-primary, $brand-primary);
  border-radius: 3px;
  text-align: center;
  color: #fff;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  letter-spacing: 0.1em;
  transition: 200ms ease-in;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
    background-color: var(--brand-primary, $brand-primary) !important;
  }

  &:hover {
    background: darken($brand-primary, 20%) !important;
  }
}

@media (max-width: 375px) {
  .place_order_btn {
    // width: 150px;
  }
}
</style>
