<template>
  <div>
    <!-- MODAL FILTROS -->
    <modal
      :show-modal="showModal"
      class="modal-casadinha"
      :titulo="'Combo'"
      @close="close()"
    >
      <!-- MODAL FILTRO CONTENT -->
      <div slot="body">
        <div class="modal-container">
          <div class="container-separador produto-selecionado">
            <h5
              v-if="produtosSelecionados.length && !observacao"
              class="title-casadinhas"
            >
              {{ descricaoCondicaoPremio }}
            </h5>
            <div v-if="observacao" style="white-space: pre-line">
              <!-- <span>Regras:</span> -->
              <p>
                {{ observacao }}
              </p>
            </div>
            <div class="container-produto-casadinha">
              <div
                v-for="(item, index) in produtosSelecionados"
                :key="item.id"
                class="container-separador produto-premio"
              >
                <router-link
                  class="produto"
                  tag="a"
                  :to="{
                    name: 'produtoRota',
                    params: { codigo: integracao_id },
                  }"
                  @click.native="close"
                >
                  <img loading="lazy" :src="item.src" />
                  <h5>{{ item.descricao }}</h5>
                  <h5>Qtd. Min. {{ item.qtdmincompra }}</h5>
                  <button class="btn-view">
                    <i class="fa fa-chevron-right" />
                  </button>
                </router-link>
              </div>
              <div v-if="!produtosSelecionados.length" class="pagina-vazia">
                <h3>Oops!</h3>
                <p>Nenhum produto por aqui!</p>
                <p>
                  Tente voltar para a página anterior, clicando abaixo, para
                  visualizar os produtos dessa combo
                </p>
              </div>
            </div>

            <div
              v-show="produtosSelecionados.length >= limit || offset != 0"
              class="pagination-casadinha"
            >
              <button @click="shouldChangePage('prev')">
                <h5 v-show="offset != 0">Página anterior</h5>
              </button>
              <button
                v-show="produtosSelecionados.length >= limit"
                @click="shouldChangePage('next')"
              >
                <h5>Próxima página</h5>
              </button>
            </div>
          </div>
          <hr />

          <div
            v-for="(item, index) in produtosPremio"
            :key="item.premioid"
            class="container-separador produto-premio col-md-12"
          >
            <h5 v-if="index == 0 && !observacao" class="title-casadinhas">
              Você ganha os seguintes produtos
            </h5>
            <router-link
              class="produto"
              tag="a"
              :to="{ name: 'produtoRota', params: { codigo: item.id } }"
              @click.native="close"
            >
              <img loading="lazy" :src="item.src" />
              <h5>{{ item.descricao }}</h5>
              <h5>Qtd. {{ item.qtdpremio }}</h5>
              <button class="btn-view">
                <i class="fa fa-chevron-right" />
              </button>
            </router-link>
          </div>
          <br />
        </div>
        <div v-if="error" class="error-message">
          <p class="error-text">
            {{ mensagem }}
          </p>
        </div>
      </div>
      <div slot="footer"></div>
    </modal>
  </div>
</template>

<script>
import modal from "@/components/root/modal/Modal";
import bus from "@/utils/events/bus";
import { mascaraValor } from "@/app/global";

export default {
  name: "ModalCasadinha",
  components: { modal },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    produto_id: {
      type: Number,
      default: 0,
    },
    integracao_id: {
      type: Number,
      default: 0,
    },
    descricao: {
      type: String,
      default: "",
    },
    src: {
      type: String,
      default: "",
    },
    embqtdean: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      produtosPremio: [],
      produtosSelecionados: ["teste"],
      dadosPedido: {},
      error: 0,
      mensagem: "",
      casadinha_id: null,
      limit: 4,
      offset: 0,
      descricaoCondicaoPremio: "",
      observacao: null,
    };
  },
  mounted() {
    // Se existir parametros do pedido no store
    this.dadosPedido =
      Object.keys(this.$store.getters.dadosPedido).length !== 0
        ? this.$store.getters.dadosPedido
        : false;

    this.buscaDadosCasadinha();
  },
  methods: {
    mascaraValor,
    /*
     * Função: Method resposável por emitir o evento de fechar o modal de parametros.
     * Chamada por: Evento '@close' do componente 'Modal'.
     * */
    close() {
      this.$emit("close");
    },

    /**
     * Função que busca dados do item enviado ao modal e busca casadinha
     */

    async shouldChangePage(action) {
      if (action == "prev") {
        this.offset -= this.limit;
      } else if (action == "next") {
        this.offset += this.limit;
      }

      const payload = {
        casadinha_id: this.casadinha_id,
        limit: this.limit,
        offset: this.offset,
      };

      const responseSelecionados = await dpcAxios
        .connection()
        .post(
          `${process.env.VUE_APP_ECOMMERCE}ec/casadinha/produto/showall`,
          payload
        );

      const { produtos } = responseSelecionados.data;

      this.produtosSelecionados = produtos;
    },

    async buscaDadosCasadinha() {
      let payload = {};
      let parametros = {};

      parametros = {
        pedido_id: this.dadosPedido.pedidoId,
      };

      if (Object.keys(parametros).length !== 0) {
        payload = { ...payload, ...parametros };
      } else {
        bus.$emit("show-notification", [
          "error",
          "Desculpe.",
          "Ocorrou um erro inesperado ao buscar o combo.",
        ]);
        return;
      }

      if (this.produto_id != null && this.produto_id != "") {
        payload = { ...payload, produto_id: this.produto_id };
      }

      const loadingId = Date.now() + Math.random();
      this.$store.dispatch("setShowLoadingModal", [true, loadingId]);

      try {
        const response = await dpcAxios
          .connection()
          .post(
            `${process.env.VUE_APP_ECOMMERCE}ec/casadinha/showall`,
            payload
          );

        const { casadinhas } = response.data;

        this.descricaoCondicaoPremio = casadinhas[0].descricao;

        this.casadinha_id = casadinhas[0].id;
        this.observacao = casadinhas[0].observacao;

        payload = {
          casadinha_id: casadinhas[0].id,
          limit: this.limit,
          offset: this.offset,
        };

        const responseSelecionados = await dpcAxios
          .connection()
          .post(
            `${process.env.VUE_APP_ECOMMERCE}ec/casadinha/produto/showall`,
            payload
          );

        const { produtos } = responseSelecionados.data;

        this.produtosSelecionados = produtos;

        payload = {
          ...payload,
          limit: this.limit,
          offset: 0,
        };

        const responsePremio = await dpcAxios
          .connection()
          .post(
            `${process.env.VUE_APP_ECOMMERCE}ec/casadinha/premio/showall`,
            payload
          );

        const { premios } = responsePremio.data;
        this.produtosPremio = premios;
      } catch (e) {
        console.error(e);
        bus.$emit("show-notification", [
          "error",
          "Desculpe.",
          "Ocorrou um erro inesperado ao buscar o combo.",
        ]);
      }

      this.$store.dispatch("setShowLoadingModal", [false, loadingId]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/_variables.scss";
.modal-container {
  display: flex;
  align-items: center;
  flex-direction: column;

  label {
    margin: 5px 0;
  }

  select {
    text-transform: uppercase;
    box-shadow: none;

    &:focus {
      border-color: $brand-primary;
    }
  }
}
</style>

<style lang="scss">
@import "~@/assets/css/sass/bootstrap/_variables.scss";
.modal-casadinha {
  .modal-container.container {
    width: 50% !important;
  }

  width: 40% !important;
  position: absolute;
  z-index: 9999;

  .btn-casadinha {
    i {
      color: #ff9800;
    }

    background: transparent;
  }

  .produto-selecionado {
    display: flex;
    flex-direction: column;

    .produto {
      h5 {
        border: 0 !important;
        padding: 0 !important;
      }
    }
  }

  .produto-premio {
    margin-top: 15px;
  }

  .container-produto-casadinha {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .produto-premio {
      flex: 1 0 45%;
      margin: 5px;
    }
  }

  hr {
    margin-top: 35px;
    margin-bottom: 5px;
    border: 0;
    width: 100%;
    border-top: 1px solid #ccc;
    z-index: 999999999999999999;
  }

  .container-separador {
    flex: 1;
    width: 100%;

    .title-casadinhas {
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 20px;
    }

    .pagina-vazia {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .pagination-casadinha {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin: 10px 5px 0px;
      padding: 5px;
      border-radius: 4px;
      border: 2px solid #eee;
      box-shadow: 0 1px 5px #e3e3e3;

      button {
        border: none;
        background: none;

        h5 {
          color: $brand-primary;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }
    .title {
      span {
        font-weight: bold;
        color: $brand-primary;
      }
    }

    .produto {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #eee;
      box-sizing: border-box;
      padding: 10px;
      height: 100%;
      border-radius: 4px;
      box-shadow: 0px 1px 2px #eee;
      transition: 0.1s ease;
      box-shadow: 0 1px 5px #e3e3e3;

      img {
        height: auto;
        max-width: 15px;
      }

      h5 {
        flex: 1;
        padding: 0 5px !important;
        border-right: 1px solid #d3d3d3 !important;
        font-size: 13px !important;
        text-align: center !important;
      }

      .btn-view {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: transparent;
        color: $brand-primary;
        font-size: 14px;
        border: none;
        text-align: center;

        i {
          color: $brand-primary;
          font-size: 9px;
        }
      }

      & + .produto {
        margin-top: 10px;
      }

      &:hover {
        box-shadow: 0px 1px 4px #ddd;
        border-color: $brand-primary;
      }
    }
  }

  .error-message {
    text-align: center;

    .error-text {
      color: #f34c3c;
      font-weight: 600;
    }
  }
}

@media (max-width: 1600px) {
  .modal-body {
    max-height: 550px !important;
  }
}

@media (max-width: 1440px) {
  .modal-body {
    max-height: 425px !important;
  }
}

@media (max-width: 1200px) {
  .produto-premio {
    flex: 1 0 100% !important;
  }
}

@media (max-width: 768px) {
  .modal-body {
    max-height: 450px !important;
  }
}

@media (max-width: 425px) {
  .modal-body {
    max-height: inherit !important;
  }
}
</style>
