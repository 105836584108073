<template>
  <div
    style="height: 400px"
    class="mt-20 login-required flex overflow-hidden w-full items-center justify-center rounded-default relative"
  >
    <div
      class="h-full absolute mask flex-row flex w-full flex-wrap justify-between"
    >
      <div
        class="w-full p-6 flex rounded-default mb-6 h-banner bg-green-primary"
      ></div>
      <div
        class="w-full p-6 flex rounded-default mb-6 h-banner bg-green-primary"
      ></div>
      <div
        class="w-full p-6 flex rounded-default mb-6 h-banner bg-green-primary"
      ></div>
    </div>
    <div
      class="absolute login-required-alert flex flex-row bg-white rounded-default items-center"
    >
      <div
        class="p-6 circle flex items-center justify-center border-2 border-green-rounded-custom m-2"
      >
        <log-in-icon size="1.5x" class="custon-class"></log-in-icon>
      </div>

      <p class="font-sm m-4 font-bold text-dpc-green">
        Confira as ofertas exclusivas que preparamos para você.
      </p>
      <a
        class="rounded-default btn-dpc p-4 pointer"
        @click="handleShowCampanhas"
      >
        {{ semPedidoAberto ? "Ver ofertas" : "Fazer login" }}
      </a>
    </div>
  </div>
</template>
<script>
import LogInIcon from "vue-feather-icons/icons/LogInIcon";
import bus from "@/utils/events/bus";

export default {
  name: "AlertCampanhaPromotion",
  components: { LogInIcon },
  props: {
    semPedidoAberto: "",
  },
  methods: {
    handleShowCampanhas() {
      if (!this.semPedidoAberto) {
        this.$route.query.isLoginRequired = true;

        this.$router.push({
          name: "login",
        });
        return;
      }
      bus.$emit("openModalParametros", true);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/css/global.css";
@import "../../assets/css/sass/bootstrap/variables";
.pointer {
  cursor: pointer;
}
.btn-dpc {
  height: 42px;
  width: 210px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex: 0 0 120px;
  border-radius: 3px;
  color: rgb(255, 255, 255);
  background-color: var(--brand-primary, $brand-primary);
  font-size: 12px;
  font-weight: bold;
  margin-left: 15px;
}
.login-required-alert {
  height: 135px;
  width: 820px;
  background: rgb(255, 255, 255);
  box-shadow: #818181 0px 1px 2px 0px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px 30px;
}
.border-green-rounded-custom {
  border-style: solid;
  border-color: var(--brand-primary, $brand-primary);
}
.mask {
  filter: blur(15px);
}
</style>
