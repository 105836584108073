// import Produto from "./components/Main.vue";

export default [
  {
    path: "/produto/:codigo/:desc?",
    name: "produtoRota",
    component: () =>
      import(
        /* webpackChunkName: "view-produto" */ `./components/Main.vue`
      ).catch((error) => {
        location.reload();
      }),
    // props: (route) => ({ query: route.query.codigo }, { query: route.query.categoria }) },

    props: (route) => (
      { codigo: route.params.codigo },
      { categoria: route.query.categoria },
      { filtroId: route.params.filtroId }
    ),
    beforeEnter: async (to, from, next) => {
      if (from.query.filtroId) {
        try {
          dpcAxios
            .connection()
            .post(
              `${process.env.VUE_APP_APIB2B}/v1/navigation/search/filter/${from.query.filtroId}/event`,
              {
                event_type: "view-details",
                event_data: {
                  produto_id: to.params.id,
                },
              }
            );
        } catch (error) {
          // console.error("", error);
        }
      }
      next();
    },
  },
];
