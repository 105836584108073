<template>
  <aside id="sidebar">
    <div
      v-if="active"
      id="aside-content"
      :class="{
        left: divLadoIn,
        right: !divLadoIn,
        active: active,
        'scroll-active': scrollActive,
      }"
    >
      <section class="sidebar-header">
        <h4 v-if="titulo" class="sidebar-title">
          <strong>{{ titulo }}</strong>
        </h4>
        <button class="btn-transparent sidebar-close-btn" @click="isActive()">
          <i class="fa fa-times close-desktop"></i>
          <i class="fa fa-chevron-down close-mobile"></i>
        </button>
      </section>
      <slot></slot>
    </div>
    <!-- <button
      v-if="btnCloseActive"
      type="button"
      class="btn btn-close"
      :class="{ left: divLadoIn, right: !divLadoIn, active: active }"
      @click="isActive()"
    >
      x
    </button> -->
    <div
      id="blacksidebar"
      :class="{ active: active }"
      @click="isActive()"
    ></div>
  </aside>
</template>

<script>
export default {
  name: "Sidebar",
  props: {
    lado: {
      type: String,
      default: "left",
    },
    active: {
      type: Boolean,
      default: false,
    },
    scrollActive: {
      type: Boolean,
      default: true,
    },
    btnCloseActive: {
      type: Boolean,
      default: true,
    },
    titulo: {
      type: String,
      default: "Sidebar",
    },
  },
  data() {
    return {
      overlay: false,
    };
  },
  computed: {
    divLadoIn() {
      if (this.$props.lado == "left") {
        return true;
      }
      return false;
    },
  },
  watch: {
    active() {
      if (this.$props.active) {
        this.overlay = true;
        $("body").css("overflow-y", "hidden");
        $("body").css("height", "100%");
      } else {
        $("body").css("overflow-y", "scroll");
        $("body").css("height", "");
      }
    },
  },
  mounted() {
    const el = this;

    $(() => {
      /* if (el.$props.lado == "left") {

                $("#blacksidebar").swipe({ swipeStatus: swipe2, allowPageScroll: "horizontal", tap: tap2 });

                function swipe2(event, phase, direction, distance) {
                    $('#aside-content').css('left', -distance * 2);
                    if ((phase == 'end') || (phase == 'cancel')) {
                        el.isActive()
                        $('#aside-content').css('left', '');
                    }
                }

                function tap2(event, target) {
                    el.isActive()
                    $('#aside-content').css('left', '');
                }
            } else {

                $("#blacksidebar").swipe({ swipeStatus: swipe2, allowPageScroll: "horizontal", tap: tap2 });

                function swipe2(event, phase, direction, distance) {
                    $('#aside-content').css('right', -distance * 2);
                    if ((phase == 'end') || (phase == 'cancel')) {
                        el.isActive()
                        $('#aside-content').css('right', '');
                    }
                }

                function tap2(event, target) {

                    el.isActive()
                    $('#aside-content').css('right', '');
                }

            } */
    });
  },
  methods: {
    isActive() {
      this.$emit("closeside");
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/css/sass/bootstrap/variables";
@media (max-width: 767px) {
  .btn-close {
    display: none !important;
  }

  #aside-content {
    width: 100% !important;
  }
}

#aside-content {
  width: 300px;
  max-width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 9999999;
  // position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 0;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.28);
  -webkit-box-shadow: 0 0 14px rgba(0, 0, 0, 0.28);

  &.left {
    left: -100%;

    &.active {
      left: 0%;
      opacity: 1;
    }
  }

  &.right {
    right: -100%;

    &.active {
      right: 0%;
      opacity: 1;
    }
  }

  //   &::-webkit-scrollbar,
  //   &::-webkit-scrollbar {
  //     width: 0px !important;
  //     height: 0px !important;
  //   }

  //   &.scroll-active {
  //     &::-webkit-scrollbar,
  //     &::-webkit-scrollbar {
  //       width: 4px !important;
  //       height: 4px !important;
  //     }
  //   }
}

#blacksidebar {
  display: none;

  &.active {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.63);
    position: fixed;
    z-index: 999999;
    left: 0;
    top: 0;
    display: block;
  }
}

.btn-close {
  background-color: var(--brand-accent, $brand-accent);
  border-bottom: 0;
  z-index: 9999998;
  height: 50px;
  width: 50px;
  font-size: 25px;
  padding: 0;
  //left: -100% !important;
  position: fixed;
  top: 0;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  color: $brand-primary;
  margin-top: -5px;

  &:hover,
  &:active,
  &:focus {
    color: var(--brand-primary, $brand-primary);
  }

  &.left {
    left: -100%;

    &.active {
      left: 300px;
    }
  }

  &.right {
    right: -100%;

    &.active {
      right: 300px;
    }
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 520px) {
  .close-mobile {
    display: block !important;
  }
  .close-desktop {
    display: none;
  }
}

.sidebar-header {
  padding: 15px;
  margin: 0px;
  border-bottom: 1px solid gainsboro;
  color: rgb(102, 102, 102);
  font-weight: 700;
  display: flex;

  .sidebar-title {
    margin: 0px;
    flex: 1 1 0%;
    line-height: 24px;
  }

  .sidebar-close-btn {
    background: transparent;
    border: none;
  }
  .close-mobile {
    display: none;
  }
}
</style>
