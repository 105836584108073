// import Main from "./components/Main";

export default [
  {
    path: "/politica-de-privacidade-expedicao",
    name: "politicaMainExpedicao",
    component: () =>
      import(
        /* webpackChunkName: "view-politica-de-privacidade-expedicao" */ `./components/Main.vue`
      ).catch((error) => {
        location.reload();
      }),
  },
];
