<template>
  <div class="descontoContent">
    <template v-if="getMaxDiscount(gruposBonus) != 0">
      <span class="ateSpan">Até</span>
      <span v-if="displayDiscount"> {{ displayDiscount }}</span>
      <span v-else>{{ getMaxDiscount(gruposBonus) }}</span>
      <span class="offInput">OFF</span>
    </template>
    <template v-else>
      <i class="mdi mdi-sale"></i>
    </template>
  </div>
</template>
<script>
import { mascaraValor } from "@/app/global.js";

export default {
  name: "IconMaxDesconto",
  props: {
    gruposBonus: {
      required: true,
    },
    displayDiscount: {},
  },
  methods: {
    getMaxDiscount(bonus = []) {
      let discount = 0;
      let tpDesconto = 1;
      if (bonus.length > 0) {
        bonus.forEach((bonusItem) => {
          if (parseFloat(bonusItem.vlrdesconto) > discount) {
            discount = parseFloat(bonusItem.vlrdesconto);
            tpDesconto = bonusItem.tpdesconto;
          }
        });
      }

      if (discount == 0) {
        return 0;
      }

      if (tpDesconto == 1) {
        discount = `${discount}%`;
      } else {
        discount = `R$ ${mascaraValor(discount)}`;
      }
      return discount;
    },
  },
};
</script>
<style lang="scss">
.descontoContent {
  line-height: 1;
  color: #1ba256;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 700;
  font-size: 40px;
  span.ateSpan {
    font-size: 13px;
    align-self: flex-start;
    margin-left: 4px;
    font-weight: 600;
  }
  span.offInput {
    font-size: 34px;
  }
}

@media (max-width: 448px) {
  .descontoContent {
    padding: 0px 5px;
    span {
      font-size: 20px !important;
    }
    span.ateSpan {
      display: none;
    }
  }
}
@media (max-width: 340px) {
  .descontoContent {
    display: none;
  }
}
</style>
