export default {
  informacao_global: [],
  token: "",
  user: [],
  dadosPedido: {},
  campanhaStatus: [],
  campanhasDisponiveis: [],
  productsWithCampanha: [],
  hasMoreCampaignsToShow: true,
  carrinhoAtual: [],
  paymentData: {
    card: {
      cardName: "",
      cardNumber: "",
      cardCvv: "",
      cardMonth: "",
      cardYear: "",
      saveCard: true,
      cardParcels: "",
      cardBrand: "",
      cardDocument: "",
    },
  },
  saveCards: [],
  totalProduct: 0,
  faturamentoData: {},
  savedCardSelected: {},
  cvvCardSave: "",
  globalAppLoading: false,
  cardPaymentError: {
    error: false,
  },
  usuarioInfo: {},
  clienteAtual: "",
  isAuthenticated: false,
  userShouldResetPassword: false,
  showLoadingModal: false,
  loadingModalIds: [],
  isDpcMobileWebView: navigator.userAgent.includes("dpc-mobile-webview"),
  siteConfig: null,
};
