<template>
  <div class="new-card">
    <content-box title="Dados de pagamento">
      <div slot="body">
        <alert-box
          message="Adicionar novo cartão"
          type="default"
          :show-border="false"
        >
          <div v-if="myCards.length > 0" slot="action">
            <router-link
              class="nb-change-method"
              :to="{ name: 'checkoutPayment', query: { ...$route.query } }"
            >
              Alterar
            </router-link>
          </div>
        </alert-box>

        <div class="nb-card">
          <div class="nc-card-details">
            <img
              style="padding: 15px 30px"
              src="@/assets/img/checkout/card-brands.png"
              alt="Bandeiras de cartão aceitas. Visa, MasterCard, American Express, Hipercard e Elo"
              width="100%"
            />

            <form @submit.prevent="validateAndGoToReview">
              <div class="form-group">
                <label for="cardNumber">Número</label>
                <div style="position: relative">
                  <input
                    id="cardNumber"
                    v-model="cardInfo.number"
                    v-mask="cardMask"
                    type="text"
                    class="form-control"
                    data-ref="cardNumber"
                    maxlength="20"
                    required
                    autocomplete="cc-number"
                    :class="{ 'form-shake-error error': errValidation.number }"
                    @focus="focusInput"
                    @blur="
                      blurInput;
                      getCardBrand(cardNumberUnmasked);
                    "
                  />
                  <div
                    v-if="cardBrand"
                    class="ncd-number-brand"
                    style="
                      position: absolute;
                      right: 5px;
                      top: 0px;
                      height: 37px;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <img
                      draggable="false"
                      :src="require(`@/assets/img/pagamento/${cardBrand}.png`)"
                      width="45px"
                    />
                  </div>
                </div>
                <p v-if="errValidation.number" class="input-error">
                  {{ errValidation.number }}
                </p>
              </div>

              <div class="form-group">
                <label for="cardName">Nome no cartão</label>
                <input
                  id="cardName"
                  v-model="cardInfo.name"
                  type="text"
                  class="form-control"
                  data-ref="cardName"
                  maxlength="26"
                  required
                  autocomplete="cc-name"
                  :class="{ 'form-shake-error error': errValidation.name }"
                  @focus="focusInput"
                  @blur="blurInput"
                />

                <p v-if="errValidation.name" class="input-error">
                  {{ errValidation.name }}
                </p>
              </div>

              <div class="form-group" style="display: flex">
                <div
                  style="margin-right: 5px; max-width: 120px; min-width: 60px"
                >
                  <label for="cardCvc">CVV</label>
                  <input
                    id="cardCvc"
                    v-model="cardInfo.cvc"
                    v-mask="cvvMask"
                    type="text"
                    class="form-control"
                    data-ref="cardCvc"
                    :maxlength="cardIsAmex ? 4 : 3"
                    required
                    autocomplete="cc-csc"
                    :class="{ 'form-shake-error error': errValidation.cvc }"
                    @focus="flipCard = true"
                    @blur="flipCard = false"
                  />
                  <p v-if="errValidation.cvc" class="input-error">
                    {{ errValidation.cvc }}
                  </p>
                </div>

                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    min-width: 155px;
                  "
                >
                  <label for="form-group">Data de vencimento</label>

                  <div class="form-group-stack" style="display: flex">
                    <select
                      v-model="cardInfo.expMonth"
                      class="form-control"
                      style="margin-right: 5px"
                      :class="{
                        'form-shake-error error': errValidation.expiration,
                      }"
                    >
                      <option value="null" selected disabled>Mês</option>
                      <option
                        v-for="(month, index) in 12"
                        :value="`${month < 10 ? 0 : ''}${month}`"
                      >
                        {{ month < 10 ? 0 : "" }}{{ month }}
                      </option>
                    </select>

                    <select
                      v-model="cardInfo.expYear"
                      class="form-control"
                      :class="{
                        'form-shake-error error': errValidation.expiration,
                      }"
                    >
                      <option value="null" selected disabled>Ano</option>
                      <option
                        v-for="(year, index) in 12"
                        :value="index + minCardYearAvailable"
                      >
                        {{ index + minCardYearAvailable }}
                      </option>
                    </select>
                  </div>
                  <p v-if="errValidation.expiration" class="input-error">
                    {{ errValidation.expiration }}
                  </p>
                </div>
              </div>

              <div class="form-group">
                <label for="ownerDocument">CPF/CNPJ do titular</label>
                <!-- <input
                  id="ownerDocument"
                  :key="documentMask"
                  v-model="cardInfo.ownerDocument"
                  v-mask="`${documentMask}`"
                  type="text"
                  class="form-control"
                  data-ref="ownerDocument"
                  maxlength="20"
                  required
                  :class="{ 'form-shake-error error': errValidation.document }"
                  @focus="focusInput"
                  @blur="blurInput"
                /> -->

                <the-mask
                  id="ownerDocument"
                  v-model="cardInfo.ownerDocument"
                  :mask="['###.###.###-##', '##.###.###/####-##']"
                  type="text"
                  class="form-control"
                  data-ref="ownerDocument"
                  maxlength="20"
                  required
                  :class="{ 'form-shake-error error': errValidation.document }"
                  @focus="focusInput"
                  @blur="blurInput"
                />

                <p v-if="errValidation.document" class="input-error">
                  {{ errValidation.document }}
                </p>
              </div>

              <div class="form-group truncate">
                <input
                  id="saveCard"
                  v-model="cardInfo.saveCard"
                  type="checkbox"
                />
                &#32;<label for="saveCard">
                  Salvar para próximas compras.</label
                >
              </div>
            </form>
          </div>

          <div class="nc-card-rep">
            <card-mock
              :card-number="cardInfo.number"
              :card-holder="cardInfo.name"
              :card-expiration="`${cardInfo.expMonth}/${cardInfo.expYear}`"
              :card-brand="cardBrand"
              :flip-card="flipCard"
              :card-cvc="cardInfo.cvc"
              class="ncr-card-mock"
            ></card-mock>

            <div>
              <span class="nc-card-safe"
                ><i class="fa fa-lock"></i> Todas as transações são
                seguras</span
              >
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="nc-footer">
        <alert-box
          v-if="errorMessage"
          type="error"
          :message="errorMessage"
        ></alert-box>

        <div class="nf-navigation">
          <button
            class="btn btn-primary"
            :disabled="errorMessage"
            @click="validateAndGoToReview"
          >
            <span>Continuar</span>
            &#32;
            <i class="fa fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </content-box>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import ContentBox from "@/app/checkout/components/ContentBox";
import AlertBox from "@/app/checkout/components/AlertBox";

import CardMock from "./CardMock";
import CartMixin from "@/app/checkout/cart/CartMixin";

import bus from "@/utils/events/bus";

export default {
  name: "AddCard",
  components: {
    ContentBox,
    AlertBox,
    CardMock,
    TheMask,
  },

  mixins: [CartMixin],
  data() {
    return {
      flipCard: false,
      errorMessage: null,
      cardInfo: {
        number: null,
        holderName: null,
        expYear: null,
        expMonth: null,
        cvc: null,
        brand: this.cardBrand,
        ownerDocument: null,
        saveCard: true,
      },
      cardBrand: null,
      errValidation: {
        name: null,
        number: null,
        cvc: null,
        expiration: null,
        document: null,
      },
      cardMasks: {
        default: "#### #### #### ####",
        amex: "#### ###### #####",
        cvvAmex: "####",
        cvvDefault: "###",
      },

      cardRegex: {
        amex: /^3[47][0-9]{13}/,
        // Aura: '^507860',
        // BaneseCard: '^636117',
        // Cabal: '(60420[1-9]|6042[1-9][0-9]|6043[0-9]{2}|604400)',
        // Diners: '(36[0-8][0-9]{3}|369[0-8][0-9]{2}|3699[0-8][0-9]|36999[0-9])',
        // Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
        elo: "^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^504175|^627780|^63(6297|6368|6369)|(65003[5-9]|65004[0-9]|65005[01])|(65040[5-9]|6504[1-3][0-9])|(65048[5-9]|65049[0-9]|6505[0-2][0-9]|65053[0-8])|(65054[1-9]|6505[5-8][0-9]|65059[0-8])|(65070[0-9]|65071[0-8])|(65072[0-7])|(65090[1-9]|6509[1-6][0-9]|65097[0-8])|(65165[2-9]|6516[67][0-9])|(65500[0-9]|65501[0-9])|(65502[1-9]|6550[34][0-9]|65505[0-8])|^(506699|5067[0-6][0-9]|50677[0-8])|^(509[0-8][0-9]{2}|5099[0-8][0-9]|50999[0-9])|^65003[1-3]|^(65003[5-9]|65004d|65005[0-1])|^(65040[5-9]|6504[1-3]d)|^(65048[5-9]|65049d|6505[0-2]d|65053[0-8])|^(65054[1-9]|6505[5-8]d|65059[0-8])|^(65070d|65071[0-8])|^65072[0-7]|^(65090[1-9]|65091d|650920)|^(65165[2-9]|6516[6-7]d)|^(65500d|65501d)|^(65502[1-9]|6550[3-4]d|65505[0-8])",
        // FortBrasil: '^628167',
        // GrandCard: '^605032',
        hipercard: "^606282|^637095|^637599|^637568",
        // JCB: /^(?:2131|1800|35\d{3})\d{11}/,
        mastercard: /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{14}$/,
        // PersonalCard: '^636085',
        // Sorocred: '^627892|^636414',
        // Valecard: '^606444|^606458|^606482',
        visa: /^4[0-9]{12}(?:[0-9]{3})/,
      },
    };
  },
  computed: {
    cardIsAmex() {
      return this.cardBrand == "amex";
    },
    cvvMask() {
      return this.cardIsAmex
        ? this.cardMasks.cvvAmex
        : this.cardMasks.cvvDefault;
    },
    cardMask() {
      return this.cardIsAmex ? this.cardMasks.amex : this.cardMasks.default;
    },

    myCards() {
      return this.$store.getters.saveCards;
    },
    minCardYearAvailable() {
      if (
        this.cardInfo.expMonth &&
        this.cardInfo.expMonth < new Date().getMonth() + 1
      ) {
        return new Date().getFullYear() + 1;
      }
      return new Date().getFullYear();
    },
    cardNumberUnmasked() {
      if (!this.cardInfo.number) return;
      return this.cardInfo.number.replace(/\s+/g, "");
    },
    // async cardBrand() {
    // 	return await this.getCardBrand(this.cardNumberUnmasked);
    // },
  },
  watch: {
    // cardNumberUnmasked(newValue, oldValue) {
    // 	if (newValue && newValue != oldValue) {
    // 		if (newValue.length <= 6) return;

    // 		this.getCardBrand(newValue);
    // 	}
    // },
    paymentMethod(newValue) {
      if (newValue != this.PAYMENT_METHODS.CREDIT_CARD) {
        this.$router.push({
          name: "checkoutReview",
          query: { ...this.$route.query },
        });
      }
    },
    cardInfo: {
      handler(val) {
        // do stuff
        // console.log(val);

        if (this.errValidation.name && val.name) {
          this.errValidation.name = null;
        }

        if (this.errValidation.number && val.number) {
          this.errValidation.number = null;
        }

        if (this.errValidation.cvc && val.cvc) {
          this.errValidation.cvc = null;
        }

        if (
          (this.errValidation.expiration && val.expMonth) ||
          (this.errValidation.expiration && val.expYear)
        ) {
          this.errValidation.expiration = null;
        }

        if (this.errValidation.document && val.ownerDocument) {
          this.errValidation.document = null;
        }

        this.errorMessage = null;
      },
      deep: true,
    },
  },
  deactivated() {
    this.clearAllErrors();
  },
  methods: {
    async getCardBrand(number) {
      // if (!number) return;
      // const cartoes = this.cardRegex;
      // for (var cartao in cartoes)
      //   if (number.match(cartoes[cartao])) return cartao;
      // return "";
      if (number.length < 6) {
        this.cardBrand = null;
        return;
      }

      const cardBin = number;
      const loadingId = Date.now() + Math.random();
      this.$store.dispatch("setShowLoadingModal", [true, loadingId]);

      try {
        const url = `${process.env.VUE_APP_ECOMMERCE}ec/payment/binlookup/${cardBin}`;

        const { data } = await dpcAxios.connection().get(url);

        this.cardBrand = data.brand;
      } catch (e) {
        console.log(e);
      }

      this.$store.dispatch("setShowLoadingModal", [false, loadingId]);
    },
    validateAndGoToReview() {
      let hasError = false;
      if (!this.cardInfo.name || this.cardInfo.name.length < 4) {
        this.errValidation.name = "Nome inválido!";
        hasError = true;
      }

      if (
        !this.cardInfo.number ||
        !this.validateCreditCardNumber(this.cardNumberUnmasked)
      ) {
        this.errValidation.number = "Número inválido!";
        hasError = true;
      }

      if (
        !this.cardInfo.cvc ||
        (this.cardIsAmex && this.cardInfo.cvc.length < 4) ||
        (!this.cardIsAmex && this.cardInfo.cvc.length > 3)
      ) {
        this.errValidation.cvc = "Código inválido!";
        hasError = true;
      }

      if (
        !this.cardInfo.expYear ||
        (this.cardInfo.expYear <= new Date().getFullYear() &&
          this.cardInfo.expMonth < new Date().getMonth() + 1)
      ) {
        this.errValidation.expiration = "O ano informado não é válido.";
        hasError = true;
      }

      if (
        !this.cardInfo.expMonth ||
        (this.cardInfo.expYear == new Date().getFullYear() &&
          this.cardInfo.expMonth < new Date().getMonth() + 1)
      ) {
        this.errValidation.expiration = "O mês informado não é válido. ";
        hasError = true;
      }

      // TODO: validar cnpj / cpf aqui.
      if (!this.cardInfo.ownerDocument) {
        this.errValidation.document = "O CPF/CNPJ informado não é válido.";
        hasError = true;
      }

      if (hasError) {
        this.errorMessage =
          "Por favor, valide e corrija os dados de pagamento.";
        return;
      }

      const { expMonth, expYear, ownerDocument, number, name, cvc, saveCard } =
        this.cardInfo;

      const paymentData = {
        card: {
          cardParcels: this.paymentData.card.cardParcels,
          cardName: name,
          cardDocument: ownerDocument,
          cardNumber: this.cardNumberUnmasked,
          cardCvv: cvc,
          cardBrand: this.cardBrand,
          cardMonth: expMonth,
          cardYear: expYear,
          saveCard,
          desc: String(this.cardNumberUnmasked).slice(-4), // last4digits
        },
      };

      // console.log(paymentData);
      this.$store.dispatch("paymentData", paymentData);
      this.$router.push({
        name: "checkoutReview",
        query: { ...this.$route.query },
      });
    },
    validateCreditCardNumber(inputNum) {
      let digit;
      let digits;
      let flag;
      let sum;
      let _i;
      let _len;
      flag = true;
      sum = 0;
      digits = `${inputNum}`.split("").reverse();
      for (_i = 0, _len = digits.length; _i < _len; _i++) {
        digit = digits[_i];
        digit = parseInt(digit, 15);
        if ((flag = !flag)) {
          digit *= 2;
        }
        if (digit > 9) {
          digit -= 9;
        }
        sum += digit;
      }

      return sum % 10 === 0;
    },
    focusInput(e) {
      // console.log(e);
    },
    blurInput(e) {
      // console.log(e);
    },
    clearAllErrors() {
      this.errorMessage = null;
      this.errValidation.name = null;
      this.errValidation.number = null;
      this.errValidation.cvc = null;
      this.errValidation.expiration = null;

      this.errValidation.document = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

.nb-card {
  display: flex;
  flex-wrap: wrap;

  .nc-card-details {
    flex: 1;
  }

  .nc-card-rep {
    flex: 1;
  }

  .nc-card-safe {
    font-size: 14px;
    color: var(--brand-primary, $brand-primary);
    font-weight: 600;
  }
}
.nb-change-method {
  color: var(--brand-primary, $brand-primary);
}
.nc-footer {
  display: flex;
  flex-direction: column;

  .nf-navigation {
    display: flex;
    justify-content: flex-end;

    .btn {
      margin: 0 4px;
      padding: 10px 40px;
    }
  }
}

@media (max-width: 525px) {
  .nc-footer {
    .nf-navigation {
      .btn {
        flex: 1;
      }
    }
  }
}

input,
textarea,
select {
  border: 01px solid #ddd;
  box-shadow: none;
  -webkit-box-shadow: none;

  &:focus,
  &:active {
    border: 01px solid var(--brand-primary, $brand-primary);
    box-shadow: none;
    -webkit-box-shadow: none;
  }

  &.error {
    border: 1px solid #da0000 !important;
  }
}

.input-error {
  color: #da0000;
  font-size: 12.5px;
  padding-top: 1.5px;
}

.nc-card-rep {
  align-self: center;
  text-align: center;
  margin-left: 10px;

  .ncr-card-mock {
    // width: 330px !important;
    max-width: 330px !important;
    min-width: 245px;
    height: 215px;
  }
}
</style>
