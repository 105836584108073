<template>
  <section
    class="banners-destaque"
    :class="{ hovertop: hovertop, hoverbottom: hoverbottom }"
  >
    <div
      :class="{
        container1: !containerfluid,
        'container-fluid1': containerfluid,
      }"
    >
      <div v-for="(banner, index) in banners" :key="index">
        <div
          v-for="(item, index) in banner.row"
          :key="index"
          :class="[
            'col-xs-' + item.col_xs,
            'col-sm-' + item.col_sm,
            'col-md-' + item.col_md,
            {
              'hidden-xs': item.h_xs,
              'hidden-sm': item.h_sm,
              'hidden-md': item.h_md,
              'hidden-lg': item.h_lg,
              'no-padding1': containerfluid,
            },
          ]"
          class="banner"
        >
          <div v-if="checkLink(item.link)">
            <a :href="item.link">
              <ImageItem custom-class="img-responsive" :source="item.src" />
            </a>
          </div>
          <div v-else>
            <img
              loading="lazy"
              :src="item.src"
              class="img-responsive"
              alt="Banner"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ImageItem from "@/components/image/ImageItem";

export default {
  name: "BannersDestaque",
  components: {
    ImageItem,
  },
  props: {
    containerfluid: {
      type: Boolean,
      default: false,
    },
    banners: {
      type: Array,
      default: [],
    },
    hovertop: {
      type: Boolean,
      default: false,
    },
    hoverbottom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    checkLink(itemLink) {
      if (itemLink != "./" && itemLink != "") {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

.banners-destaque {
  margin-top: 15px;
  max-width: 1360px;
  border-radius: 6px;
  text-align: center;
  margin: auto;
  display: flex;
}

.hovertop {
  margin-top: -90px !important;
}

.hoverbottom {
  margin-bottom: -120px !important;
}

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.banner img {
  width: 100%;
  border-radius: 6px;
  margin-bottom: 15px;
}

@media (max-width: 991px) {
  .hovertop {
    margin-top: -65px !important;
  }
}

@media (max-width: 768px) {
  .hovertop {
    margin-top: 10px !important;
  }
}
</style>
