import { render, staticRenderFns } from "./OrderOverview.vue?vue&type=template&id=ce699852&scoped=true"
import script from "./OrderOverview.vue?vue&type=script&lang=js"
export * from "./OrderOverview.vue?vue&type=script&lang=js"
import style0 from "./OrderOverview.vue?vue&type=style&index=0&id=ce699852&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce699852",
  null
  
)

export default component.exports