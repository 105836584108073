var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"modal-sellers",attrs:{"show-modal":true,"titulo":"Selecione o vendedor","show-footer":true},on:{"close":function($event){return _vm.close()}}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"modal-container"},[_c('search-box',{staticClass:"modal-sellers-list__search",attrs:{"placeholder":"Digite para pesquisar","search-term":_vm.searchTerm},on:{"search":function($event){_vm.searchTerm = $event}}}),_c('div',{staticClass:"modal-sellers-list"},_vm._l((_vm.sellers),function(seller){return _c('div',{key:seller.usuario_id,staticClass:"modal-sellers-list-item",class:{
            'modal-sellers-list-item__active':
              _vm.seletedSeller == seller.usuario_id,
          },on:{"click":() => {
              seller.loading = true;
              _vm.handleSellerSelected(seller.usuario_id);
            }}},[_c('span',[_vm._v(_vm._s(seller.nome))]),(_vm.seletedSeller == seller.usuario_id && _vm.showAllowFinishOrder)?_c('div',{staticClass:"finish-order__toggle"},[_c('span',[_vm._v("Permite Finalizar")]),_c('toggle-button',{attrs:{"sync":"","width":35,"height":18,"color":_vm.primaryColor,"margin":0},model:{value:(_vm.permiteFinalizar),callback:function ($$v) {_vm.permiteFinalizar=$$v},expression:"permiteFinalizar"}})],1):_vm._e()])}),0)],1)]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":!_vm.seletedSeller},on:{"click":function($event){return _vm.sendOrderToSeller()}}},[(_vm.loading)?_c('i',{staticClass:"fa fa-spinner fa-spin"}):_vm._e(),_vm._v(" Enviar Pedido ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }