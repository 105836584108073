<template>
  <div id="section-categorias-destaque" class="container-fluid">
    <div>
      <span class="flex items-center justify-center">
        <h1
          class="text-4xl text-center mb-3 mt-0 pb-2 border-b-3 border-green-custom font-bold"
        >
          {{ titulo }}
        </h1>
      </span>

      <VueSlickCarousel
        v-bind="slideSettings"
        class="slider-categorias-destaque"
      >
        <div v-for="(categoria, index) in sliders" :key="index">
          <section
            class="item flex flex-col items-center justify-center container-categoria"
          >
            <router-link
              class="flex flex-col items-center justify-center hover"
              tag="a"
              :to="{
                name: 'catalogoRota',
                query: {
                  categoria: categoria.link || 1,
                },
              }"
            >
              <section class="item-item">
                <img
                  v-if="categoria.titulo"
                  :src="categoria.src"
                  onerror="this.onerror=null;this.src='https://img.dpcnet.com.br/catalogo/imagem_indisponivel.jpg';this.parentNode.parentNode.parentNode.parentNode.style.display = 'none'"
                  class="img-responsive"
                  :alt="categoria.titulo"
                />
              </section>

              <label class="mt-4 text-dpc-green">{{ categoria.titulo }}</label>
            </router-link>
          </section>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  name: "CategoriasSlider",
  components: { VueSlickCarousel },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    titulo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      slideSettings: {
        infinite: true,
        speed: 800,
        slidesToShow: 8,
        slidesToScroll: 2,
        dots: false,
        autoplay: false,
        autoplaySpeed: 4000,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 4,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 4,
            },
          },
          {
            breakpoint: 815,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
            },
          },
          {
            breakpoint: 630,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            },
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 365,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 275,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  computed: {
    sliders() {
      return this.data;
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.slider-categorias-destaque {
  .slick-next {
    right: -20px;
  }
  .slick-prev {
    left: -20px;
  }
}
</style>
<style lang="scss" scoped>
@import "../../assets/css/sass/bootstrap/variables";
@import "~@/assets/css/global.css";
#section-categorias-destaque {
  max-width: 1360px;
  width: calc(100% - 20px);
  padding-top: 40px;
  padding-bottom: 40px;

  span {
    color: #303932;
  }

  @media (max-width: 525px) {
    padding: 0 10px;
  }
}

:focus {
  outline: 0;
}
.border-green-custom {
  border-bottom-style: solid;
  border-bottom-color: var(--brand-primary, $brand-primary);
}
label {
  transition: all 0.3s ease-in-out;

  @media (max-width: 525px) {
    font-size: 14px;
  }
}
.container-categoria {
  padding-top: 20px;
  transition: all 0.3s ease-in-out;
  text-align: center;
}
.container-categoria:hover {
  transform: scale(1.03);
}
.hover:hover label {
  color: var(--brand-primary, $brand-primary);
}
.slider-categorias-destaque {
  z-index: 0;
  .slick-next {
    right: -20px;
  }
}
.item-item img {
  width: 120px;

  @media (max-width: 900px) {
    width: 100px;
  }

  @media (max-width: 525px) {
    width: 60px;
  }
}
</style>
