<template>
	<label
		:for="id"
		class="item-card"
		:class="{ error: validationFailed }"
		@click="$emit('itemSelected', id)"
	>
		<div v-if="showRadio" class="ic-radio">
			<input
				:id="id"
				type="radio"
				class="ir-mark"
				name="itemSelected"
				:checked="selected"
			/>
			<span class="ir-custom-mark"></span>
		</div>
		<div v-if="logo" class="ic-logo">
			<img width="45px" :src="logo" draggable="false" />
		</div>
		<div class="ic-content">
			<div class="ic-info">
				<div class="ii-title">{{ title }}</div>
				<div v-if="desc" class="ii-desc">{{ desc }}</div>
			</div>
			<div class="ii-action">
				<slot name="action"></slot>
			</div>
		</div>
	</label>
</template>

<script>
export default {
	name: "ItemCard",
	props: {
		selected: {
			required: false,
		},
		id: {
			required: true,
		},
		logo: {
			required: false,
		},
		title: {
			required: true,
		},
		desc: {
			required: false,
		},
		showRadio: {
			default: false,
		},
		validationFailed: {
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
.item-card {
	border-radius: 10px;
	display: flex;
	height: 65px;
	padding-left: 10px;
	align-items: center;
	-webkit-perspective-origin-x: center;
	cursor: pointer;
	// background: radial-gradient(
	//   ellipse farthest-corner at 0 140%,
	//   #afafaf 0%,
	//   #969696 70%,
	//   #8a8a8a 70%
	// );
	background: whitesmoke;
	margin: 5px;
	border: 1px solid #e0e0e0;
	flex: 1 1 calc(50% - 10px);
	// max-width: calc(50% - 10px);

	&.error {
		.ir-custom-mark {
			background-color: #da0000 !important;

			&:after {
				display: block !important;
			}
		}
	}

	/* On mouse-over, add a grey background color */
	&:hover .ic-radio input ~ .ir-custom-mark {
		background-color: #ccc;
	}
	.ic-logo {
		background: whitesmoke;
		padding: 3.5px 3px;
		border-radius: 3px;
		height: 30px;
		display: flex;
		align-items: center;
	}
	.ic-radio {
		padding: 0 10px;
		position: relative;
		width: 20px;
		display: flex;
		align-items: center;

		input:checked ~ .ir-custom-mark {
			background-color: #2196f3;
		}

		input:checked ~ .ir-custom-mark:after {
			display: block;
		}

		.ir-mark {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}

		/* Create a custom radio button */
		.ir-custom-mark {
			position: absolute;
			left: 0;
			height: 16px;
			width: 16px;
			background-color: #d4d4d4;
			border-radius: 50%;
			// margin-left: 20px;
		}

		/* Create the indicator (the dot/circle - hidden when not checked) */
		.ir-custom-mark:after {
			content: "";
			position: absolute;
			display: none;
		}

		/* Style the indicator (dot/circle) */
		.ir-custom-mark:after {
			top: 5.1px;
			left: 5.1px;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background: white;
		}
	}

	.ic-content {
		display: flex;
		padding: 0 10px;
		flex: 1;
		justify-content: space-between;
		align-items: center;
		min-width: 0;

		.ic-info {
			display: flex;
			flex-direction: column;
			flex: 0 1 auto;
			min-width: 0;

			.ii-title {
				white-space: nowrap;
				max-width: 150px;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.ii-desc {
				font-size: 13px;
			}
		}
	}
}
</style>
