<template>
  <div :class="'alertContainer ' + handleTypeAlert()">
    <div v-if="typeAlert == 'home'" class="flex items-center justify-center">
      <i class="mdi mdi-credit-card-multiple-outline fa-2x"></i>
    </div>
    <div v-if="typeAlert == 'alertDanger'">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
    </div>
    <span>{{ description }}</span>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    description: {
      type: String,
      required: true,
    },
    typeAlert: {
      type: String,
      required: false,
    },
  },
  methods: {
    handleTypeAlert() {
      if (this.typeAlert == undefined) {
        return "default";
      }
      return this.typeAlert;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/global.css";

@import "~@/assets/css/sass/bootstrap/variables";

.default {
  background: rgba(221, 0, 53, 0.1);
  color: #dd0035;
  padding: 10px;
  border-radius: 6px;
  font-size: 12px;
}
.card-icon {
  width: 33px;
  height: 33px;
}

.alertDanger {
  background: rgba(255, 183, 3, 0.1);
  color: #ffb703;
  padding: 0px 7px;
  height: 32px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  display: flex;
  align-items: center;
  gap: 5px;
  i {
    font-size: 19px;
  }
}

.home {
  display: flex;
  gap: 12px;
  align-items: center;
  height: 44px;
  font-weight: 600;
  color: var(--brand-primary, $brand-primary);
  padding: 5px;
  background: white;
}

.alertContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    text-align: center;
  }
}
@media (max-width: 720px) {
  .card-icon {
    width: 25px;
    height: 25px;
  }
  .alertContainer span {
    font-size: 14px;
    font-weight: 600;
  }
}
@media (max-width: 530px) {
  .card-icon {
    width: 20px;
    height: 20px;
  }
  .alertContainer span {
    font-size: 12px;
    font-weight: 600;
  }
}
@media (max-width: 400px) {
  .alertContainer {
    display: none !important;
  }
}
</style>
