<template>
  <div class="right-top">
    <div class="menu-login-section col-sm-6 col-md-3">
      <btnLogin></btnLogin>
      <!-- <div class="new-request" v-if="isAuthenticated && !hasPedidoAberto">
        <button @click="novoPedidoClick()" style="margin-top: 1px;">
          <i class="fa fa-truck"></i>
          <span class="span-requst">Novo Pedido</span>
        </button>
      </div> -->
      <!-- <router-link tag="button" to="/campanhas">
        <i class="fa fa-percent"></i>
        <span class="span-requst">Ofertas</span>
      </router-link> -->
      <floatingCart></floatingCart>
    </div>
  </div>
</template>

<script>
import btnLogin from "@/components/root/btn-login/btnLogin";
import Popover from "@/components/popover/Popover";
import OpcaoBusca from "@/components/popover/OpcaoBusca";
import * as lastSearch from "@/services/cookie/lastSearch";
import bus from "@/utils/events/bus";

import floatingCart from "@/components/root/header/top/FloatingCart.vue";

export default {
  name: "RightTop",
  components: {
    btnLogin,
    Popover,
    OpcaoBusca,
    floatingCart,
  },
  data() {
    return {
      busca: "",
      active_search: false,
      showPopover: false,
      pesquisarecentes: [],
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.getIsAuthenticated;
    },
    hasPedidoAberto() {
      return Object.keys(this.$store.getters.dadosPedido).length !== 0;
    },
  },
  watch: {
    "$route.query.busca": function (to, from) {
      this.busca = this.$route.query.busca ? this.$route.query.busca : "";
    },
  },
  methods: {
    novoPedidoClick() {
      bus.$emit("openModalParametros", true);
    },
    isActive() {
      this.active_search = !this.active_search;
      // SE POPOVER ESTIVER ABERTO
      if (this.showPopover == true) {
        this.showPopover = false;
      }
    },
    paginaCatalogo(event) {
      // SE TECLA PRECIOSANA FOR ENTER, BUSCA O TERMO DIGITADO
      if (event.key == "Enter") {
        this.buscaTermoDigitado();
      } else {
        // SE POPOVER ESTIVER DESATIVADO, ATIVA NOVAMENTE O COMPONENTE
        if (!this.showPopover) {
          this.showPopover = true;
        }
      }
    },
    exibirPopover() {
      // GET AS ULTIMAS BUSCAS REALIZADAS
      let cookie = lastSearch.getCookie();
      // SALVO NA VARIAVEL DE PESQUISAS RESENTES
      this.pesquisarecentes =
        cookie !== null && cookie !== undefined
          ? (cookie = Array.from(cookie))
          : [];

      this.showPopover = true;
    },
    closeOverlay() {
      this.showPopover = false;
    },
    buscaTermoDigitado() {
      // REMOVENDO ESPAÇOS EM BRANCO DO FINAL DA BUSCA
      this.busca = this.busca.trim().toLowerCase();

      // CASO NÃO HAJA BUSCA, PASSAMOS UMA ARRAY VAZIA PARA O PARÂMETRO busca. ISSO IRÁ REMOVÊ-LO
      let nbusca = [];
      if (this.busca !== "") {
        nbusca = this.busca;

        const cookie = { termo: this.busca, timestamp: new Date().getTime() };
        // SALVA O TERMO PESQUISADO NO COOKIE
        lastSearch.saveLastSearch(cookie);

        // FAZENDO RETEAMENTO
        this.$router.push({
          name: "catalogoRota",
          query: {
            busca: nbusca,
            visualizacao: this.$store.getters.modoVisualizacao
              ? this.$store.getters.modoVisualizacao
              : this.$store.dispatch("setModoVisualizacao", "bloco-1"),
          },
        });

        this.closeOverlay();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

.right-top {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
}

.new-request:hover {
  background: darken($green-emphasis, 10%) !important;
}
.new-request {
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0px 10px;
  background: var(--brand-accent, $brand-accent);
  height: 40px;
  border-radius: 6px;
  color: var(--brand-primary, $brand-primary);
  font-weight: bold;
  border: none;

  span {
    // color: #fff;
    font-size: 13px;
    padding: 5px;
    line-height: 15px;
    text-align: left;
    position: relative;
  }
}

@media (max-width: 1024px) {
  .new-request {
    padding: 0px 0px !important;
    margin-top: 0px;
    margin-bottom: 0;
  }
}

@media (max-width: 425px) {
  .new-request {
    position: relative;
    // top: 10px !important;
  }
}

@media (max-width: 525px) {
  .new-request {
    float: right;
    position: relative;
    right: 0px;
    margin-top: unset;
    flex: 0 0 auto;
    background: #9be24000;
    color: #ffffff;
    padding: 0px 8px 0px 0px !important;

    i {
      font-size: 32px;
      position: relative;
      right: 3px;
    }

    span {
      display: none;
    }
  }
}

@media (max-width: 320px) {
  .new-request {
    left: 6px;
  }

  .new-request i {
    font-size: 30px;
    position: relative;
    right: 3px;
  }

  .new-request span {
    display: none;
  }
}

.menu-login-section {
  padding-right: 0;
  padding-left: 0;
  display: flex;
  height: 40px;
  width: 100%;
}

.menu-login-section button {
  background: transparent;
  border: none;
  padding-right: 0;
}

.menu-login-section button:focus,
.menu-login-section button:active,
.btn-search:focus,
.btn-search:active {
  box-shadow: 0 0 0 0 !important;
  outline: 0 !important;
}

.popover {
  border-radius: 0px !important;
}

@media (max-width: 991px) {
  .menu-login-section {
    position: relative;
    justify-content: flex-end;
    width: 100%;
  }
  .right-top {
    // width: 50%;
    // margin-left: 25px;
    justify-content: flex-end;
  }
}

@media (max-width: 525px) {
  .div-popover {
    padding-left: 8px;
    padding-right: 8px !important;
    width: 98%;
  }

  .menu-login-section {
    margin-top: 0;
    top: 0;
    align-items: center;
    height: 50px;
    padding-right: 10px;
  }

  .right-top {
    // width: 50%;
    // margin-left: 0px;
    // justify-content: flex-end;
    flex: auto;
  }
}
</style>
