var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timelineItem"},[_c('span',[_vm._v(_vm._s(_vm.grupoAtivacao.regra)+" ")]),_c('div',{staticClass:"remainingProducts"},[_c('div',{staticClass:"contentItems"},_vm._l((_vm.grupoAtivacaoData.qtdMinProd),function(idx){return _c('div',{key:idx,staticClass:"item",class:idx <= _vm.grupoAtivacaoData.qtdMinProdAtivados ? 'item active' : ''})}),0),_c('div',{staticClass:"infoCard"},[_c('div',[(
            _vm.grupoAtivacaoData.qtdMinProd -
              _vm.grupoAtivacaoData.qtdMinProdAtivados >
              0 && _vm.grupoAtivacaoData.qtdMinProd != null
          )?_c('span',[_vm._v("Adicione mais "+_vm._s(_vm.grupoAtivacaoData.qtdMinProd - _vm.grupoAtivacaoData.qtdMinProdAtivados)+" produto(s) para ativar este combo.")]):_vm._e()]),_c('div',[(
            _vm.grupoAtivacaoData.vlrMinProd -
              _vm.grupoAtivacaoData.vlrMinProdAtivados >
              0 && _vm.grupoAtivacaoData.vlrMinProd != null
          )?_c('span',[_vm._v("Adicione mais R$ "+_vm._s(_vm.mascaraValor( _vm.grupoAtivacaoData.vlrMinProd - _vm.grupoAtivacaoData.vlrMinProdAtivados ))+" em produtos para ativar este combo.")]):_vm._e()]),_c('div',[(
            _vm.grupoAtivacaoData.vlrMaxProd != null &&
            _vm.grupoAtivacaoData.vlrMaxProd < _vm.grupoAtivacaoData.vlrAtualProd
          )?_c('span',{staticClass:"danger"},[_vm._v("Valor máximo atingido! "),_c('alert-circle-icon',{staticClass:"custom-class",attrs:{"size":"1.5x"}})],1):_vm._e(),(
            _vm.grupoAtivacaoData.qtdMaxProd < _vm.grupoAtivacaoData.qtdAtualProd &&
            _vm.grupoAtivacaoData.qtdMaxProd != null
          )?_c('span',{staticClass:"danger"},[_vm._v("Quantidade máxima atingida! "),_c('alert-circle-icon',{staticClass:"custom-class",attrs:{"size":"1.5x"}})],1):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }