<template>
  <div class="cart-empty">
    <span class="sad">:(</span>
    <h3>Seu caminhão está vazio!</h3>
    <h5>
      Que tal visualizar nosso catálogo, ver seus pedidos ou iniciar um novo?
    </h5>

    <div class="ce-action-buttons">
      <router-link
        :to="{ name: 'catalogoRota' }"
        tag="button"
        class="btn"
        :class="{
          'btn-primary': hasPedidoAberto,
          'btn-outline btn-secondary-outline': !hasPedidoAberto,
        }"
      >
        Ver catálogo
      </router-link>

      <template v-if="!hasPedidoAberto">
        <router-link
          :to="{ name: 'MinhaContaPedidos' }"
          tag="button"
          class="btn btn-outline btn-secondary-outline"
        >
          Meus pedidos
        </router-link>
        <button class="btn btn-primary" @click="showModalNewOrder">
          Novo pedido
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import CartMixin from "@/app/checkout/cart/CartMixin";
import bus from "@/utils/events/bus";

export default {
  name: "CartEmpty",
  mixins: [CartMixin],
  methods: {
    showModalNewOrder() {
      // this.$router.push({
      //   name: "catalogoRota",
      //   query: {
      //     //busca: this.$route.query.busca,
      //     // categoria: null,
      //     fornecedor: [],
      //     sub: [],
      //     ordem: this.$route.query.ordem,
      //   },
      // });
      bus.$emit("openModalParametros", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.cart-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  align-items: center;

  .sad {
    font-size: 40px;
    font-weight: bold;
  }

  .btn {
    padding: 10px 30px;
    flex: 1 0 auto;
    margin: 2.5px 2px;
  }

  .ce-action-buttons {
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    flex: 0 1 auto;
  }
}
</style>
