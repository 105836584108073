export const itensSelecionados = ({ itens_selecionados }) => itens_selecionados;
export const categoriaSelecionada = ({ categoria_selecionada }) =>
  categoria_selecionada;
export const categorias = ({ categorias }) => categorias;
export const componente_categorias = ({ componente_categorias }) =>
  componente_categorias;
export const parceiros = ({ parceiros }) => parceiros;
export const parceirosFiltrados = ({ parceirosFiltrados }) =>
  parceirosFiltrados;

export const getMarcasFiltradas = ({ marcasFiltradas }) => marcasFiltradas;

export const modoVisualizacao = ({ modoVisualizacao }) => modoVisualizacao;
export const cartaoSucesso = ({ cartaoSucesso }) => cartaoSucesso;
export const cartaoErro = ({ cartaoErro }) => cartaoErro;
