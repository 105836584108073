<template>
  <div :class="'alertContainer ' + handleTypeAlert()">
    <div v-if="typeAlert == 'home'" class="flex items-center justify-center">
      <svg
        class="card-icon"
        viewBox="0 0 122 109"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M117.342 15.9764L42.623 0.127324C39.4617 -0.543026 36.3284 1.50119 35.6596 4.66972L34.24 13.8133L120.453 32.0993L121.873 22.9558C122.542 19.7885 120.503 16.6469 117.342 15.9764Z"
          fill="#003522"
        />
        <path
          d="M85.9208 34.0384C85.048 30.8883 81.7636 29.0263 78.6216 29.8997L64.4372 33.8464L30.9982 26.7533L27.3207 44.1694L4.34304 50.5616C1.20132 51.4352 -0.656406 54.7272 0.214943 57.8763L13.1642 104.647C14.0369 107.796 17.3216 109.659 20.4633 108.784L94.7419 88.1236C97.8836 87.2488 99.7413 83.958 98.87 80.8077L96.6473 72.7787L104.978 74.544C108.139 75.2155 111.272 73.1713 111.941 70.0028L117.212 45.0396L87.2048 38.6751L85.9208 34.0384ZM94.4989 57.809L96.3359 49.107C96.6118 47.8041 97.9008 46.9637 99.2005 47.2388L107.883 49.0812C109.184 49.3578 110.022 50.6483 109.748 51.9525L107.911 60.6544C107.635 61.9574 106.346 62.7989 105.046 62.5226L96.364 60.6814C95.0616 60.4039 94.223 59.1119 94.4989 57.809ZM5.63561 55.2289L79.9116 34.5695C80.0069 34.5437 80.102 34.5316 80.1949 34.5316C80.6135 34.5316 81.1141 34.8069 81.2604 35.3342L83.7931 44.4801L7.40542 65.7267L4.87271 56.582C4.71402 56.0081 5.06328 55.3879 5.63561 55.2289ZM94.212 82.1018C94.3097 82.4567 94.2072 82.7502 94.1034 82.9338C94.0009 83.1172 93.8032 83.3572 93.4491 83.4549L19.1719 104.115C19.078 104.141 18.9815 104.155 18.8886 104.155C18.47 104.155 17.9695 103.878 17.8231 103.351L10.2702 76.0705L86.6579 54.8227L94.212 82.1018Z"
          fill="#003522"
        />
        <path
          d="M31.1739 83.9686C30.8174 82.6841 29.4796 81.9256 28.198 82.2816L19.5234 84.6953C18.243 85.0514 17.485 86.3935 17.8415 87.678L20.2484 96.3714C20.6048 97.6559 21.9427 98.4144 23.2243 98.0572L31.8989 95.6447C33.1805 95.2886 33.9372 93.9465 33.582 92.662L31.1739 83.9686Z"
          fill="#003522"
        />
      </svg>
    </div>
    <div v-if="typeAlert == 'alertDanger'">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
    </div>
    <span>{{ description }}</span>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    description: {
      type: String,
      required: true,
    },
    typeAlert: {
      type: String,
      required: false,
    },
  },
  methods: {
    handleTypeAlert() {
      if (this.typeAlert == undefined) {
        return "default";
      }
      return this.typeAlert;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/global.css";

@import "~@/assets/css/sass/bootstrap/variables";

.default {
  background: rgba(221, 0, 53, 0.1);
  color: #dd0035;
  padding: 10px;
  border-radius: 6px;
  font-size: 12px;
}
.card-icon {
  width: 33px;
  height: 33px;
}

.alertDanger {
  position: relative;
  // right: -10px;
  // top: -10px;
  background: #fff8e5;
  color: #8e6500;
  padding: 5px 7px;
  height: 32px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.3px;
  -webkit-font-smoothing: antialiased;
  gap: 5px;

  i {
    font-size: 19px;
  }
}

.home {
  display: flex;
  gap: 12px;
  align-items: center;
  height: 50px;
  font-weight: 600;
  color: var(--brand-primary, $brand-primary);
  padding: 5px;
  background: white;
  max-width: 1360px;
  margin: 0 auto;
}

.alertContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    text-align: center;
  }
}
@media (max-width: 720px) {
  .card-icon {
    width: 25px;
    height: 25px;
  }
  .alertContainer span {
    font-size: 14px;
    font-weight: 600;
  }
}
@media (max-width: 530px) {
  .card-icon {
    width: 20px;
    height: 20px;
  }
  .alertContainer span {
    font-size: 12px;
    font-weight: 600;
  }
}
@media (max-width: 400px) {
  .alertContainer {
    display: none !important;
  }
}
</style>
