<template>
  <figure v-lazyload class="image__wrapper">
    <!-- <ImageSpinner class="image__spinner" /> -->
    <img
      :class="customClass"
      class="image__item"
      :data-url="source"
      :alt="description"
      onerror="this.onerror=null;this.src='https://img.dpcnet.com.br/catalogo/imagem_indisponivel.jpg'"
      :fetchpriority="fetchPriority"
      @error="$emit('error', $event)"
    />
  </figure>
</template>

<script>
import ImageSpinner from "./ImageSpinner";

export default {
  name: "ImageItem",
  components: {
    ImageSpinner,
  },
  props: {
    source: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: "",
    },
    customClass: {
      type: String,
      required: false,
      default: "",
    },
    fetchPriority: {
      default: "auto",
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.image {
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 100%;
    width: 100%;

    &.loaded {
      .image {
        &__item {
          visibility: visible;
          opacity: 1;
          border: 0;
        }

        &__spinner {
          display: none;
          width: 100%;
        }
      }
    }
  }

  &__item {
    // width: 100%;
    border-radius: 4px;
    transition: all 0.15s ease-in-out;
    opacity: 0;
    visibility: hidden;

    @media (max-width: 1920px) {
      border-radius: 0;
    }

    // especifico para catalgoo. Verificar se irá funcionar para todos os casos.
    // max-height: 80%;
    // max-width: 85%;
    // display: initial;
  }
}
</style>
