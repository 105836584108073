<template>
  <div class="order-review">
    <content-box title="Revisar e finalizar">
      <div slot="body">
        <payment-resume-info
          icon="mdi mdi-map-marker-outline fa-2x"
          title="Endereço"
          :content="deliveryAddress"
        ></payment-resume-info>

        <review-payment-resume></review-payment-resume>

        <div class="or-items">
          <cart-product-item
            v-for="(product, index) in cartProducts"
            :key="product.integracao_id"
            :item="product"
          ></cart-product-item>
        </div>
      </div>
    </content-box>

    <modal-payment-error v-if="cardPaymentError.error"></modal-payment-error>
    <modal-payment-not-set v-else-if="!isPaymentValid"></modal-payment-not-set>
  </div>
</template>

<script>
import ContentBox from "@/app/checkout/components/ContentBox";
import CartProductItem from "@/app/checkout/cart/components/CartProductItem";

import PaymentResumeInfo from "./payment/PaymentResumeInfo";
import CartMixin from "@/app/checkout/cart/CartMixin";

import ReviewPaymentResume from "./payment/ReviewPaymentResume";

import ModalPaymentNotSet from "./ModalPaymentNotSet";
import ModalPaymentError from "./ModalPaymentError";

export default {
  name: "Review",
  components: {
    ContentBox,
    CartProductItem,
    PaymentResumeInfo,
    ReviewPaymentResume,
    ModalPaymentNotSet,
    ModalPaymentError,
  },
  mixins: [CartMixin],
  computed: {},
  watch: {
    paymentData(newValue) {
      //   console.log(newValue);
    },
  },
  mounted() {},
  async activated() {
    this.$store.dispatch("setCampanhaStatus");
  },
  deactivated() {
    this.$destroy();
  },
};
</script>

<style lang="scss" scoped>
.order-review {
  .or-items {
    border-top: 1px solid #eeeeee;
    padding-top: 4px;
  }
}
</style>
