import { render, staticRenderFns } from "./ProductVariantDropdown.vue?vue&type=template&id=09b17b39&scoped=true"
import script from "./ProductVariantDropdown.vue?vue&type=script&lang=js"
export * from "./ProductVariantDropdown.vue?vue&type=script&lang=js"
import style0 from "./ProductVariantDropdown.vue?vue&type=style&index=0&id=09b17b39&prod&lang=scss"
import style1 from "./ProductVariantDropdown.vue?vue&type=style&index=1&id=09b17b39&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09b17b39",
  null
  
)

export default component.exports