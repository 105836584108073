<template>
  <div>
    <div id="blackmodal-pop" class="active-overlay" @click="close()"></div>
    <div id="floating-balloon" tabindex="-1" @keydown.27="close">
      <section v-if="showHeader" class="floating-balloon-header">
        <h4 v-if="title || subtitle" class="floating-balloon-title">
          <strong>{{ title }}</strong>
          <small v-if="subtitle" class="floating-balloon-info">
            {{ subtitle }}
          </small>
          <!-- 
          <small v-if="table" class="floating-balloon-info">
            <small>Tabela</small>
            {{table}}
          </small>
          <small v-if="pgto" class="floating-balloon-info">
            <small>Pagamento</small>
            {{pgto}}
          </small>
          <small v-if="cond" class="floating-balloon-info">
            <small>Condição</small>
            {{cond}}
          </small>-->
        </h4>

        <button
          class="btn-transparent floating-balloon-close-btn"
          @click="close()"
        >
          <i class="fa fa-times close-desktop"></i>
          <i class="fa fa-chevron-down close-mobile"></i>
        </button>
      </section>
      <section class="floating-balloon-body">
        <slot name="body">Nenhum conteúdo</slot>
      </section>
      <section class="floating-balloon-subfooter">
        <slot name="subfooter"></slot>
      </section>
      <section class="floating-balloon-footer">
        <slot name="footer">
          <button class="btn btn-primary" @click="close()">Fechar</button>
        </slot>
      </section>
    </div>
  </div>
</template>

<script>
import bus from "@/utils/events/bus";

export default {
  name: "FloatingBalloon",
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false
    },
    showHeader: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data() {
    return {
      jDivInterval: null
    };
  },
  created() {
    this.$emit("open", true);
    bus.$emit("modalIsOpen", true);

    document.documentElement.style.setProperty("--jivochat-right", "605px");
  },
  mounted() {
    this.toggleJivoChatPosition();
    this.$nextTick(() => {
      $("#floating-balloon").focus();
    });
  },
  beforeDestroy() {
    document.documentElement.style.setProperty("--jivochat-right", "30px");

    this.$nextTick(() => {
      bus.$emit("modalIsOpen", false);
    });
  },

  methods: {
    close() {
      this.$emit("close");
    },
    toggleJivoChatPosition(reset = false) {
      var jdiv = document.getElementsByTagName("jdiv");

      if (jdiv && jdiv.length > 3) {
        jdiv = jdiv[3];

        if (reset) {
          jdiv.style.right = "30px";
          clearInterval(this.jDivInterval);
          return;
        }

        this.jDivInterval = setInterval(() => {
          jdiv.style.right = "585px";
        }, 200);
      }
    }
  }
};
</script>

<style lang="scss">
:root {
  --jivochat-right: 30px;
}

.notranslate,
#jcont {
  right: var(--jivochat-right) !important;
}
</style>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

.floating-balloon-info {
  font-weight: 500;
  color: white;
  font-size: 12px;
  padding: 0 5px;

  // small {
  //   color: #333;
  //   font-size: 12px;
  //   font-weight: 400;
  // }
}
#floating-balloon {
  position: fixed;
  height: 100%;
  top: 0;
  border-radius: 0;

  // position: absolute;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.29) 1px 1px 10px -1px;
  color: rgb(164, 164, 164);
  font-weight: normal;
  width: 585px;
  right: 0px;
  z-index: 9999;
  // border-radius: 6px;

  display: flex;
  flex-direction: column;
  // max-height: calc(100vh - 200px);

  &:before {
    content: "";
    top: -15px;
    right: 55px;
    position: absolute;
    z-index: 10000;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid white;
  }
}
.floating-balloon-header {
  -webkit-box-flex: 0;
  flex: 0 1 auto;
  padding: 15px;
  margin: 0;
  color: #fff;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: var(--brand-primary, $brand-primary);

  .floating-balloon-title {
    color: #fff;
    margin: 0;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .floating-balloon-close-btn {
    background: transparent;
    border: none;

    i {
      font-size: 20px;
    }
  }

  .close-mobile {
    display: none;
  }
}

.floating-balloon-body {
  flex: 1 1 auto;
  overflow: auto;
  // height: calc(100vh - 350px);
  // max-height: calc(100vh - 350px);
  // max-height: 450px;
}

.floating-balloon-footer {
  flex: 0 1 auto;
  padding: 10px 15px;
  // background: rgb(241, 241, 241);
  background: white;
  display: flex;
  border-radius: 0px 0px 6px 6px;
  justify-content: flex-end;
}

.floating-balloon-subfooter {
  flex: 0 1 auto;
}

//Responsive
@media (max-width: 520px) {
  #floating-balloon {
    border-radius: 0;
    height: 100%;
    // height: 100vh;
    max-height: unset !important;
    position: fixed;
    width: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 10001;
  }
  .floating-balloon-body {
    height: calc(100% - 125px);
    max-height: unset;
  }
  .close-mobile {
    display: block !important;
  }
  .close-desktop {
    display: none;
  }
}
</style>
