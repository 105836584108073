<template>
  <div id="section-parceiros" class="container-fluid">
    <div class="slider-parceiros-body">
      <VueSlickCarousel v-bind="slideSettings" class="slider-parceiros">
        <div
          v-for="(parceiro, index) in sliders"
          :key="index"
          class="slider-parceiros-wrapper"
        >
          <div class="slider-parceiros-item">
            <router-link
              tag="a"
              :to="{
                name: 'catalogoRota',
                query: {
                  busca: $route.query.busca,
                  categoria: $route.query.categoria,
                  fornecedor: parceiro.link,
                  sub: $route.query.sub,
                  ordem: $route.query.ordem,
                  visualizacao: $route.query.visualizacao,
                },
              }"
            >
              <ImageItem custom-class="img-responsive" :source="parceiro.src" />
            </router-link>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import ImageItem from "@/components/image/ImageItem";

export default {
  name: "ParceirosSlider",
  components: { VueSlickCarousel, ImageItem },
  props: {
    sliders: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      slideSettings: {
        infinite: true,
        speed: 800,
        slidesToShow: 8,
        slidesToScroll: 8,
        dots: false,
        autoplay: false,
        autoplaySpeed: 4000,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 4,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 4,
            },
          },
          {
            breakpoint: 815,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
            },
          },
          {
            breakpoint: 630,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 4,
            },
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 365,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 275,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  deactivated() {},
  activated() {
    // this.mountSlider();
  },
  mounted() {
    // this.mountSlider();
  },
  methods: {},
};
</script>
<style lang="scss">
.slider-parceiros {
  .slick-next {
    right: -20px;
  }

  .slick-prev {
    left: -20px;
  }
}
</style>
<style lang="scss" scoped>
@import "../../assets/css/sass/bootstrap/variables";

#section-parceiros {
  padding-top: 15px;
  padding-bottom: 15px;
  max-width: 1360px;
  width: calc(100% - 20px);

  @media (max-width: 525px) {
    padding: 0 10px;
  }
}

.slider-parceiros-wrapper {
  padding: 5px;

  @media (max-width: 525px) {
    padding: 2.5px;
  }
}
.slider-parceiros-item {
  display: flex !important;
  height: 90px !important;
  // margin: 5px;
  border-radius: 6px;
  // margin: 8px;
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 525px) {
    height: 55px !important;
  }
}

:focus {
  outline: 0;
}

.slider-parceiros-body {
  display: flex;
}
.slider-parceiros {
  z-index: 0;
}

/* END EFEITO PRETO E BRANCO PARCEIROS */
</style>
<style>
.slider-parceiros button.slick-prev {
  left: -10px;
}

.slider-parceiros button.slick-next {
  right: -10px;
}

@media (max-width: 1360px) {
  #section-parceiros {
    width: 100% !important;
  }
}

/* EFEITO PRETO E BRANCO PARCEIROS */

.slider-parceiros img {
  transition: all 0.25s ease;

  filter: grayscale(75%);
  opacity: 0.66 !important;
  padding: 10px;
  max-height: 70px;
}

.slider-parceiros img:hover {
  filter: none;
  opacity: 1;
}
</style>
