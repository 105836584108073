<template>
  <div class="content-box">
    <div v-if="title" class="cb-title">
      <span>{{ title }}</span>
    </div>
    <div v-if="$slots['body']" class="cb-body">
      <slot name="body"></slot>
    </div>
    <div v-if="$slots['footer']" class="cb-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { mascaraValor, toTitleCase } from "@/app/global";

export default {
  name: "ContentBox",
  props: {
    title: {
      required: false,
      type: String,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.content-box {
  width: 100%;
  display: flex;
  flex-direction: column;

  .cb-title {
    span {
      padding-top: 20px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      line-height: 23px;
      letter-spacing: 0.02em;
      color: #000000;
    }
  }

  .cb-body {
    padding: 15px 0px 0 0;
    flex: 1 1 auto;
  }
  .cb-footer {
    padding: 10px 0px 0 0;

    &.right {
      align-self: flex-end;
    }
  }
}
</style>
