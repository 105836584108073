import defaults from "lodash/defaults";
import get from "lodash/get";

import axios from "axios";
import cookie from "vue-cookie";
import store from "./vuex";
import def from "@/../node_modules/axios/lib/defaults";
import bus from "@/utils/events/bus";
import router from "@/router";

export const CancelToken = () => axios.CancelToken;
export const connection = (options = {}) => {
  def.headers = {
    // Authorization: `Bearer ${cookie.get("token")}`,
    Identifier: `${cookie.get("identifier")}`,
    Accept: "application/json",
  };
  def.baseURL = process.env.VUE_APP_ECOMMERCE;
  def.withCredentials = true;

  const instance = axios.create(defaults(options, def));

  // EXECUTADO ANTES DA REQUISIÇÃO NO SERVIDOR
  instance.interceptors.request.use(
    (config) => {
      // REMOVER ESSA PARTE DO TOKEN QUANDO DEIXAR DE USAR A API SITE.
      if (Account.isAuthenticated()) {
        config.url += `?ec=1`;
      }

      return config;
    },
    (error) => {
      // eslint-disable-next-line no-console
      console.error("erro antes da requisicao");

      return Promise.reject(error);
    }
  );

  // EXECUTADO DEPOIS DA REQUISIÇÃO NO SERVIDOR
  instance.interceptors.response.use(
    (response) => {
      const newtoken = get(response, "headers.authorization");
      if (newtoken) {
        store.dispatch("setToken", newtoken);
        cookie.set("token", newtoken, 365);
      }

      return response;
    },
    (error) => {
      // eslint-disable-next-line no-console
      console.log(error);
      if (error != "Cancel") {
        switch (error.response.status) {
          case 429:
            bus.$emit("show-notification", [
              "warn",
              "Desculpe.",
              "Muitas requisições realizadas em pouco tempo, favor tentar novamente dentro de alguns minutos.",
            ]);
            break;
          case 401:
          case 419:
            Account.logoff(true);
            break;
          case 503:
            if (router.history?.current?.name != "maintenance") {
              router.push(
                `/maintenance?redirect=${window.location.origin}${window.location.pathname}`
              );
            }
            break;
          default:
            // eslint-disable-next-line no-console
            console.error(error.response);
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
};
