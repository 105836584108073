<template>
  <div
    v-if="redeSelected"
    class="select-unidade-wrapper"
    :class="{ 'not-select': !showAsSelect }"
  >
    <select
      v-if="showAsSelect"
      v-model="selectUnidade"
      class="selectUnidade"
      :class="{ dark: themeColor == 'dark' }"
    >
      <option
        v-for="option in listagemRedes"
        :key="option.id"
        :value="option.id"
      >
        {{ formataDocumento(option.cpfcnpj) }} -
        {{ parseNomeRazao(option.nomerazao) }}
      </option>
    </select>

    <div v-else class="selectUnidade" :class="{ dark: themeColor == 'dark' }">
      {{ formataDocumento(redeSelected.cpfcnpj) }} -
      {{ parseNomeRazao(redeSelected.nomerazao) }}
    </div>
  </div>
</template>

<script>
import cookie from "vue-cookie";

import * as global from "@/app/global";
import pedidoBusca from "@/mixins/pedidoBusca";
import bus from "@/utils/events/bus";

export default {
  name: "RazaoCardInfo",
  mixins: [pedidoBusca],
  props: {
    themeColor: {
      type: String,
      default: "light",
    },
    showAsSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectUnidade: 0,
      listagemRedes: [],
    };
  },
  computed: {
    redeSelected() {
      return this.listagemRedes.find((x) => x.id == this.selectUnidade);
    },
    dadosPedido() {
      return this.$store.getters.dadosPedido;
    },
  },
  watch: {
    selectUnidade(newValue, oldValue) {
      this.handleSelectUnidade(newValue, !!oldValue);

      // this.$emit("selectUnidade", newValue);
    },
    dadosPedido(newValue) {
      if (newValue.razao) {
        if (this.selectUnidade == newValue.razao) return;
        this.selectUnidade = newValue.razao;
      }
    },
  },
  created() {
    this.buscaRede();
  },
  methods: {
    formataDocumento(documento) {
      return global.mascaraCpfCnpj(documento);
    },
    parseNomeRazao(nomeRazao) {
      if (nomeRazao.length <= 23) {
        return nomeRazao;
      }

      return `${nomeRazao.slice(0, 23)}...`;
    },
    async buscaRede() {
      try {
        const response = await dpcAxios
          .connection()
          .get(process.env.VUE_APP_ECOMMERCE + api.env.BUSCA_CLIENTES_REDE_EC);
        if (response.data.error == 0) {
          const { clientes } = response.data;

          if (clientes.length == 0) {
            return;
          }
          if (clientes.length == 1) {
            this.selectUnidade = clientes[0].id;
          }

          const listagemRedes = [];

          $.each(clientes, (index, value) => {
            listagemRedes.push(value);
          });

          const clienteAtual = localStorage.getItem("clienteAtual");

          const clienteCached = listagemRedes.find(
            (item) => item.id == clienteAtual
          );

          if (clienteCached) {
            this.selectUnidade = clienteCached.id;
          } else {
            this.$store.dispatch("setClienteAtual", 0);
          }
          //  else {
          //   this.selectUnidade = listagemRedes[0].id;
          // }

          this.listagemRedes = listagemRedes;
          this.$emit("listagemRedes", listagemRedes);
        } else {
          // bus.$emit("show-notification", [
          //   "error",
          //   "Desculpe.",
          //   "Algo deu errado ao buscar a rede atrelada ao seu usuário, favor tentar novamente mais tarde.",
          // ]);
        }
      } catch (err) {
        // bus.$emit("show-notification", [
        //   "error",
        //   "Desculpe.",
        //   "Algo deu errado ao buscar os dados do seu usuário, favor tentar novamente mais tarde.",
        // ]);
        console.error(err);
      }
    },
    async handleSelectUnidade(clienteId, hasOldValue) {
      this.$store.dispatch("setClienteAtual", clienteId);

      if (clienteId === 0) return;

      const hasPedidoAberto = !!localStorage.getItem("pedidoaberto");

      if (
        hasPedidoAberto &&
        this.dadosPedido.razao == clienteId
        // || (hasPedidoAberto && Object.keys(this.dadosPedido) == 0)
      )
        return;

      const loadingId = Date.now() + Math.random();
      this.$store.dispatch("setShowLoadingModal", [true, loadingId]);

      await this.buscaPedidoAberto({ cliente_id: clienteId });

      this.$store.dispatch("setShowLoadingModal", [false, loadingId]);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-unidade-wrapper {
  position: relative;
  margin-right: 15px;

  &:after {
    content: " ";
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: -15px;
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2.8px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

.selectUnidade {
  // width: auto;
  font-size: 14px;
  // line-height: 1.42857;
  color: white;
  // padding: 4px;
  // margin: 5px 0 10px 0;
  // border: 2px solid #eee;
  // border-radius: 4px;
  background-color: transparent;
  border: none;
  outline: none;

  appearance: none;

  &.dark {
    color: black;
  }

  option {
    color: black;
  }

  cursor: pointer;

  &:focus {
    // border: 2px solid #00773d;
  }

  span {
    display: block;
  }
}

@media (max-width: 991px) {
  .select-unidade-wrapper.not-select {
    margin-right: 15px;
    margin-left: 15px;
    &:after {
      right: 2px;
    }

    .selectUnidade {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
    }
  }
}
</style>
