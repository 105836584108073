<template>
  <div id="loading-slider-parceiros">
    <div class="skeleton slider-parceiros-carousel">
      <div v-for="(value, index) in 17" :key="index" class="skeleton-wrapper">
        <div class="skeleton-wrapper-inner">
          <div class="skeleton-wrapper-body">
            <div class="skeleton-slider-parceiros"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoriasSlider",
  components: {},
  data() {
    return {};
  },
  mounted () {
    $(".slider-categorias-destaque")
      .not(".slick-initialized")
      .slick({
        infinite: true,
        speed: 800,
        slidesToShow: 8,
        slidesToScroll: 2,
        dots: false,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 4,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 4,
            },
          },
          {
            breakpoint: 815,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
            },
          },
          {
            breakpoint: 630,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            },
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 365,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 275,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
  },
  methods: {},
};
</script>
<style lang="scss">
.slider-categorias-destaque {
  .slick-next {
    right: -20px;
  }
  .slick-prev {
    left: -20px;
  }
}
</style>
<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";
@import "~@/assets/css/global.css";
#section-categorias-destaque {
  padding-bottom: 30px;
  max-width: 1380px;
  width: calc(100% - 20px);
}
.skeleton-wrapper {
  background: #fff;
  //border: 1px solid;
  //border-color: #e5e6e9 #dfe0e4 #d0d1d5;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  width: 290px;
  float: left;
  margin: 10px 15px;
}

.skeleton-wrapper-inner {
  height: 70px;
  padding: 70px;
  position: relative;
}

.skeleton-wrapper-body div {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderSkeleton;
  -webkit-animation-timing-function: linear;
  background: #f6f7f8;
  background-image: -webkit-gradient(
    linear,
    left center,
    right center,
    from(#f6f7f8),
    color-stop(0.2, #edeef1),
    color-stop(0.4, #f6f7f8),
    to(#f6f7f8)
  );
  background-image: -webkit-linear-gradient(
    left,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: auto;
  height: auto;
  position: relative;
}
.skeleton-wrapper-body {
  -webkit-animation-name: skeletonAnimate;
  background-image: -webkit-gradient(
    linear,
    center top,
    center bottom,
    from(deg),
    color-stop(0, red),
    color-stop(0.15, orange),
    color-stop(0.3, yellow),
    color-stop(0.45, green),
    color-stop(0.6, blue),
    color-stop(0.75, indigo),
    color-stop(0.8, violet),
    to(red)
  );
  background-image: -webkit-linear-gradient(
    135deg,
    red 0%,
    orange 15%,
    yellow 30%,
    green 45%,
    blue 60%,
    indigo 75%,
    violet 80%,
    red 100%
  );
  background-repeat: repeat;
  background-size: 50% auto;
}
.skeleton-wrapper-body div {
  position: absolute;
  right: 15px;
  left: 15px;
  top: 15px;
}
div.skeleton-slider-parceiros {
  top: 0px;
  height: 100%;
  left: 0px;
  right: 0px;
  width: 100%;
}
@-webkit-keyframes placeholderSkeleton {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 1200px;
  }
}
@-webkit-keyframes skeletonAnimate {
  from {
    background-position: top left;
  }
  to {
    background-position: top right;
  }
}
</style>