<template>
  <div>
    <modal
      :show-modal="showModal"
      class="modal-change-password"
      :titulo="'Alteração de senha'"
      @close="forgotPassword == true ? close() : null"
    >
      <div slot="body">
        <div class="modal-container">
          <div class="col-md-7 modal-column">
            <p v-if="!forgotPassword">
              Olá caro cliente, por motivos de segurança, todos os nossos
              usuários devem alterar suas senhas no primeiro acesso. Mas não se
              preocupe, vamos te ajudar a escolher uma senha segura!
            </p>
            <p v-else>
              Olá caro cliente, por motivos de segurança, todos os nossos
              usuários devem possuir uma senha forte. Mas não se preocupe, vamos
              te ajudar a escolher uma senha segura!
            </p>

            <p>A sua senha segura deve conter:</p>

            <p
              :class="{
                'texto-riscado': validationLength,
              }"
            >
              <i class="fa fa-check" /> Pelo menos 8 caracteres.
            </p>
            <p
              :class="{
                'texto-riscado': validationMaiuscula,
              }"
            >
              <i class="fa fa-check" /> Pelo menos um caractere maiúsculo (A-Z);
            </p>
            <p
              :class="{
                'texto-riscado': validationMinuscula,
              }"
            >
              <i class="fa fa-check" /> Pelo menos um caractere minúsculo (a-z);
            </p>
            <p
              :class="{
                'texto-riscado': validationNumerico,
              }"
            >
              <i class="fa fa-check" /> Pelo menos um caractere númerico (0-9)
            </p>
            <p
              :class="{
                'texto-riscado': validationEspecial,
              }"
            >
              <i class="fa fa-check" /> Pelo menos um caractere especial (*, @,
              !, $, %, ...);
            </p>
          </div>
          <div class="col-md-5 modal-column">
            <form
              class="password-form"
              autocomplete="off"
              @submit.prevent="onSubmit"
            >
              <input
                v-model="$v.formResponses.password.$model"
                :type="showPassword ? 'text' : 'password'"
                name="password"
                placeholder="Nova senha"
                class="password-input"
              />
              <p
                v-if="
                  !$v.formResponses.password.strongPassword &&
                  formResponses.password.length
                "
                class="error"
              >
                Você precisa definir uma senha forte. Veja ao lado como como
                criar a sua.
              </p>
              <input
                v-model="$v.formResponses.confirmPassword.$model"
                :type="showPassword ? 'text' : 'password'"
                name="confirmPassword"
                placeholder="Confirmar nova senha"
                class="password-input"
              />
              <input
                v-if="forgotPassword"
                v-model="myToken"
                type="text"
                name="token"
                :disabled="!!token"
                placeholder="Token de alteração de senha"
                class="token"
                autocomplete="off"
              />
              <span
                title="Ver senha"
                class="btn btn-link btn-sm view-password"
                @click="showPassword = !showPassword"
              >
                <i
                  class="fa"
                  :class="{
                    'fa-eye': showPassword,
                    'fa-eye-slash': !showPassword,
                  }"
                ></i>
                Exibir senha
              </span>
              <p
                v-if="
                  !$v.formResponses.confirmPassword.sameAsPassword &&
                  formResponses.confirmPassword.length
                "
                class="error"
              >
                A sua senha deve ser igual nos dois campos
              </p>
              <p class="mensagem">
                {{ retornoMensagem }}
              </p>
              <p v-if="errors" class="error">
                O formulário acima possui erros, favor corrigir e enviar
                novamente.
              </p>
              <p v-else-if="formTouched && uiState" class="error">
                O formulário acima está vazio, preencha algo para enviar.
              </p>
              <button type="submit">SALVAR</button>
            </form>
          </div>
        </div>
      </div>
      <div slot="footer" class="footer-modal"></div>
    </modal>
  </div>
</template>

<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import modal from "@/components/root/modal/Modal";
import bus from "@/utils/events/bus";

export default {
  name: "ModalChangePassword",
  components: { modal },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    forgotPassword: {
      type: Boolean,
      default: false,
    },
    token: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formResponses: {
        password: "",
        confirmPassword: "",
      },
      myToken: "",
      showPassword: false,
      errors: false,
      formTouched: false,
      retornoMensagem: "",
      retornoErro: false,
    };
  },
  computed: {
    validationLength() {
      return this.formResponses.password.length >= 8;
    },
    validationMaiuscula() {
      return /[A-Z]/.test(this.formResponses.password);
    },
    validationMinuscula() {
      return /[a-z]/.test(this.formResponses.password);
    },
    validationNumerico() {
      return /[0-9]/.test(this.formResponses.password);
    },
    validationEspecial() {
      return /\W|_/.test(this.formResponses.password);
    },
  },
  validations: {
    formResponses: {
      password: {
        required,
        strongPassword(password) {
          return (
            /[A-Z]/.test(password) &&
            /[a-z]/.test(password) &&
            /[0-9]/.test(password) &&
            /\W|_/.test(password) &&
            password.length >= 8
          );
        },
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  created() {
    this.myToken = this.token;
  },
  methods: {
    /*
     * Função: Method resposável por emitir o evento de fechar o modal de parametros.
     * Chamada por: Evento '@close' do componente 'Modal'.
     * */
    close() {
      this.$emit("close");
    },

    /**
     * Função responsável por remover item do carrinho
     * Chamada por botão no modal
     */

    async onSubmit(e) {
      this.formTouched = !this.$v.formResponses.$anyDirty;
      this.errors = this.$v.formResponses.$anyError;
      this.retornoMensagem = "";
      this.retornoErro = false;

      if (this.errors === false && this.formTouched === false) {
        if (!this.forgotPassword) {
          const { data } = await dpcAxios
            .connection()
            .post(`${process.env.VUE_APP_ECOMMERCE_AUTH}trocasenha`, {
              senha: this.formResponses.password,
              confirmaSenha: this.formResponses.confirmPassword,
            });

          this.retornoMensagem = data.mensagem;
          this.retornoErro = data.error;
        } else {
          const { data } = await dpcAxios
            .connection()
            .post(`${process.env.VUE_APP_ECOMMERCE_AUTH}alterarsenha`, {
              senha: this.formResponses.password,
              token: this.myToken,
            });

          this.retornoMensagem = data.mensagem;
          this.retornoErro = data.error;
        }

        if (!this.retornoErro) {
          // Fecha o modal após alterar a senha
          setTimeout(() => {
            this.close();
          }, 1000);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/_variables.scss";
.modal-container {
  .modal-column {
    display: flex;
    flex-direction: column;

    .texto-riscado {
      color: #1c8c48;
      text-decoration: line-through;
    }

    i {
      color: #1c8c48;
    }

    .password-form {
      width: 100%;
      display: flex;
      flex-direction: column;

      .password-input,
      .token {
        margin: 10px 0 0 0;
        width: 100%;
        height: 50px;
        border-radius: 4px;
        box-shadow: none;
        border: 1px solid #ced6e0;
        transition: all 0.3s ease-in-out;
        font-size: 14px;
        padding: 5px 15px 5px 40px;
        background: url("/public/lock.svg") no-repeat center left 15px;
        background-color: #fff;
        background-size: 15px;
        color: #286d46;

        &.token {
          background: url("/public/key.svg") no-repeat center left 15px;
          background-size: 15px;
        }

        &:hover,
        &:focus {
          border-color: $brand-primary;
        }

        &:focus {
          box-shadow: 0px 10px 20px -13px rgba(0, 152, 64, 0.45);
        }
      }

      button {
        transition: 0.3s ease;
        width: 100%;
        background: $brand-primary;
        border: none;
        border-radius: 4px;
        padding: 10px 15px;
        font-size: 18px;
        font-weight: 500;
        box-shadow: 3px 10px 20px 0px rgba(0, 152, 64, 0.45);
        color: #fff;
        margin-top: 20px;
        cursor: pointer;

        &:hover {
          box-shadow: 3px 10px 20px 0px rgba(0, 152, 64, 0.3);
        }
      }

      .error {
        color: #f34c3c;
      }

      .view-password {
        align-self: flex-end;
        font-size: 15px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.modal-close-btn {
  display: none !important;
}
</style>
