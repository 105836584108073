<template>
  <section id="section-newsletter">
    <div id="newsletter" class="container-fluid">
      <div class="container">
        <div class="row newsletter-footer">
          <div class="col-md-4">
            <h3 class="text-left">
              cadastre-se e receba<br />
              as
              <strong>NOVIDADES!</strong>
            </h3>
          </div>
          <div class="col-md-8">
            <div class="input-group">
              <input
                type="text"
                class="form-control input"
                placeholder="E-mail"
              />
              <span class="input-group-btn">
                <button class="btn btn-info" type="button">Cadastre-se</button>
              </span>
            </div>
          </div>
          <!-- <div class="col-sm-3">
                                                                <h3><strong>Dúvidas?</strong></h3>
                                                                <p>Estamos aqui para
                                                                    <strong>ajudar!</strong>
                                                                </p>
                                                            </div>
                                                            <div class="col-sm-3">
                                                                <div class="item-info">
                                                                    <span class="lnr lnr-bubble"></span>
                                                                    <p>Chat Online</p>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-3">
                                                                <div class="item-info">
                                                                    <span class="lnr lnr-bubble"></span>
                                                                    <p>Call Center</p>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-3">
                                                                <div class="item-info">
                                                                    <span class="lnr lnr-bubble"></span>
                                                                    <p>Envie-nos um email</p>
                                                                </div>
                                                            </div> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Newsletter",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

#section-newsletter {
  margin-top: 20px;
}

#newsletter {
  background-color: var(--brand-primary, $brand-primary);
  color: #fff;
  padding: 10px 0px;

  h3 {
    margin-bottom: 2px;
    margin-top: 10px;

    strong {
      color: $brand-info;
    }
  }

  .item-info {
    font-size: 18px;
    font-weight: 100;
    margin-top: 15px;
    padding: 8px 20px;
    text-align: center;
    border-radius: 0px 5px 0px 5px;
    display: inline-flex;
    width: 100%;
    position: relative;

    .lnr {
      font-size: 1.7em;
      margin-right: 8px;
    }
  }
}

.call-action-chat {
  float: left;
  margin-right: 18px;
}

.btn-green-lemon-inverse {
  margin-top: 15px;
  position: relative;
  float: left;
  padding-left: 30px;
  padding-right: 30px;
}

.newsletter-footer {
  padding: 15px 0px;

  h3 {
    margin-top: 0px !important;
    font-weight: 100;

    strong {
    }
  }

  input,
  button {
    margin-top: 6px;
    height: 45px;
  }
}
</style>
