var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"review-payment-resume"},[(
      _vm.paymentData &&
      _vm.paymentData.card &&
      _vm.paymentMethod === _vm.PAYMENT_METHODS.CREDIT_CARD
    )?_c('payment-resume-info',{attrs:{"icon":"mdi mdi-credit-card-multiple-outline fa-2x","title":"Cartão de crédito","content":`${
      _vm.toTitleCase(_vm.paymentData.card.brand) ||
      _vm.toTitleCase(_vm.paymentData.card.cardBrand) ||
      'Bandeira'
    } **** ${_vm.paymentData.card.desc || '0000'}`}},[_c('div',{attrs:{"slot":"action"},slot:"action"},[_c('router-link',{staticClass:"rpr-link",attrs:{"to":{ name: 'checkoutPayment', query: { ..._vm.$route.query } }}},[_vm._v("Alterar")])],1)]):(_vm.paymentMethod === _vm.PAYMENT_METHODS.AVISTA)?_c('payment-resume-info',{attrs:{"icon":"mdi mdi-barcode fa-2x","title":"A vista","content":`Boleto`}}):(_vm.paymentMethod === _vm.PAYMENT_METHODS.BOLETO)?_c('payment-resume-info',{attrs:{"icon":"mdi mdi-barcode fa-2x","title":"Boleto","content":_vm.dadosPedido.informacoes.condicaoPagamento}}):(_vm.paymentMethod === _vm.PAYMENT_METHODS.DINHEIRO)?_c('payment-resume-info',{attrs:{"icon":"mdi mdi-cash fa-2x","title":"Dinheiro","content":_vm.dadosPedido.informacoes.condicaoPagamento}}):(_vm.paymentMethod === _vm.PAYMENT_METHODS.DEPOSITO_ANTECIPADO)?_c('payment-resume-info',{attrs:{"icon":"mdi mdi-cash fa-2x","title":"Depósito Antecipado","content":_vm.dadosPedido.informacoes.condicaoPagamento}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }