<template>
  <div class="w-full flex items-center justify-center">
    <section
      class="default flex flex-col w-full justify-center max-width-section"
    >
      <span v-if="showTitle" class="flex items-center justify-center">
        <h1
          class="text-default-title-xl text-center mb-10 mt-0 pb-2 border-b-4 border-green-custom font-bold border-green-custom"
        >
          {{ data.title }}
        </h1>
      </span>
      <div class="flex items-center flex-row justify-center flex-wrap">
        <a
          v-for="(image, index) of data"
          :key="index"
          :href="image.href"
          :class="`p-3 w-banner-${data.length}  w-bannner`"
          :aria-label="image.title"
        >
          <ImageItem
            custom-class="w-full rounded-default"
            :source="item.src"
            :alt="image.description"
          />
        </a>
      </div>
    </section>
  </div>
</template>

<script>
import ImageItem from "@/components/image/ImageItem";

export default {
  name: "BannerDefault",
  components: {
    ImageItem,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      window: {
        width: 1920,
        height: 1080,
      },
      isMobile: false,
      isDesktop: true,
      isTablet: false,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/global.css";
@import "../../assets/css/sass/bootstrap/variables";
.border-green-custom {
  border-bottom-style: solid;
  border-bottom-color: var(--brand-primary, $brand-primary);
}
@media (max-width: 940px) {
  // .w-bannner {
  // width: 50%;
  // }
}
@media (max-width: 650px) {
  .w-bannner {
    width: 100%;
  }
}
</style>
