var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"banners-destaque",class:{ hovertop: _vm.hovertop, hoverbottom: _vm.hoverbottom }},[_c('div',{class:{
      container1: !_vm.containerfluid,
      'container-fluid1': _vm.containerfluid,
    }},_vm._l((_vm.banners),function(banner,index){return _c('div',{key:index},_vm._l((banner.row),function(item,index){return _c('div',{key:index,staticClass:"banner",class:[
          'col-xs-' + item.col_xs,
          'col-sm-' + item.col_sm,
          'col-md-' + item.col_md,
          {
            'hidden-xs': item.h_xs,
            'hidden-sm': item.h_sm,
            'hidden-md': item.h_md,
            'hidden-lg': item.h_lg,
            'no-padding1': _vm.containerfluid,
          },
        ]},[(_vm.checkLink(item.link))?_c('div',[_c('a',{attrs:{"href":item.link}},[_c('ImageItem',{attrs:{"custom-class":"img-responsive","source":item.src}})],1)]):_c('div',[_c('img',{staticClass:"img-responsive",attrs:{"loading":"lazy","src":item.src,"alt":"Banner"}})])])}),0)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }