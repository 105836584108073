<template>
  <div class="callContainer">
    <div>
      <span
        >Cadastre-se para receber
        <strong>nossas ofertas exclusivas!</strong></span
      >
    </div>
    <div class="inputContainer">
      <input type="text" placeholder="Digite seu e-mail" />
      <input type="text" placeholder="Digite seu nome" />
      <button>Cadastrar</button>
    </div>
  </div>
</template>
<script>
export default {
  name: "ToolbarCallCenter",
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.callContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
  background: #1c342f;
  padding: 12px 0;
  width: 100%;
  .inputContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
  span {
    color: white;
  }
  strong {
    color: #92e3a9;
  }
  button {
    padding: 5px 25px;
    font-size: 13px;
    border: none;
    background: #09af5f;
    color: white;
    border-radius: 4px;
    font-weight: bold;
  }
  button:hover {
    transition: 0.3s;
    cursor: pointer;
    background: #058547;
  }
  input {
    padding: 7px;
    width: 225px;
    border: none;
    border-radius: 5px;
  }
  input::placeholder {
    color: #a5a5a5;
  }
}

@media (max-width: 720px) {
  .callContainer {
    text-align: center;
    flex-direction: column;
    gap: 20px;
    font-size: 16px;
  }
  .inputContainer {
    flex-direction: column;
  }
  button {
    padding: 7px 25px;

    font-size: 14px;
  }
}
@media (max-width: 1030px) {
  .callContainer {
    gap: 5px;
  }
}
</style>