<template>
  <ul class="sub-menu">
    <slot name="row-start"></slot>
    <li
      v-for="(o, index) in itemList"
      v-if="index <= maxRows || !maxRows"
      :key="index"
    >
      <a href="#" onclick="return false;" class="text-capitalize">
        {{ capitalize(o.descricao) }}
        <i
          v-if="o.filhos"
          aria-hidden="true"
          class="fa fa-angle-right ic-dep arrow"
        ></i>
      </a>

      <div v-if="o.filhos" class="painel">
        <!-- <BoxSubCategorias
          :categoria="o"
          :sub-categorias="o.filhos"
        ></BoxSubCategorias> -->
        <!-- <div>
          <h4>
            <strong>{{ capitalize(o.descricao) }}</strong>
          </h4>
        </div> -->
        <SubmenuTree v-if="o.filhos" :item-list="o.filhos"></SubmenuTree>
      </div>
    </li>
    <slot name="row-end"></slot>
    <!-- LI FIXA TODAS AS CATEGORIAS
    <li data-cod-categoria="TDCTGAS">
      <a href="#">
        <strong>Todas as Categorias</strong>
        <i aria-hidden="true" class="fa fa-angle-right ic-dep arrow"></i>
      </a>
      <div class="sub-menu painel">
        <BoxSubCategorias
          :categoria="infoBoxTodasCategorias[0]"
          :sub-categorias="this.$store.getters.categorias"
          :tipoSubCategoria="'CAT'"
        ></BoxSubCategorias>
      </div>
    </li> -->
  </ul>
</template>

<script>
import _capitalize from "lodash/capitalize";

export default {
  name: "SubmenuTree",
  props: {
    maxRows: {
      required: false,
      default: null,
      type: Number,
    },
    itemList: {
      required: true,
      default: () => [],
      type: Object / Array,
    },
  },
  methods: {
    capitalize(text) {
      if (!text) return;

      return _capitalize(text);
    },
  },
};
</script>

<style lang="scss" scoped>
// .sub-menu {
//   margin: 0;
//   padding: 0;
//   list-style: none;
// }

// .sub-menu {
//   visibility: hidden;
//   display: none;
//   position: absolute;
//   width: 100%;
//   min-width: 180px;
//   background: #fff;
//   color: #444;
//   border-right: 0px solid #eee;
//   //   box-shadow: 0 5px 14px rgba(0, 0, 0, 0.15);
//   //   -webkit-box-shadow: 0 5px 14px rgba(0, 0, 0, 0.15);
//   //   -moz-box-shadow: 0 5px 14px rgba(0, 0, 0, 0.15);
//   //   -ms-box-shadow: 0 5px 14px rgba(0, 0, 0, 0.15);
//   //   -o-box-shadow: 0 5px 14px rgba(0, 0, 0, 0.15);
//   // border-radius: 0px 0px 5px 0px;

//   .sub-menu {
//     width: auto;
//     top: 0;
//     left: 100%;
//     z-index: -2;
//     display: block;
//     opacity: 0;
//     // min-height: 100%;
//   }

//   li:hover {
//     background: #f1f1f1;

//     // .sub-menu {
//     overflow: hidden;
//     opacity: 1;
//     // -webkit-transform: translateX(0em);
//     // -ms-transform: translateX(0em);
//     // transform: translateX(0em);
//     // -webkit-transition: all 0.2s ease;
//     // -moz-transition: all 0.2s ease;
//     // -ms-transition: all 0.2s ease;
//     // -o-transition: all 0.2s ease;
//     // transition: all 0.2s ease;

//     > .painel {
//       opacity: 1;
//       pointer-events: auto;
//       overflow: visible;
//       position: absolute;
//       height: 100%;
//       //   width: 100% !important;
//       .sub-menu {
//         opacity: 1;
//         pointer-events: auto;
//         overflow: visible;
//         width: 100% !important;
//         visibility: visible;
//         top: -40px;
//       }
//       // z-index: -1;
//       // -webkit-transition-delay: 0.2s;
//       // -moz-transition-delay: 0.2s;
//       // -ms-transition-delay: 0.2s;
//       // -o-transition-delay: 0.2s;
//       // transition-delay: 0.2s;
//       //   }
//     }
//   }

//   a {
//     display: block;
//     padding: 10px 12px 10px 19px;
//     color: #444 !important;
//     border-bottom: 1px solid #eee;

//     i {
//       color: #444 !important;
//       float: right;
//       margin-top: 4px;
//     }
//   }
// }

// .painel {
//   //   height: 100%;
//   //   position: absolute;
//   //   max-height: 100%;
//   position: absolute;
//   /* font-size: 0; */
//   //   top: 0;
//   /* margin: 0; */
//   width: 0 !important;
//   left: 100%;
//   border: none;
//   padding: 0;
//   width: auto;
//   pointer-events: none;
//   overflow: hidden;
//   transition: all 0.3s ease;
// }
</style>
