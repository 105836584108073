<template>
  <div class="banner-section flex items-center justify-center">
    <section
      :class="`
        default
        flex flex-col
        items-center
        justify-center
        max-width-section
        w-full
        ${margin}
      `"
    >
      <span v-if="showTitle" class="flex items-center justify-center">
        <h1
          class="text-default-title-xl text-center mb-10 mt-0 pb-2 border-b-4 border-green-custom font-bold border-green-custom"
        >
          {{ data.title }}
        </h1>
      </span>
      <div :class="`grid grid-cols-${cols} grid-rows-${rows} w-full ${gap}`">
        <a
          v-for="(image, index) of data"
          :key="index"
          :href="image.link"
          :class="`w-banner ${rules[index] ? rules[index].col : rules[0].col} ${
            rules[index] ? rules[index].row : rules[0].row
          }`"
        >
          <ImageItem
            custom-class="w-full rounded-default"
            :source="image.src"
          />
        </a>
      </div>
    </section>
  </div>
</template>

<script>
import LogInIcon from "vue-feather-icons/icons/LogInIcon";
import ImageItem from "@/components/image/ImageItem";

export default {
  name: "BannerRow",
  components: { LogInIcon, ImageItem },
  props: {
    loginRequired: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      required: true,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: String,
      default: "1",
    },
    cols: {
      type: String,
      default: "2",
    },
    gap: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
    },
    margin: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      window: {
        width: 1920,
        height: 1080,
      },
      isMobile: false,
      isDesktop: true,
      isTablet: false,
    };
  },
  computed: {
    returnLoginUrl() {
      return `${process.env.VUE_APP_CLIENTE}?redirect=${process.env.VUE_APP_ECOMMERCE_SITE}`;
    },
    isAuthenticated() {
      return this.$store.getters.getIsAuthenticated;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/global.css";
@import "../../assets/css/sass/bootstrap/variables";

.banner-section {
  width: calc(100% - 20px);
  box-sizing: border-box;
}

.rand1 {
  width: 10%;
  height: 20%;
}
.rand2 {
  width: 15%;
  height: 23%;
}
.rand3 {
  width: 21%;
  height: 34%;
}
.rand5 {
  width: 24%;
  height: 55%;
}
.rand6 {
  width: 20%;
  height: 31%;
}
.border-green-custom {
  border-bottom-style: solid;
  border-bottom-color: var(--brand-primary, $brand-primary);
}
.custon-class {
  color: var(--brand-primary, $brand-primary);
}
.border-green-rounded-custom {
  border-style: solid;
  border-color: var(--brand-primary, $brand-primary);
}

.mask {
  filter: blur(15px);
}
.login-required {
  height: 450px;
  width: 100% !important;
}
.btn-dpc {
  height: 42px;
  width: 210px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex: 0 0 120px;
  border-radius: 3px;
  color: rgb(255, 255, 255);
  background-color: var(--brand-primary, $brand-primary);
  font-size: 12px;
  font-weight: bold;
  margin-left: 15px;
}
.login-required-alert {
  height: 135px;
  width: 820px;
  background: rgb(255, 255, 255);
  box-shadow: #818181 0px 1px 2px 0px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px 30px;
}
@media (max-width: 550px) {
  .login-required-alert {
    flex-direction: column;
  }
  .text-dpc-green {
    text-align: center;
    margin-bottom: 5px;
  }
  .login-required-alert {
    height: 55% !important;
    padding: 0px;
    width: 85% !important;
  }
  .btn-dpc {
    width: 110px;
    flex: unset !important;
    padding: 20px;
    font-size: 10px;
    margin-left: 0px;
  }
}

@media (max-width: 640px) {
  .login-required-alert {
    height: 100px;
    padding: 0px 30px;
    width: 520px;
  }
  .btn-dpc {
    width: 110px;
    flex: 0 0 120px;
    font-size: 10px;
    margin-left: 10px;
  }
  .font-sm {
    font-size: 12px !important;
  }
  .col-span-2 {
    grid-column: span 1 / span 1;
  }
  .grid-cols-2 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .grid-cols-3 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .grid-cols-4 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
</style>
