<template>
  <div class="alertValorMinimo" :class="active ? 'active' : 'inactive'">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.98828 12.9883L12.9883 6.98828"
        stroke="#09AF5F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.48828 7.98828C7.76442 7.98828 7.98828 7.76442 7.98828 7.48828C7.98828 7.21214 7.76442 6.98828 7.48828 6.98828C7.21214 6.98828 6.98828 7.21214 6.98828 7.48828C6.98828 7.76442 7.21214 7.98828 7.48828 7.98828Z"
        :fill="active ? '#09AF5F' : '#ffb703'"
        stroke="#09AF5F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.4883 12.9883C12.7644 12.9883 12.9883 12.7644 12.9883 12.4883C12.9883 12.2121 12.7644 11.9883 12.4883 11.9883C12.2121 11.9883 11.9883 12.2121 11.9883 12.4883C11.9883 12.7644 12.2121 12.9883 12.4883 12.9883Z"
        :fill="active ? '#09AF5F' : '#ffb703'"
        stroke="#09AF5F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.98874 5.18874C2.98874 4.60527 3.22053 4.04569 3.63311 3.63311C4.04569 3.22053 4.60527 2.98874 5.18874 2.98874H6.18874C6.76964 2.98841 7.32683 2.75834 7.73874 2.34874L8.43874 1.64874C8.64319 1.44314 8.88626 1.27998 9.15398 1.16865C9.42171 1.05731 9.70879 1 9.99874 1C10.2887 1 10.5758 1.05731 10.8435 1.16865C11.1112 1.27998 11.3543 1.44314 11.5587 1.64874L12.2587 2.34874C12.6707 2.75834 13.2278 2.98841 13.8087 2.98874H14.8087C15.3922 2.98874 15.9518 3.22053 16.3644 3.63311C16.777 4.04569 17.0087 4.60527 17.0087 5.18874V6.18874C17.0091 6.76964 17.2391 7.32683 17.6487 7.73874L18.3487 8.43874C18.5543 8.64319 18.7175 8.88626 18.8288 9.15398C18.9402 9.42171 18.9975 9.70879 18.9975 9.99874C18.9975 10.2887 18.9402 10.5758 18.8288 10.8435C18.7175 11.1112 18.5543 11.3543 18.3487 11.5587L17.6487 12.2587C17.2391 12.6707 17.0091 13.2278 17.0087 13.8087V14.8087C17.0087 15.3922 16.777 15.9518 16.3644 16.3644C15.9518 16.777 15.3922 17.0087 14.8087 17.0087H13.8087C13.2278 17.0091 12.6707 17.2391 12.2587 17.6487L11.5587 18.3487C11.3543 18.5543 11.1112 18.7175 10.8435 18.8288C10.5758 18.9402 10.2887 18.9975 9.99874 18.9975C9.70879 18.9975 9.42171 18.9402 9.15398 18.8288C8.88626 18.7175 8.64319 18.5543 8.43874 18.3487L7.73874 17.6487C7.32683 17.2391 6.76964 17.0091 6.18874 17.0087H5.18874C4.60527 17.0087 4.04569 16.777 3.63311 16.3644C3.22053 15.9518 2.98874 15.3922 2.98874 14.8087V13.8087C2.98841 13.2278 2.75834 12.6707 2.34874 12.2587L1.64874 11.5587C1.44314 11.3543 1.27998 11.1112 1.16865 10.8435C1.05731 10.5758 1 10.2887 1 9.99874C1 9.70879 1.05731 9.42171 1.16865 9.15398C1.27998 8.88626 1.44314 8.64319 1.64874 8.43874L2.34874 7.73874C2.75834 7.32683 2.98841 6.76964 2.98874 6.18874V5.18874"
        stroke="#09AF5F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <div v-if="type == 'campanha'">
      <span v-if="!active"
        >Adicione mais R$ {{ mascaraValor(valorFaltante) }} em produtos para
        ativar este combo.</span
      >
      <span v-else>Valor mínimo atingido.</span>
    </div>
    <div v-else>
      <span v-if="!active"
        >Adicione mais R$ {{ mascaraValor(valorFaltante) }} em produtos para
        ativar este combo.</span
      >
      <span v-else>Combo ativado!</span>
    </div>
  </div>
</template>

<script>
import { mascaraValor } from "@/app/global.js";

export default {
  name: "CardValorMinimo",
  props: {
    valorFaltante: {},
    active: {},
    type: {},
  },
  methods: {
    mascaraValor,
  },
};
</script>

<style lang="scss" scoped>
.alertValorMinimo {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  border: 2px solid;
  border-radius: 5px;
}
.active {
  background-color: rgba($color: #09af5f, $alpha: 0.1);
  border-color: #09af5f;
  color: #09af5f;
}
.inactive {
  background-color: rgba($color: #ffb703, $alpha: 0.1);
  border-color: #ffb703;
  color: #ffb703;
  svg {
    path {
      stroke: #ffb703;
    }
  }
}
</style>
