<template>
  <div class="container page-not-found">
    <span class="pnf-404">404</span>

    <img
      width="300"
      src="@/assets/img/404/empty-box.svg"
      alt="Page not found"
    />

    <h3>Ops! Parece que você está perdido :(</h3>

    <p>
      Confira se você digitou direitinho o endereço que queria. Se estiver tudo
      certo, pode ser que o conteúdo tenha sido removido.
    </p>
    <div class="pnf-btn">
      <router-link to="/">
        <button class="btn btn-primary">Voltar ao início</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  metaInfo: {
    title: "404",
    titleTemplate: "Página não encontrada",
    meta: [],
  },

  components: {},
};
</script>

<style lang="scss" scoped>
.page-not-found {
  display: flex;
  padding: 30px 0;
  align-items: center;
  flex-direction: column;

  // mobile
  @media (max-width: 800px) {
    padding: 0 15px;
    text-align: center;
  }

  img {
    padding: 15px;
  }
  .pnf-404 {
    font-size: 80px;
  }

  .pnf-btn {
    margin: 10px 0;
  }
}
</style>
