<template>
  <div id="blackModal">
    <div id="blackmodal-overlay" :class="{ 'active-overlay': true }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlackModal",
  components: {},
  props: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

#blackmodal-overlay {
  display: none;
  // opacity: 0.6;

  &.active-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    display: flex;
    place-items: center;
    justify-content: center;
  }
}
</style>
