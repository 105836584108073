<template>
  <div v-if="myProdutos[0].length > 0" id="produto-slider">
    <div>
      <h1 v-if="titulo" class="title-session">
        <span>{{ titulo }}</span>
      </h1>

      <div
        v-for="(produto, index) in myProdutos"
        :key="index"
        class="slider-produtos-home"
      >
        <VueSlickCarousel v-bind="mergedSliderSettings">
          <ItemProduto
            v-for="(item, i) in produto"
            :key="index + i"
            :item="item"
            :use-default-view-type="useDefaultViewType"
            :visualizacao="visualizacao"
          ></ItemProduto>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import ItemProduto from "@/components/produtos/ItemProduto";

export default {
  name: "ProdutosSlider",
  components: { ItemProduto, VueSlickCarousel },
  props: {
    titulo: {
      type: String,
      default: null,
    },
    produtos: {
      type: Array,
      default: Array,
    },
    useDefaultViewType: {
      default: true,
      type: Boolean,
    },
    visualizacao: {
      default: "bloco-1",
      required: false,
      type: String,
    },
    slideSettings: {
      required: false,
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      defaultSliderSettings: {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 5,
        autoplay: false,
        autoplaySpeed: 5000,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            },
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 720,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          // {
          //   breakpoint: 548,
          //   settings: {
          //     slidesToShow: 2,
          //     slidesToScroll: 2,
          //   },
          // },
        ],
      },
    };
  },
  computed: {
    myProdutos() {
      return this.produtos;
    },
    mergedSliderSettings() {
      // Merge the default settings with custom settings
      return {
        ...this.defaultSliderSettings,
        ...this.slideSettings,
        responsive:
          this.slideSettings && this.slideSettings.responsive
            ? this.defaultSliderSettings.responsive.map(
                (defaultResponsive, index) => ({
                  ...defaultResponsive,
                  ...(this.slideSettings.responsive[index] || {}),
                })
              )
            : this.defaultSliderSettings.responsive,
      };
    },
  },
  beforeCreate() {
    this.$emit("created");
  },
  mounted() {
    // this.mountSlider();
  },
  activated() {
    // this.mountSlider();
  },
  methods: {
    // mountSlider() {
    //   const $slider = $(".slider-produtos-home");
    //   if (
    //     $slider.hasClass("slick-initialized") &&
    //     this.$route.name == "homeMain"
    //   ) {
    //     $slider.slick("unslick");
    //   }
    //   $slider.not(".slick-initialized").slick({
    //     dots: true,
    //     infinite: true,
    //     speed: 300,
    //     slidesToShow: 5,
    //     slidesToScroll: 5,
    //     autoplay: false,
    //     autoplaySpeed: 5000,
    //     responsive: [
    //       {
    //         breakpoint: 1200,
    //         settings: {
    //           slidesToShow: 4,
    //           slidesToScroll: 4,
    //         },
    //       },
    //       {
    //         breakpoint: 960,
    //         settings: {
    //           slidesToShow: 3,
    //           slidesToScroll: 3,
    //         },
    //       },
    //       {
    //         breakpoint: 720,
    //         settings: {
    //           slidesToShow: 2,
    //           slidesToScroll: 2,
    //         },
    //       },
    //       // {
    //       //   breakpoint: 548,
    //       //   settings: {
    //       //     slidesToShow: 2,
    //       //     slidesToScroll: 2,
    //       //   },
    //       // },
    //     ],
    //   });
    // },
  },
};
</script>

<style lang="scss">
@import "~@/assets/css/sass/bootstrap/variables";

#produto-slider {
  margin-top: 20px;
  width: calc(100% - 20px);
  margin: auto;
  margin-bottom: 40px !important;
  max-width: 1360px;

  .slick-list {
    display: flex !important;
    .slick-track {
      display: flex !important;
    }
  }

  .slick-slide {
    > div {
      height: 100%;
    }

    .item-produto {
      height: 100%;
    }
  }

  .title-session {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    span {
      text-align: center;
      font-family: "Montserrat";
      font-weight: 700;
      font-size: 2.25rem;
      color: #303932;
      border-bottom: solid 3px var(--brand-primary, $brand-primary);
      line-height: 3rem;
    }
  }
  .item-produto.bloco-1 {
    flex: 1 1 auto !important;
    max-width: initial !important;
  }
}

@media (min-width: 768px) {
}

@media (max-width: 768px) {
  #produto-slider {
    .title-session {
      span {
        font-size: 2rem;
      }
    }
  }
}
</style>

<style>
#produto-slider .slick-next {
  right: -9px;
}

#produto-slider .slick-prev {
  left: -9px;
}
</style>
