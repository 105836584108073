var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"home"}},[(_vm.show_banner_parcelamento)?_c('mini-banner',{attrs:{"description":'Parcele seus pedidos em até 3 vezes no cartão de crédito',"type-alert":'home'}}):_vm._e(),_vm._l((_vm.myComponents),function(componente,index){return _c('section',{key:index},[(componente.imagens_validas.length && componente.tipo_id === 1)?_c('SliderFull',{attrs:{"sliders":[componente.imagens_validas],"is-loading":_vm.isLoading}}):_vm._e(),(
        !componente.imagens_validas.length &&
        componente.tipo_id === 1 &&
        _vm.isLoading
      )?_c('loadingSliderFull'):_vm._e(),_c('div',{staticClass:"section-all-components"},[(componente.imagens_validas.length && componente.tipo_id === 2)?_c('ParceirosSlider',{attrs:{"sliders":componente.imagens_validas,"is-loading":_vm.isLoading}}):_vm._e(),(
          !componente.imagens_validas.length &&
          componente.tipo_id === 2 &&
          _vm.isLoading
        )?_c('loadingSliderParceiros'):_vm._e(),(
          componente.imagens_validas.length &&
          componente.tipo_id === 3 &&
          _vm.verifyIfShowComponent(componente.tipo_visualizacao)
        )?_c('BannerTree',{attrs:{"data":componente.imagens_validas,"show-title":false,"cols":"3","rows":"1","gap":"gap-9","margin":"mt-4 mb-4","login-required":componente.tipo_visualizacao == 0 ||
          componente.tipo_visualizacao == 1,"is-loading":_vm.isLoading,"rules":[
          { col: 'col-span-1', row: 'row-span-1' },
          { col: 'col-span-1', row: 'row-span-1' },
          { col: 'col-span-1', row: 'row-span-1' },
        ]}}):_vm._e(),(
          !componente.imagens_validas.length &&
          componente.tipo_id === 3 &&
          _vm.isLoading
        )?_c('loadingBanner'):_vm._e(),(
          componente.imagens_validas.length > 0 &&
          componente.tipo_id === 4 &&
          _vm.verifyIfShowComponent(componente.tipo_visualizacao)
        )?_c('BannerTree',{attrs:{"data":componente.imagens_validas,"show-title":false,"cols":"1","margin":"mt-8 mb-8","rows":"1","login-required":componente.tipo_visualizacao == 0 ||
          componente.tipo_visualizacao == 1,"rules":[{ col: 'col-span-1', row: 'row-span-1' }]}}):_vm._e(),(
          !componente.imagens_validas.length &&
          componente.tipo_id === 4 &&
          _vm.isLoading
        )?_c('loadingBanner'):_vm._e(),(
          componente.imagens_validas.length &&
          componente.tipo_id === 5 &&
          _vm.verifyIfShowComponent(componente.tipo_visualizacao)
        )?_c('CategoriasSlider',{attrs:{"data":componente.imagens_validas,"titulo":componente.descricao}}):_vm._e(),(
          !componente.imagens_validas.length &&
          componente.tipo_id === 5 &&
          _vm.verifyIfShowComponent(componente.tipo_visualizacao) &&
          _vm.isLoading
        )?_c('LoadingCategoriasSlider'):_vm._e(),(
          componente.tipo_id === 6 &&
          componente.produtos &&
          componente.produtos.length &&
          _vm.verifyIfShowComponent(componente.tipo_visualizacao)
        )?_c('ProdutosSlider',{attrs:{"titulo":componente.descricao,"produtos":[componente.produtos]}}):_vm._e(),(
          _vm.verifyIfShowComponent(componente.tipo_visualizacao) &&
          componente.tipo_id === 6 &&
          componente.produtos &&
          !componente.produtos.length &&
          _vm.isLoadingProducts
        )?_c('loadingItemProduto'):_vm._e(),(
          componente.imagens_validas.length &&
          componente.tipo_id === 7 &&
          _vm.verifyIfShowComponent(componente.tipo_visualizacao)
        )?_c('BannerTree',{attrs:{"data":componente.imagens_validas,"show-title":false,"cols":"2","rows":"1","login-required":false,"gap":"gap-9","margin":"mt-8 mb-8","rules":[
          { col: 'col-span-1', row: 'row-span-1' },
          { col: 'col-span-1', row: 'row-span-1' },
        ]}}):_vm._e(),(
          !componente.imagens_validas.length &&
          componente.tipo_id === 7 &&
          _vm.verifyIfShowComponent(componente.tipo_visualizacao) &&
          _vm.isLoading
        )?_c('loadingBanner'):_vm._e()],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }