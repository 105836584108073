<template>
  <div class="back_button">
    <router-link :to="route" class="back_button__a">
      <i class="fa fa-arrow-left"></i>
      <span> {{ text }}</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "BackButton",
  props: {
    route: {
      required: true,
    },
    text: {
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

.back_button {
  padding: 20px 5px 0;
  max-width: 1150px;
  margin: 0 auto;
  width: 100%;

  .back_button__a {
    color: var(--brand-primary, $brand-primary);
    font-weight: bold;

    &:hover {
      color: var(--brand-accent, $brand-accent);
    }
  }
}
</style>
