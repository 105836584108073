<template>
  <!-- MODAL FILTROS -->
  <modal
    :show-modal="true"
    class="modal-sellers"
    titulo="Selecione o vendedor"
    :show-footer="true"
    @close="close()"
  >
    <!-- MODAL FILTRO CONTENT -->
    <div slot="body">
      <div class="modal-container">
        <search-box
          class="modal-sellers-list__search"
          placeholder="Digite para pesquisar"
          :search-term="searchTerm"
          @search="searchTerm = $event"
        >
        </search-box>

        <div class="modal-sellers-list">
          <div
            v-for="seller in sellers"
            :key="seller.usuario_id"
            class="modal-sellers-list-item"
            :class="{
              'modal-sellers-list-item__active':
                seletedSeller == seller.usuario_id,
            }"
            @click="
              () => {
                seller.loading = true;
                handleSellerSelected(seller.usuario_id);
              }
            "
          >
            <span>{{ seller.nome }}</span>
            <div
              v-if="seletedSeller == seller.usuario_id && showAllowFinishOrder"
              class="finish-order__toggle"
            >
              <span>Permite Finalizar</span>
              <toggle-button
                v-model="permiteFinalizar"
                sync
                :width="35"
                :height="18"
                :color="primaryColor"
                :margin="0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <button
        class="btn btn-primary"
        :disabled="!seletedSeller"
        @click="sendOrderToSeller()"
      >
        <i v-if="loading" class="fa fa-spinner fa-spin"></i>
        Enviar Pedido
      </button>
    </div>
  </modal>
</template>

<script>
import modal from "@/components/root/modal/Modal";
import blackmodal from "@/components/root/black-modal/blackModal";
import SearchBox from "@/components/searchbox/SearchBox.vue";

export default {
  name: "ModalSellers",
  components: { modal, blackmodal, SearchBox },
  props: {
    sellers: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showAllowFinishOrder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchTerm: null,
      seletedSeller: null,
      permiteFinalizar: false,
      primaryColor: this.$store.getters.siteConfig.app_color_brand_primary,
    };
  },
  computed: {
    loading() {
      return this.isLoading;
    },
  },
  watch: {
    searchTerm() {
      this.$emit("search-seller", this.searchTerm);
    },
  },
  methods: {
    handleSellerSelected(sellerId) {
      this.seletedSeller = sellerId;
    },
    close() {
      this.$emit("close");
    },
    sendOrderToSeller() {
      this.$emit("send-order-to-seller", {
        usuario_id: this.seletedSeller,
        permite_finalizar: this.permiteFinalizar,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

.modal-sellers {
  color: #626262;

  &-list__search {
    margin-bottom: 10px;
  }

  .finish-order__toggle {
    display: flex;
    align-items: center;
    justify-content: space-center;
    span {
      margin-right: 10px;
    }
    label {
      margin: 0;
    }
  }

  &-list-item {
    padding: 12px;
    border: 1px solid gainsboro;
    border-radius: 6px;
    margin-bottom: 5px;
    cursor: pointer;
    transition: box-shadow 0.3s, border-color 0.3s, color 0.3s;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover,
    &:focus,
    &__active {
      color: var(--brand-primary, $brand-primary);
      border-color: var(--brand-primary, $brand-primary);
    }
  }
}
</style>

<style lang="scss">
.black-modal {
  z-index: 1100 !important;
  display: flex;
  align-items: center;
  justify-content: center;

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
  }
}

.modal-sellers {
  .modal-container.container {
    width: 100vw;
    max-width: 900px;
  }
  // position: absolute;
  // z-index: 1050;

  .error-message {
    text-align: center;
  }
}
</style>
