<template>
  <transition-group
    tag="ul"
    name="slide"
    class="sub-menu"
    :class="{ 'deep-category': activeMenu.nivel >= 1 }"
  >
    <button
      v-if="activeMenu.father"
      id="voltar-menu"
      :key="activeMenu.id"
      @click="navigateTo(activeMenu.father.id)"
    >
      <i class="fa fa-arrow-left" aria-hidden="true"></i>
      {{ capitalize(activeMenu.father.descricao) }}
    </button>
    <li
      v-if="activeMenu.descricao"
      :key="activeMenu.descricao"
      @click="$emit('closeside')"
    >
      <router-link
        tag="a"
        style="font-weight: bold"
        :to="{
          name: 'catalogoRota',
          query: { categoria: activeMenu.integracao_id },
        }"
      >
        Tudo em {{ capitalize(activeMenu.descricao) }}
      </router-link>
    </li>
    <li
      v-for="(item, index) in activeMenu.filhos"
      :key="item.id"
      class="text-capitalize"
    >
      <a
        v-if="item.filhos"
        href="#"
        onclick="return false;"
        @click="navigateTo(item.id)"
      >
        {{ capitalize(item.descricao) }}
        <i aria-hidden="true" class="fa fa-angle-right ic-dep arrow"></i>
      </a>

      <span v-else @click="$emit('closeside')">
        <router-link
          tag="a"
          :to="{
            name: 'catalogoRota',
            query: { categoria: item.integracao_id },
          }"
        >
          {{ capitalize(item.descricao) }}
        </router-link></span
      >

      <!-- <div
        class="sub-menu painel"
        v-if="subActive == item.id"
        :class="{ 'sub-active': true }"
      >
        <div
          class="menu-prev-mobile"
          @click="closeAllCategories(item.categoriapai_id || item.id)"
        >
          <button id="voltar-menu">
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            {{ itemFather.descricao || "Todas as categorias" }}
          </button>
        </div>

        <submenu-tree-responsive
          :itemList="item.filhos"
          :itemFather="item"
          v-if="item.filhos"
        ></submenu-tree-responsive>
      </div> -->
    </li>
  </transition-group>
</template>

<script>
import _capitalize from "lodash/capitalize";

export default {
  name: "SubmenuTreeResponsive",
  props: {
    itemList: {
      required: true,
      type: Array / undefined,
    },
    itemFather: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      subActive: null,
      activeMenu: {
        filhos: {},
      },
    };
  },
  mounted() {
    this.setDefaultMenu();
    // console.log(this.itemList);
  },
  methods: {
    capitalize(text) {
      if (!text) return;

      return _capitalize(text);
    },
    navigateTo(menuId, menuList = this.itemList) {
      if (!menuList) return;
      if (!menuId) {
        this.setDefaultMenu();
        return;
      }
      let activeMenu = null;
      const findMenu = (menuId, menuList) => {
        menuList.forEach((item) => {
          if (activeMenu) return;
          if (item.id === menuId) {
            activeMenu = item;
          } else if (item.filhos) {
            findMenu(menuId, item.filhos);
          }
        });
      };

      findMenu(menuId, menuList);
      if (!activeMenu) return;
      // console.log(activeMenu);
      // const menu = menuList.find((x) => x.id === menuId);
      // if (!menu) {
      //   console.log("Menu not found. What should we do?");
      //   navigateTo(menuId)
      //   return;
      // }
      // console.log(menu);
      if (this.activeMenu.nivel < activeMenu.nivel) {
        activeMenu.father = {
          descricao: this.activeMenu.descricao,
          id: this.activeMenu.id,
        };
      } else if (!activeMenu.categoriapai_id) {
        activeMenu.father = {
          descricao: "Todas as categorias",
        };
      }

      this.activeMenu = activeMenu;
    },
    isActiveSubMenu(value) {
      this.subActive = value;
    },
    closeAllCategories() {
      this.subActive = null;
      this.$emit("closeside");
    },
    setDefaultMenu() {
      this.activeMenu = { filhos: this.itemList };
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-enter-active {
  transition: 0.1s;
}
.slide-enter {
  transform: translate(-100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}

.sub-menu {
  //position: absolute;
  min-width: 180px;
  background: #fff;
  color: #444;

  &.deep-category {
    position: absolute;
    width: 100%;
    top: 0;
  }

  #voltar-menu {
    background: #eee;
    font-size: 17px;
    color: #777;
    border: none;
    // margin-top: 2px;
    padding: 18px;
    width: 100%;
    text-align: left;
  }

  a {
    display: block;

    color: #444;
    padding: 15px;
    border-bottom: 1px solid #eee;

    i {
      color: #444;
      float: right;
      margin-top: 2px;
    }
  }

  .painel {
    height: 100%;
    position: absolute;
    max-height: 100%;
  }

  .sub-menu {
    width: 100%; //height: 100%;
    top: 0;
    left: -100%; //padding: 5px 12px;
    z-index: -2;
    -webkit-transform: translateX(-17em);
    -ms-transform: translateX(-17em);
    transform: translateX(-17em);
    display: block;
    opacity: 0;
    height: 100%;

    &.sub-active {
      z-index: 9;
      left: 0%;
      opacity: 1;
      width: 100%;
      -webkit-transform: translateX(0em);
      -ms-transform: translateX(0em);
      transform: translateX(0em);
      position: fixed;
      width: 300px;
      overflow-y: auto;
      overflow-x: hidden;

      > .sub-menu {
        -webkit-transform: translateX(0em);
        -ms-transform: translateX(0em);
        transform: translateX(0em);
        left: 0%;
        opacity: 1;
      }
    }
  }

  li {
    &:hover {
      background: #f3f3f3;
    }

    cursor: pointer;
  }

  // li .sub-menu.painel.sub-active {
  //   z-index: 9;
  //   left: 0%;
  //   opacity: 1;
  //   width: 100%;
  // }
}
</style>
