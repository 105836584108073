import cookie from "vue-cookie";

export const setCookie = (busca) => {
  if (busca !== null && busca !== undefined) {
    // SALVO O TERMO BUSCADO NO COOKIE DE ULTMAS BUSCAS
    cookie.set("my_last_search", JSON.stringify(busca), 365);
  }
};

export const getCookie = () => {
  const arr = cookie.get("my_last_search");
  // RETORNO AS BUSCAR ARMAZENADAS NO COOKIE
  return JSON.parse(arr);
};

export const deleteCookie = () => {
  // PARA DELETAR O COOKIE
  cookie.delete("my_last_search");
};

export const removeTermosPesquisados = () => {
  // PEGO TODOS OS COOKIE
  const termos_buscados = getCookie();
  // CONVERTE PARA ARRAY SE EXISTIR TERMOS BUSCADOS
  const arr = termos_buscados ? Array.from(termos_buscados) : [];

  // SE EXISTIR 6 REGISTROS JÁ ARMAZENADOS
  if (arr.length >= 6) {
    // ORDENO OS COOKIEs DE ACORDO COM A MENOR DATA DO TIMESTAMP
    arr.sort((a, b) => (b.timestamp > a.timestamp ? 1 : -1));
    // REMOVO TODAS AS POSICOES A POS A POSICAO 7
    arr.splice(5, arr.length);
    // SALVA O COOKIE NOVAMENTE APENAS COM OS ULTIMOS PESQUISADOS
    setCookie(arr);
  }
};

export const saveLastSearch = (busca) => {
  removeTermosPesquisados();

  // PEGO TODOS OS COOKIE
  const termos_buscados = getCookie();
  // CONVERTE PARA ARRAY SE EXISTIR TERMOS BUSCADOS
  const arr = termos_buscados ? Array.from(termos_buscados) : [];
  // PARA CONTROLAR SE SERA SALVO NO COOKIE O NOVO TERMO PESQUISADO, OU SE SERA APENAS ATUALIZADO O TIMESTAMP
  let termo_existe = false;

  if (arr.length > 0) {
    arr.forEach((value, index) => {
      // CASO O TERMO PESQUISADO JÁ EXISTA
      if (value.termo == busca.termo) {
        // ATUALIZO O TIMESTAMP
        value.timestamp = busca.timestamp;
        termo_existe = true;
      }
    });
  }

  if (termo_existe == false) {
    // ACRESCENTO O TERMO PESQUISADO
    arr.push(busca);
  }

  // SALVO O COOKIE
  setCookie(arr);
};
