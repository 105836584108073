<template>
  <div class="timelineItem">
    <span>{{ grupoAtivacao.regra }} </span>
    <div class="remainingProducts">
      <div class="contentItems">
        <div
          v-for="idx in grupoAtivacaoData.qtdMinProd"
          :key="idx"
          class="item"
          :class="
            idx <= grupoAtivacaoData.qtdMinProdAtivados ? 'item active' : ''
          "
        ></div>
      </div>
      <div class="infoCard">
        <div>
          <span
            v-if="
              grupoAtivacaoData.qtdMinProd -
                grupoAtivacaoData.qtdMinProdAtivados >
                0 && grupoAtivacaoData.qtdMinProd != null
            "
            >Adicione mais
            {{
              grupoAtivacaoData.qtdMinProd -
              grupoAtivacaoData.qtdMinProdAtivados
            }}
            produto(s) para ativar este combo.</span
          >
          <!-- <span v-else class="active">
            Você completou todos os produtos minimos!</span
          > -->
        </div>
        <div>
          <span
            v-if="
              grupoAtivacaoData.vlrMinProd -
                grupoAtivacaoData.vlrMinProdAtivados >
                0 && grupoAtivacaoData.vlrMinProd != null
            "
            >Adicione mais R$
            {{
              mascaraValor(
                grupoAtivacaoData.vlrMinProd -
                  grupoAtivacaoData.vlrMinProdAtivados
              )
            }}
            em produtos para ativar este combo.</span
          >
          <!-- <span v-else class="active"> Você atingiu o valor minimo!</span> -->
        </div>
        <div>
          <span
            v-if="
              grupoAtivacaoData.vlrMaxProd != null &&
              grupoAtivacaoData.vlrMaxProd < grupoAtivacaoData.vlrAtualProd
            "
            class="danger"
            >Valor máximo atingido!
            <!-- <TooltipBalloon
              :text="'Caso um produto participante dessa campanha, seja ativado em um duas ou mais campanhas, os descontos irão acumular (somar), respeitando a regra de cada campanha'"
            > -->
            <alert-circle-icon size="1.5x" class="custom-class" />
            <!-- </TooltipBalloon> -->
          </span>
          <span
            v-if="
              grupoAtivacaoData.qtdMaxProd < grupoAtivacaoData.qtdAtualProd &&
              grupoAtivacaoData.qtdMaxProd != null
            "
            class="danger"
            >Quantidade máxima atingida!
            <!-- <TooltipBalloon
              :text="'Caso um produto participante dessa campanha, seja ativado em um duas ou mais campanhas, os descontos irão acumular (somar), respeitando a regra de cada campanha'"
            > -->
            <alert-circle-icon size="1.5x" class="custom-class" />
            <!-- </TooltipBalloon> -->
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertCircleIcon from "vue-feather-icons/icons/AlertCircleIcon";
import { mascaraValor } from "@/app/global.js";
import TooltipBalloon from "@/components/tooltip-balloon/TooltipBalloon.vue";

export default {
  name: "TimelineItem",
  components: {
    AlertCircleIcon,
    TooltipBalloon,
  },
  props: {
    grupoAtivacao: {},
    campanhaId: "",
    index: 0,
  },

  data() {
    return {
      grupoAtivacaoData: {},
    };
  },
  computed: {
    campanhaStatus() {
      return this.$store.getters.campanhaStatus;
    },
  },

  watch: {
    campanhaStatus(val) {
      this.getDataTimeline();
    },
  },

  methods: {
    mascaraValor,
    getDataTimeline() {
      const campanhaId = this.campanhaId.toString();
      const grupoId = this.grupoAtivacao.id.toString();
      const grupoAtivacaoStatus =
        this.campanhaStatus[campanhaId].gruposAtivacaoStatus[grupoId];

      this.grupoAtivacaoData = grupoAtivacaoStatus;

      this.grupoAtivacaoData.qtdMinProd = Math.round(
        this.grupoAtivacaoData.qtdMinProd
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

$title: #303932;
$subTitle: #979797;
$primary: var(--brand-primary, $brand-primary);
$secondary: #e5e5e5;
$danger: $alert-danger-text;

.timelineItem {
  // margin: 25px 0;
  h4 {
    margin: 0;
    font-weight: bold;
    color: $title;
    text-transform: capitalize;
  }
  span {
    color: $subTitle;
  }
  .remainingProducts {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    gap: 20px;
    .contentItems {
      display: flex;
      justify-content: space-around;
      align-items: center;
      gap: 5px;
      .item {
        width: 100%;
        height: 13px;
        background: $secondary;
        border-radius: 3px;

        &.active {
          transition: 0.25s ease background;
          background: $primary;
        }
      }
    }
    .infoCard {
      // align-self: center;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 15px;
      span {
        color: $title;
        background: $secondary;
        padding: 5px;
        border-radius: 5px;
        font-weight: 500;
      }
      .active {
        color: white;
        background: $primary;
      }
      .danger {
        background: $danger;
        color: white;
        display: flex;
        align-items: center;
        gap: 3px;
      }
    }
  }
}
</style>
