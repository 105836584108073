<template>
  <div class="review-payment-resume">
    <payment-resume-info
      v-if="
        paymentData &&
        paymentData.card &&
        paymentMethod === PAYMENT_METHODS.CREDIT_CARD
      "
      icon="mdi mdi-credit-card-multiple-outline fa-2x"
      title="Cartão de crédito"
      :content="`${
        toTitleCase(paymentData.card.brand) ||
        toTitleCase(paymentData.card.cardBrand) ||
        'Bandeira'
      } **** ${paymentData.card.desc || '0000'}`"
    >
      <div slot="action">
        <router-link
          class="rpr-link"
          :to="{ name: 'checkoutPayment', query: { ...$route.query } }"
          >Alterar</router-link
        >
      </div>
    </payment-resume-info>
    <payment-resume-info
      v-else-if="paymentMethod === PAYMENT_METHODS.AVISTA"
      icon="mdi mdi-barcode fa-2x"
      title="A vista"
      :content="`Boleto`"
    >
    </payment-resume-info>
    <payment-resume-info
      v-else-if="paymentMethod === PAYMENT_METHODS.BOLETO"
      icon="mdi mdi-barcode fa-2x"
      title="Boleto"
      :content="dadosPedido.informacoes.condicaoPagamento"
    >
    </payment-resume-info>
    <payment-resume-info
      v-else-if="paymentMethod === PAYMENT_METHODS.DINHEIRO"
      icon="mdi mdi-cash fa-2x"
      title="Dinheiro"
      :content="dadosPedido.informacoes.condicaoPagamento"
    >
    </payment-resume-info>
    <payment-resume-info
      v-else-if="paymentMethod === PAYMENT_METHODS.DEPOSITO_ANTECIPADO"
      icon="mdi mdi-cash fa-2x"
      title="Depósito Antecipado"
      :content="dadosPedido.informacoes.condicaoPagamento"
    >
    </payment-resume-info>
  </div>
</template>

<script>
import CartMixin from "@/app/checkout/cart/CartMixin";
import PaymentResumeInfo from "./PaymentResumeInfo";

export default {
  name: "ReviewPaymentResume",
  components: {
    PaymentResumeInfo,
  },
  mixins: [CartMixin],
  computed: {},
};
</script>

<style lang="scss" scoped>
.review-payment-resume {
  .rpr-link {
    color: #276f46;
  }
}
</style>
