export const setItensFiltro = (context, payload) => {
  context.commit("setItensFiltro", payload);
};
export const addItemFiltro = (context, payload) => {
  context.commit("addItemFiltro", payload);
};
export const addItemFiltroByCodigoTipo = (context, payload) => {
  context.commit("addItemFiltroByCodigoTipo", payload);
};
export const removeItemFiltro = (context, payload) => {
  context.commit("removeItemFiltro", payload);
};
export const addCategoria = (context, payload) => {
  context.commit("setCategoriaSelecionada", payload);
};
export const setCategoriaSelecionadaByCodigo = (context, payload) => {
  context.commit("setCategoriaSelecionadaByCodigo", payload);
};
export const removeCategoria = (context) => {
  context.commit("removeCategoriaSelecionada");
};
export const removeItensFiltro = (context) => {
  context.commit("removeFiltro");
};
export const addItemCategoria = (context, payload) => {
  context.commit("setCategorias", payload);
};
export const setCategoriasComponente = (context, payload) => {
  context.commit("setCategoriasComponente", payload);
};
export const setParceiros = (context, payload) => {
  context.commit("setParceiros", payload);
};
export const setParceirosFiltrados = (context, payload) => {
  context.commit("setParceirosFiltrados", payload);
};

export const setMarcasFiltradas = (context, payload) => {
  context.commit("setMarcasFiltradas", payload);
};

export const setModoVisualizacao = (context, payload) => {
  context.commit("setModoVisualizacao", payload);
};
export const setCartaoSucesso = (context, payload) => {
  context.commit("setCartaoSucesso", payload);
};
export const setCartaoErro = (context, payload) => {
  context.commit("setCartaoErro", payload);
};
