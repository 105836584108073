<template>
  <div class="container containerCampanhaMain">
    <div class="header">
      <BannerPrincipalCampanha :params-campanha="paramsCampanha" />

      <ul class="promo-tab">
        <li
          class="promo-tab__item"
          :class="{ 'promo-tab__item__active': activeTab == 1 }"
          @click="handleSwitchbonusType(1)"
        >
          <i class="mdi mdi-percent"></i>
          <span>Descontos</span>
        </li>
        <li
          class="promo-tab__item"
          :class="{ 'promo-tab__item__active': activeTab == 2 }"
          @click="handleSwitchbonusType(2)"
        >
          <i class="mdi mdi-ticket"></i>
          <span>Bonificações</span>
        </li>
      </ul>

      <div
        v-if="isAuthenticated && !showNotFound && !showAlerta"
        class="navFinder"
      >
        <div>
          <search-box
            ref="searchBox"
            class="__search"
            placeholder="Busque por uma promoção..."
            size="sm"
            :search-term="paramsCampanha.searchTerm"
            @search="searchCampaigns($event)"
          >
          </search-box>
        </div>
      </div>
    </div>
    <AlertCampanhaPromotion
      v-if="!isAuthenticated || showAlerta"
      :sem-pedido-aberto="isAuthenticated && !dadosPedido.razao ? true : false"
    />
    <AlertNotFoundCampanha
      v-else-if="(!showAlerta && !campanhas) || showNotFound"
    />
    <div v-else-if="!showAlerta && searchNotFound">
      <h3 class="titleNotFound">Nenhuma promoção encontrada</h3>
    </div>
    <div v-else class="containerItems">
      <!-- <div v-if="loadingCampanha" class="loadingContent">
        <img src="@/assets/img/loading/loading.gif" alt="" />
      </div> -->
      <!-- <div> -->
      <div v-for="(campanha, i) in campanhas" :key="i">
        <CampanhaAccordion
          :active="accordionActive"
          :campanha="campanha"
          :handle-accordion-active="handleAccordionActive"
        />
      </div>
      <!-- </div> -->
    </div>
    <div>
      <center v-if="hasMoreCampaignsToShow && campanhas.length > 0">
        <button class="btn btn-primary" @click="loadMoreCampaigns()">
          <span>Ver mais</span>
        </button>
      </center>
    </div>
  </div>
</template>

<script>
import CampanhaAccordion from "@/components/campanhas/CampanhaAccordion";
import AlertNotFoundCampanha from "@/components/campanhas/AlertNotFoundCampanha";
import BannerPrincipalCampanha from "@/components/campanhas/BannerPrincipalCampanha";
import AlertCampanhaPromotion from "@/components/campanhas/AlertCampanhaPromotion";
import SearchBox from "@/components/searchbox/SearchBox.vue";

import bus from "@/utils/events/bus";

export default {
  name: "CampanhaMain",
  components: {
    CampanhaAccordion,
    AlertCampanhaPromotion,
    AlertNotFoundCampanha,
    BannerPrincipalCampanha,
    SearchBox,
  },
  data() {
    return {
      campanhas: [],
      loadingCampanha: false,
      showAlerta: false,
      showNotFound: false,
      searchNotFound: false,
      accordionActive: 0,
      paramsCampanha: {
        offset: 0,
        searchTerm: "",
        limit: 7,
        clear: true,
        bonusType: [0, 1, 2],
      },
      activeTab: 1,
      // hasMoreCampaignsToShow: false,
    };
  },
  computed: {
    hasMoreCampaignsToShow() {
      return this.$store.getters.getHasMoreCampaignsToShow;
    },
    dadosPedido() {
      return this.$store.getters.dadosPedido;
    },

    clienteId() {
      return this.dadosPedido.razao;
    },
    isAuthenticated() {
      return this.$store.getters.getIsAuthenticated;
    },
    campanhasDisponiveis() {
      let campanhasDisponiveis = this.$store.getters.campanhasDisponiveis;
      if (!campanhasDisponiveis || campanhasDisponiveis == "show_not_found") {
        this.showNotFound = true;
        this.loadingCampanha = false;
        return [];
      }
      if (campanhasDisponiveis == "search_not_found") {
        this.searchNotFound = true;
        this.loadingCampanha = false;
        return [];
      }
      this.searchNotFound = false;
      this.showNotFound = false;
      return campanhasDisponiveis;
    },
  },

  watch: {
    loadingCampanha(newValue) {
      this.$store.dispatch("setShowLoadingModal", [newValue, "Main"]);
    },
    // eslint-disable-next-line func-names
    "$store.getters.dadosPedido": function (newValue, oldValue) {
      if (newValue.pedidoId != oldValue.pedidoId) this.showAlert();
    },
    async campanhasDisponiveis(newValue, oldValue) {
      if (newValue != oldValue) {
        this.campanhas = await this.mountCampanhasData(newValue);
        if (newValue.length > 0) {
          this.loadingCampanha = false;
        }
      }
    },
  },
  created() {
    this.loadingCampanha = true;

    if (this.$route.query?.buscaCampanha) {
      this.paramsCampanha.searchTerm = this.$route.query.buscaCampanha;
    }
  },

  activated() {
    if (
      this.$route.query?.buscaCampanha &&
      this.$route.query?.buscaCampanha != this.paramsCampanha.searchTerm
    ) {
      this.searchCampaigns(this.$route.query.buscaCampanha);
    }
  },

  methods: {
    handleSwitchbonusType(type) {
      if (type == this.activeTab) return;

      this.activeTab = type;

      switch (type) {
        case 1:
          this.paramsCampanha.bonusType = [0, 1, 2];
          break;
        case 2:
          this.paramsCampanha.bonusType = [3];
          break;
      }

      this.loadingCampanha = true;

      this.paramsCampanha.searchTerm = null;
      this.paramsCampanha.offset = 0;
      this.paramsCampanha.clear = true;

      if (this.$refs.searchBox) {
        this.$refs.searchBox.searchedItemLabel = "";
        this.$refs.searchBox.busca = "";
      }

      this.$store.dispatch("getCampanhasDisponiveis", this.paramsCampanha);
    },
    handleAccordionActive(campanhaId) {
      this.accordionActive = campanhaId;
    },
    searchCampaigns(searchTerm) {
      this.loadingCampanha = true;

      this.paramsCampanha.searchTerm = searchTerm;
      this.paramsCampanha.offset = 0;
      this.paramsCampanha.clear = true;
      this.$store.dispatch("getCampanhasDisponiveis", this.paramsCampanha);
    },

    async mountCampanhasData(campanhas) {
      campanhas = JSON.parse(JSON.stringify(campanhas));

      let newcampanhas = campanhas.map(async (campanha) => {
        if (!campanha.grupos_ativacao.length) {
          return {};
        }
        campanha.active = false;
        campanha.descricao = campanha.descricao.toLowerCase();
        return campanha;
      });
      newcampanhas = await Promise.all(newcampanhas);
      return newcampanhas;
    },

    loadMoreCampaigns() {
      this.paramsCampanha.offset += this.paramsCampanha.limit;
      this.paramsCampanha.clear = false;
      this.loadingCampanha = true;
      this.$store.dispatch("getCampanhasDisponiveis", this.paramsCampanha);
    },

    async showAlert() {
      if (!this.clienteId || !this.isAuthenticated || !this.dadosPedido.razao) {
        this.campanhas = [];
        this.showAlerta = true;
      } else {
        // if (!this.campanhas.length) {
        this.showAlerta = false;
        this.loadingCampanha = true;
        // await this.findCampanhas();
        this.paramsCampanha.clear = true;
        this.paramsCampanha.offset = 0;
        this.$store.dispatch("getCampanhasDisponiveis", this.paramsCampanha);
        // }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/_variables.scss";

.containerCampanhaMain {
  .promo-tab {
    display: flex;
    gap: 5px;
    margin: 0;
    padding: 0;

    @media (max-width: 548px) {
      width: 100%;
    }

    &__item {
      background: white;
      padding: 20px 25px;
      color: #303932;
      border-radius: 5px;
      font-size: 18px;
      font-weight: 600;
      display: flex;
      gap: 5px;
      align-items: center;
      border: 1px solid #e5e5e5;
      cursor: pointer;
      transition: 150ms;
      user-select: none;

      @media (max-width: 548px) {
        width: 100%;
      }

      &:hover,
      &__active {
        background-color: var(--brand-primary, $brand-primary);
        color: var(--brand-primary-contrast, "#fff");
      }

      &i {
        font-size: 22px;
      }
    }
  }

  padding: 15px;
  .header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .navFinder {
      margin: 35px 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .__search {
        width: 500px;
      }
      select {
        background: transparent;
        border: none;
        color: #1ba256;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
  .titleNotFound {
    margin-top: -15px;
  }

  .containerItems {
    .loadingContent {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (max-width: 968px) {
  .containerCampanhaMain {
    .header {
      .navFinder {
        .__search {
          width: 300px;
        }
      }
    }
  }
}
@media (max-width: 728px) {
  .containerCampanhaMain {
    .header {
      .navFinder {
        gap: 20px;
      }
    }
  }
}
</style>
