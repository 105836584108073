import Home from "./components/Main.vue";

export default [
  {
    path: "/",
    name: "homeMain",
    component: Home,
    // component: () =>
    //   import(/* webpackChunkName: "view-home" */ `./components/Main.vue`).catch(
    //     (error) => {
    //       location.reload();
    //     }
    //   ),
  },
];
