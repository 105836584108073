<template>
  <section>
    <div class="my-account">
      <template>
        <div class="ma-profile">
          <div class="ma-profile__greeting">
            <span>{{ username }}</span>
          </div>
          <div>
            <span v-if="!isAuthenticated">
              <b>Entre ou cadastre-se</b>
            </span>
            <RazaoCardInfo
              v-if="isAuthenticated"
              theme-color="dark"
              class="form-control"
              style="margin-bottom: 10px"
              :show-as-select="true"
            />

            <div class="my-account__action-menu" @click="$emit('closeside')">
              <router-link
                tag="button"
                class="btn btn-secondary-outline"
                :to="{ name: 'MinhaConta' }"
              >
                <span class="account-shortcut-icon">
                  <i class="mdi mdi-account-outline"></i>
                </span>
                Minha Conta
              </router-link>

              <template v-if="isAuthenticated">
                <router-link
                  tag="button"
                  class="btn btn-secondary-outline"
                  :to="{ name: 'MinhaContaPedidos' }"
                >
                  <span class="account-shortcut-icon">
                    <i class="mdi mdi-file-chart-outline"></i>
                  </span>
                  Pedidos
                </router-link>

                <router-link
                  tag="button"
                  class="btn btn-secondary-outline"
                  :to="{ name: 'MinhaContaCarrinhoSalvos' }"
                >
                  <span class="account-shortcut-icon">
                    <i class="mdi mdi-cart-outline"></i>
                  </span>
                  Carrinhos salvos
                </router-link>

                <router-link
                  tag="button"
                  class="btn btn-secondary-outline"
                  :to="{ name: 'MinhaContaTitulos' }"
                >
                  <span class="account-shortcut-icon">
                    <i class="mdi mdi-file-document-multiple-outline"></i>
                  </span>
                  Títulos
                </router-link>
                <button
                  tag="button"
                  class="btn btn-secondary-outline"
                  @click="logout"
                >
                  <span class="account-shortcut-icon">
                    <i class="mdi mdi-logout-variant"></i>
                  </span>
                  Sair
                </button>
              </template>
            </div>
          </div>
        </div>
        <div v-if="!isAuthenticated" class="ma-actions">
          <router-link
            tag="button"
            class="btn btn-primary"
            :to="{ name: 'login' }"
          >
            Entrar
          </router-link>

          <router-link
            tag="button"
            class="btn btn-secondary"
            :to="{ name: 'cadastro' }"
          >
            Cadastrar
          </router-link>
        </div>
      </template>
    </div>

    <div class="container categorias">
      <h4>Categorias</h4>
    </div>
    <submenu-tree-responsive
      :item-list="$store.getters.categorias"
      @closeside="$emit('closeside')"
    />
  </section>
</template>

<script>
import SubmenuTreeResponsive from "./SubmenuTreeResponsive";
import RazaoCardInfo from "@/components/razao-card-info/RazaoCardInfo.vue";
import { mascaraCpfCnpj } from "@/app/global";

export default {
  name: "HeaderMenu",
  components: { SubmenuTreeResponsive, RazaoCardInfo },
  data() {
    return {
      subActive: false
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.getIsAuthenticated;
    },
    username() {
      // create const variable user from getUsuarioInfo in vuex
      const user = this.$store.getters.getUsuarioInfo;

      if (!user) return "Olá";
      if (!Number(user.usuario)) {
        return user.usuario;
      }

      return mascaraCpfCnpj(user.usuario);
    }
  },
  methods: {
    menuClick(codCategoria) {
      this.$router.push({
        name: "catalogoRota",
        query: {
          // page: this.$route.query.page,
          busca: this.$route.query.busca,
          categoria: codCategoria,
          fornecedor: [],
          sub: [],
          ordem: this.$route.query.ordem
        }
      });
    },
    isActiveSubMenu() {
      this.subActive = !this.subActive;
    },
    closeAllCategories() {
      this.subActive = !this.subActive;
      this.$emit("closeside");
    },
    logout() {
      Account.logout();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

@media (min-width: 767px) {
  .my-account,
  .container .categorias {
    display: none;
  }
}

.my-account {
  padding: 15px;
  .ma-profile {
    &__greeting {
      margin-bottom: 10px;
      text-transform: capitalize;
    }
    // display: flex;

    // .mp-avatar {
    //   background: gainsboro;
    //   border-radius: 50%;
    //   width: 43px;
    //   height: 43px;
    //   display: flex;
    //   padding: 0;
    //   justify-content: center;
    //   margin: 0 10px 10px 0px;
    // }
  }
  .ma-actions {
    display: flex;
    gap: 5px;
    padding: 20px 0px 0px 0px;

    button {
      flex: 1 50%;
    }
  }

  &__action-menu {
    display: flex;
    flex-direction: column;
    gap: 5px;

    button {
      width: 100%;
      text-align: left;
      border: 1px solid #e0e0e0;
    }
  }
}

.phones-sac {
  li {
    padding-top: 8px;
    background: var(--brand-primary, $brand-primary);
    padding-bottom: 8px;
    padding-left: 4px;

    a {
      font-weight: 700;
      color: #fff;
      font-size: 0.9em;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    i {
      font-size: 1.5em;
      float: left;
      padding: 10px;
      color: #fff;
    }
  }
}

a {
  cursor: pointer;
}

.ic-dep {
  padding: 2px 0px 0px 5px;
  padding-bottom: 0 !important;
  float: right;
}

.tit {
  line-height: 40px;
  font-weight: 700;
}

.arrow {
  padding-bottom: 10px;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  line-height: 0;
  font-size: 0;
  visibility: hidden;
  overflow: hidden;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    // padding: 20px 20px;
    // border-bottom: 01px solid #eee;
  }
}

.bg-menu {
  background-color: #fff;
  border-bottom: 3px solid #e5e5e5;
  position: absolute;
  width: 100%;
  margin-top: -43px;
}

.container-menu {
  padding-left: 0;

  #navbar {
    padding-left: 0;
    border: unset !important;
  }
}

.painel {
  height: 100%;
  position: absolute;
  max-height: 100%;
}

.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
  background-color: transparent;
}

.navbar-nav {
  display: -webkit-box;
  display: -webkit-inline-box;

  .menu-horizontal:nth-of-type(1n + 10) {
    display: none;
  }

  li a {
    color: #666;
    z-index: 9;
    line-height: 23px;
  }

  li a:hover {
    //background-color: lighten(#666, 53);
  }

  li.all-category a:after,
  li.all-category a:before {
    display: none;
  }

  li a:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60px;
    height: 60px;
    margin-left: -30px;
    margin-top: -30px;
    background: 0 0;
    border-radius: 100%;
    opacity: 0.6;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    transition: all 2s ease;
    background-color: #ccc;
    visibility: hidden;
    z-index: -1;
  }

  li a:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: #f1f1f1;
    opacity: 0;
    box-sizing: border-box;
    -webkit-transform: scaleX(0.1);
    transform: scaleX(0.1);
    transition: all 0.7s ease;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    z-index: -1;
  }

  li a:active:before,
  li a:hover:before {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  li a:not(:active):after {
    -webkit-animation: h_ripple 2s ease-out;
    animation: h_ripple 2s ease-out;
  }
}

.item-submenu:hover #overscreen {
  visibility: visible;
}

.dropdown {
  z-index: 9;
}

.navbar-nav > .active > a {
  background-color: transparent;
  color: #666;
}

@media (min-width: 992px) {
  .btn_login_menu,
  .menu-prev-mobile,
  ul.sub-menu {
    // display: none;
  }
}

// @media (max-width: 991px) {
.navbar-nav {
  margin: 0; //float: none;
  border: none;
}

.bg-menu {
  border-bottom: none; // z-index: 1032;
  // position: absolute;
  // left: 0;
  // top: 0;
  width: auto; // z-index: 999999;
  // height: 100%;
}

.container-menu {
  //padding-left: 15px;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  padding-right: 0;
  margin-right: 0;
  width: 260px;
}

.navbar-nav {
  li {
    display: none;
  }
}

.collapse {
  &.in {
    width: 260px;
    overflow: hidden;
    margin-right: 0;
    padding-right: 0;
    margin-left: 0;
    border: none;

    .all-category {
      z-index: 99999 !important;
      display: block;

      li {
        display: block;
      }

      .dropdown {
        .categorias {
          display: none;
          background-color: #999;
          width: 260px;
          text-align: left;
          padding-left: 15px;
          font-size: 1em;

          i {
            color: #fff;
            display: none;
          }

          .tit {
            line-height: 61px;
            color: #fff;
          }
        }

        ul.sub-menu::-webkit-scrollbar,
        .box-sub-categorias::-webkit-scrollbar {
          width: 0px !important;
          height: 0px !important;
        }

        ul.sub-menu {
          width: 260px !important;
          overflow-y: scroll;
          overflow-x: hidden;
          margin-top: 0px;
          visibility: visible;
          z-index: 99999;
          position: fixed;
          height: 100%;
          width: 260px;
          box-shadow: 0 9px 14px rgba(0, 0, 0, 0.15);
          -webkit-box-shadow: 0 9px 14px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 0 9px 14px rgba(0, 0, 0, 0.15);
          -ms-box-shadow: 0 9px 14px rgba(0, 0, 0, 0.15);
          -o-box-shadow: 0 9px 14px rgba(0, 0, 0, 0.15);
          border: none;
          margin-top: -16px;

          .sub-menu {
            width: 260px;
            left: -100%;

            &.painel {
              -webkit-transition-delay: 0s;
              -moz-transition-delay: 0s;
              -ms-transition-delay: 0s;
              -o-transition-delay: 0s;
              transition-delay: 0s;
            }
          }

          li:hover {
            background-color: #fff;
            border: none;

            .sub-menu {
              width: 261px;
              padding: 0;
              -webkit-transition: all 0.1s ease;
              -moz-transition: all 0.1s ease;
              -ms-transition: all 0.1s ease;
              -o-transition: all 0.1s ease;
              transition: all 0.1s ease;
              position: fixed;
              top: 0px; //left: 0px;
            }
          }
        }
      }
    }
  }
}

.active-submenu {
  left: 0% !important;
}

.menu-prev-mobile {
  background: #eee; //padding: 18px 15px;
  font-size: 17px;
  color: #777;

  button {
    background: transparent;
    border: none;
    margin-top: 2px;
    padding: 18px;

    i {
      color: #777;
      margin-right: 5px;
    }
  }
}

.box-sub-categorias {
  padding: 15px 18px !important;
  padding-bottom: 80px !important;
  overflow: scroll;
  overflow-x: hidden;
  height: 100%;
}

// .sub-menu {
//   a {
//     padding: 15px;
//     border-bottom: 1px solid #eee;
//   }
// }

// .sub-menu
// }
</style>

<style lang="scss">
@import "~@/assets/css/sass/bootstrap/variables";

@media (max-width: 425px) {
  .btn_login_menu {
    padding: 15px 25px !important;
  }
}

.btn_login_menu {
  background: var(--brand-accent, $brand-accent);
  border: none;
  color: #fff;
  padding: 15px 0px;
  top: 0;
  width: 260px;
  height: 93px;
  margin-bottom: -1px;

  .menu-login-header {
    margin-top: -16px !important;
    float: left !important;
    padding-left: 13px !important;
    width: 100%;

    button {
      display: block !important;
      float: left !important;
    }
  }
}
</style>
