<template>
  <div
    class="cart-product-item"
    :class="{ 'form-shake-error': !item.status || itemHasNoStock }"
  >
    <router-link
      :to="{
        name: 'produtoRota',
        params: { codigo: item.integracao_id },
      }"
      class="item-img"
      :class="{ inactive: !item.status }"
    >
      <!-- <img
        loading="lazy"
        class="img-responsive"
        :src="item.src"
        :alt="item.descricao"
        onerror="this.onerror=null;this.src='https://img.dpcnet.com.br/catalogo/imagem_indisponivel.jpg'"
      /> -->

      <ImageItem
        custom-class="img-responsive"
        :source="item.src"
        :description="item.descricao"
      />
    </router-link>

    <div class="item-info">
      <router-link
        v-if="item.descricao"
        :to="{
          name: 'produtoRota',
          params: { codigo: item.integracao_id },
        }"
        >{{ toTitleCase(item.descricao) }}</router-link
      >

      <div class="item-price">
        <span class="price-each">
          Código: <b>{{ item.integracao_id }}</b>
          <!--| EAN: <b>{{ item.ean }}</b>-->
        </span>
        <span class="price-each">
          Qtd.:
          <b
            >{{ formatQuantity(
                      item.qtd,
                      item.decim,
                      item.decim_num_casas
                    ) }} x R$
            {{
              mascaraValor(
                item.valor -
                  item.vlrdesconto -
                  item.vlrstdesonerado +
                  item.vlrst
              )
            }}</b
          >
          <strike
            v-if="item.vlrdesconto > 0 || item.vlrstdesonerado"
            class="price-old"
            >(R$ {{ mascaraValor(item.valor + item.vlrst) }})</strike
          >
        </span>
        <h5 class="price-total">
          R$
          {{
            mascaraValor(
              (item.valor -
                item.vlrdesconto -
                item.vlrstdesonerado +
                item.vlrst) *
                item.qtd
            )
          }}
          <strike
            v-if="item.vlrdesconto > 0 || item.vlrstdesonerado > 0"
            class="price-old"
          >
            (R$
            {{ mascaraValor((item.valor + item.vlrst) * item.qtd) }})
          </strike>
        </h5>
      </div>

      <small v-if="itemHasNoStock" class="text-warning">
        Reduzimos a quantidade para
        <b>{{ item.qtdestoque }}</b
        >, pois o número solicitado não está disponível.
      </small>
      <small v-if="item.haspromocaocampanha" class="text-promotion">
        <template v-if="item.campanhasAtivas">
          <small
            v-for="campanha in item.campanhasAtivas"
            v-if="
              campanha.detalhesCampanha &&
              (item.vlrdesconto > 0 || item.qtd_bonificado > 0)
            "
            :key="campanha.id"
            class="text-promotion"
          >
            <span v-if="campanha.display_discount && campanha.tpDiscount != 3">
              Desconto {{ campanha.display_discount }} -
              {{ campanha.detalhesCampanha.descricao }}
            </span>
            <span
              v-else-if="campanha.tpDiscount == 0 || campanha.tpDiscount == 2"
            >
              Desconto R$
              {{ mascaraValor(item.desconto_campanha) }}
              - {{ campanha.detalhesCampanha.descricao }}
            </span>
            <span v-else-if="campanha.tpDiscount == 1">
              Desconto {{ campanha.detalhesCampanha.desconto }}% -
              {{ campanha.detalhesCampanha.descricao }}
            </span>
            <span v-else-if="campanha.tpDiscount == 3">
              Bônus: {{ campanha.detalhesCampanha.qtd_bonus }} itens -
              {{ campanha.detalhesCampanha.descricao }}
            </span>
          </small>
        </template>
      </small>

      <!-- <span class="item-cod">Cód.: {{ item.cod_produto }}</span> -->

      <small v-if="!item.status" class="text-danger">
        Esse produto está indisponível para seus parâmetros de compra, para
        finalizar o pedido, remova-o do carrinho
      </small>
    </div>

    <item-qty-btn
      v-if="canChangeQty"
      :simple="true"
      :removeitem="!item.status"
      :item="item"
      :can-remove-all="true"
      :is-cart-or-checkout="true"
    ></item-qty-btn>
  </div>
</template>

<script>
import { mascaraValor, toTitleCase } from "@/app/global.js";
import ItemQtyBtn from "@/components/produtos/ItemQtyBtn.vue";
import ImageItem from "@/components/image/ImageItem";

export default {
  name: "CartProductItem",
  components: {
    ItemQtyBtn,
    ImageItem,
  },
  props: {
    canChangeQty: {
      default: true,
      type: Boolean,
    },
    item: {
      required: true,
      type: Object,
    },
  },
  computed: {
    itemHasNoStock() {
      return this.item.qtdestoque && this.item.qtdestoque <= this.item.qtd;
    },
  },
  methods: {
    mascaraValor,
    toTitleCase,
    formatQuantity(quantityStr, decim, decim_num_casas) {
      const quantity = this.parseQuantity(quantityStr);
      return decim ? quantity.toFixed(decim_num_casas).replace('.', ',') : Math.round(quantity);
    },
    parseQuantity(quantityStr) {
      if (typeof quantityStr === "string") {
        quantityStr = quantityStr.replace(",", ".");
      }
      return parseFloat(quantityStr);
    }
  },
};
</script>

<style lang="scss">
.item-img {
  img {
    margin: 0 auto;
    max-height: 50px;
    padding: 2px;
    max-width: 50px;
  }
}
</style>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

.cart-product-item {
  display: flex;
  padding: 10px 5px;
  border-bottom: 1px solid $gray-lighter;
  .text-promotion {
    color: #007538;
  }
  .item-img {
    height: 50px;
    width: 50px;
    min-width: 50px;
    text-align: center;
    // margin-bottom: 5px;
    display: grid;
    -webkit-box-align: center;
    -ms-flex-align: center;
    // border-left: 2px solid #1d984e;
    align-items: center;
    overflow: hidden;
    &.inactive {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }
    // img {
    //   margin: 0 auto;
    //   max-height: 50px;
    //   padding: 2px;
    //   max-width: 50px;
    // }
  }
  .item-info {
    flex: 1 1 auto;
    padding: 0 5px;
    margin-left: 10px;
    color: #666;
    display: flex;
    flex-direction: column;
    align-self: center;
    .item-cod {
      color: $gray-light;
      font-size: 13px;
      // margin: 5px 0;
      font-weight: 600;
    }
    a {
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
      color: #616161;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      font-weight: 600;
    }
  }
  .item-price {
    white-space: nowrap;
    flex: 0 1 auto;
    text-align: left;
    color: #666;
    margin: 5px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    // -ms-flex-item-align: center;
    // align-self: center;
    .price-old {
      font-size: 11px;
      font-weight: normal;
      color: #a2a2a2;
    }
    .price-total {
      margin: 1px 0;
      font-weight: 700;
      color: rgb(51, 51, 51);
    }
    .price-each {
      color: $gray-light;
      font-size: 12px;
    }
  }
  .item-qty {
    white-space: nowrap;
    flex: 0 1 auto;
    text-align: left;
    color: #666;
    padding-top: 2px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    // -ms-flex-direction: column;
    // flex-direction: column;
    // -ms-flex-item-align: center;
    align-self: center;
    .button-plus {
      border-radius: 0px 5px 5px 0px;
    }
    .button-minus {
      border-radius: 5px 0px 0px 5px;
    }
    /* For Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
    /* Webkit browsers like Safari and Chrome */
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .qty {
      max-width: 25px;
      outline: 0 !important;
      border: 1px solid transparent;
      padding: 0;
      height: 25px;
      text-align: center;
      font-size: 14px;
      &:focus {
        border-color: var(--brand-primary, $brand-primary);
        border-width: 1px;
      }
    }
    button {
      border: 0;
      margin: 0;
      font-size: 13px;
      padding: 0;
      width: 30px;
      height: 25px;
      background: transparent;
      i {
        color: $gray-light;
      }
    }
  }
  //chang it later
}
</style>
