<template>
  <div id="duvidas-frequentes">
    <section id="top-section">
      <div class="container">
        <div class>
          <h2 class="text-center">
            <strong>Ficou com alguma dúvida?</strong>
            <br />Confira abaixo as perguntas mais frequentes.
          </h2>
        </div>
      </div>
    </section>
    <section id="content-section">
      <div class="container">
        <div class="row">
          <div v-show="faqbusca ? true : false" class="col-sm-12">
            <div class="col-lg-12 col-md-12">
              <h2 class="page-title">Resultado da busca: {{ faqbusca }}</h2>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="col-md-8">
              <Tab
                v-for="(tab, index) in tabs"
                :key="index"
                :index="'tab' + tab.cod_faq_grupo"
                :cod-faq-grupo="tab.cod_faq_grupo"
                :titulo="tab.descricao"
                :conteudo="''"
                :exibir-conteudo="tab.exibir_conteudo"
                @getCodFaqGrupo="buscaPerguntaResposta"
              >
                <Tab
                  v-for="(subtab, index2) in tab.subtab"
                  :key="index2"
                  :index="
                    'tab' +
                    subtab.cod_faq_pergunta +
                    'subtab' +
                    subtab.cod_faq_resposta
                  "
                  :cod-faq-pergunta="subtab.cod_faq_pergunta"
                  :subtab="true"
                  :titulo="subtab.titulo"
                  :conteudo="subtab.conteudo"
                  :exibir-conteudo="subtab.exibir_conteudo"
                ></Tab>
              </Tab>
            </div>
            <div class="col-md-4">
              <img
                loading="lazy"
                src="../../../assets/img/atendimento/1.png"
                class="img-responsive"
                style="margin-bottom: 15px"
                alt
              />

              <div class="col-md-12 col-lg-12 frame">
                <div class="row">
                  <div class="col-md-12 dashed">
                    <div class="row">
                      <div class="col-md-2 col-xs-3 text-center">
                        <i class="fa fa-phone fa-3x" aria-hidden="true"></i>
                      </div>
                      <div class="col-md-9 col-xs-9">
                        <p>SAC</p>
                        <!-- <span>{{ getTelSac }}</span> -->
                        <span
                          ><a href="tel://0800-283-0000">0800-283-0000</a></span
                        >
                        <br />
                        <span
                          ><a href="tel://(33) 3329-0046"
                            >(33) 3329-0046</a
                          ></span
                        >
                        <br />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 dashed">
                    <div class="row">
                      <div class="col-md-2 col-xs-3 text-center">
                        <i class="fa fa-user-o fa-3x" aria-hidden="true"></i>
                      </div>
                      <div class="tel col-md-9 col-xs-9">
                        <p>Call Center</p>
                        <!-- <span>{{ getTelTelevenda }}</span>                                                 -->
                        <span
                          ><a href="tel://0800-285-0005">0800-285-0005</a></span
                        >
                        <br />
                        <span
                          ><a href="tel://0800-590-3000">0800-590-3000</a></span
                        >
                        <br />
                        <span
                          ><a href="tel://(33) 3329-0014"
                            >(33) 3329-0014</a
                          ></span
                        >
                        <br />
                        <!-- <span>(33) 3329-0314</span><br /> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-2 col-xs-3 text-center">
                        <i
                          class="fa fa-building-o fa-3x"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div class="col-md-9 col-xs-9">
                        <p>Fale conosco</p>
                        <!-- <span>{{ getFaleConosco }}</span> -->
                        <span
                          ><a href="tel://(33) 3329-0000"
                            >(33) 3329-0000</a
                          ></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Tab from "@/app/atendimento/components/Tab";
import * as global from "@/app/global";

export default {
  name: "DuvidasFrequentes",
  components: { Tab },
  metaInfo: {
    title: "Dúvidas Frequentes",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Tem alguma dúvida sobre nossa empresa? confira nosso FAQ, nele você encontra as respostas para as perguntas mais frequentes.",
      },
      {
        vmid: "keywords",
        name: "keywords",
        content:
          "fale conosco,atendimento,faq,dúvidas,perguntas mais frequentes,entrega,dpc,DPC distribuidor,Distribuidor atacadista,dpcnet,distribuidor,atacadista,distribuidora,atacado,cosméticos,cosmético,cosmetico,cosmeticos,minas gerais,mg,distribuidora",
      },
      {
        vmid: "og:title",
        name: "og:title",
        content: "Dúvidas Frequentes | DPC Distribuidor Atacadista",
      },
      {
        vmid: "og:description",
        name: "og:description",
        content:
          "Tem alguma dúvida sobre nossa empresa? confira nosso FAQ, nele você encontra as respostas para as perguntas mais frequentes.",
      },
    ],
  },
  data() {
    return {
      tabs: [],
      faqbusca: "",
    };
  },
  computed: {
    getTelTelevenda() {
      return global.mascaraTelefone(
        this.$store.getters.informacaoGlobal.tel_televenda
      );
    },
    getFaleConosco() {
      return global.mascaraTelefone(
        this.$store.getters.informacaoGlobal.fale_conosco
      );
    },
    getTelSac() {
      return global.mascaraTelefone(
        this.$store.getters.informacaoGlobal.tel_sac
      );
    },
  },
  created() {
    // PEGO A PERGUNTA QUE FOI DIGITADA
    this.faqbusca = this.$route.query.faqbusca;
    // VERIFICO SE BUSCA NAO ESTA VAZIO
    const busca_existe = global.paramExist(this.faqbusca);

    // SE NAO EXISTIR BUSCA
    if (!busca_existe) {
      // PEGO O GRUPO DE PERGUNTAS
      const grupo_pergunta = this.$store.getters.grupoPerguntas
        ? this.$store.getters.grupoPerguntas
        : [];
      // SE NAO EXISTIR GRUPO DE PERGUNTAS
      if (grupo_pergunta.length == 0) {
        // BUSCO VIA API OS DADOS
        this.buscaTodosGrupoPergunta();
      } else {
        // SE EXISTIR, APENAS PREENCHO A VARIAVEL TABS COM OS DADOS PARA SEREM EXIBIDOS
        this.preencheVarivelTabs(grupo_pergunta);
      }
    } else {
      // DECODIFICA A URL E CONVERTE PARA ARRAY
      this.faqbusca = decodeURIComponent(this.faqbusca);

      this.buscaPerguntasEspecifica(this.faqbusca);
    }
  },
  methods: {
    // FUNCAO PARA BUSCAR GRUPO DE PERGUNTAS ESPECIFICA
    buscaPerguntasEspecifica(pergunta) {
      dpcAxios
        .connection({ withCredentials: false })
        .post(
          process.env.VUE_APP_ENDERECO + api.env.VIEW_FAQ_PERGUNTA_RESPOSTA,
          {
            pergunta,
          }
        )
        .then((response) => {
          if (response.data.length > 0) {
            const grupos = [];

            // PEGO O CODIGO DO GRUPO QUE ESTA NA URL
            const cod_faq_grupo = this.$route.query.grupo;
            // VERIFICO SE O COD_FAQ_PERGUNTA NAO ESTA VAZIO
            const cod_grupo_existe = global.paramExist(cod_faq_grupo);
            // PEGO O CODIGO DA PERGUNTA QUE ESTA NA URL
            const cod_faq_pergunta = this.$route.query.pergunta;
            // VERIFICO SE O COD_FAQ_PERGUNTA NAO ESTA VAZIO
            const cod_pergunta_existe = global.paramExist(cod_faq_pergunta);

            // PERCORRE O GRUPO
            $.each(response.data, (index, dados) => {
              // SE EXISTIR COD GRUPO NA URL E SE O CODIGO FOR IGUAL AO DO GRUPO NO MOMENTO
              if (cod_grupo_existe && dados.cod_faq_grupo == cod_faq_grupo) {
                var exibir_conteudo_grupo = true;
              } else {
                var exibir_conteudo_grupo = false;
              }

              grupos.push({
                cod_faq_grupo: dados.cod_faq_grupo,
                descricao: dados.descricao,
                buscou_perguntas: true,
                exibir_conteudo: exibir_conteudo_grupo,
                subtab: [],
              });

              const perguntas = [];
              // PERCORRE AS PERGUNTAS DO GRUPO
              $.each(dados.perguntas, (key, pergunta) => {
                // SE EXISTIR COD PERGUNTA NA URL E SE O CODIGO FOR IGUAL AO DA PERGUNTA
                if (
                  cod_pergunta_existe &&
                  pergunta.cod_faq_pergunta == cod_faq_pergunta
                ) {
                  var exibir_conteudo_pergunta = true;
                } else {
                  var exibir_conteudo_pergunta = false;
                }

                perguntas.push({
                  cod_faq_pergunta: pergunta.cod_faq_pergunta,
                  cod_faq_resposta: pergunta.cod_faq_resposta,
                  titulo: pergunta.titulo,
                  conteudo: pergunta.conteudo,
                  exibir_conteudo: exibir_conteudo_pergunta,
                });
              });
              // ADICIONA AS PERGUNTAS ENCONTRADOS NA OPCAO DO ARRAY REFERENTE AO GRUPO
              grupos[index].subtab = perguntas;
            });

            this.tabs = grupos;
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
        });
    },
    // FUNCAO PARA BUSCAR TODOS OS GRUPOS DE PERGUNTAS
    buscaTodosGrupoPergunta() {
      dpcAxios
        .connection({ withCredentials: false })
        .get(process.env.VUE_APP_ENDERECO + api.env.GRUPO_PERGUNTA)
        .then((response) => {
          if (response.data.length > 0) {
            const grupo_pergunta_vuex = [];

            $.each(response.data, (index, value) => {
              grupo_pergunta_vuex.push({
                cod_faq_grupo: value.cod_faq_grupo,
                descricao: value.descricao,
              });
            });

            // SALVO NA VARIAVEL DO VUEX OS GRUPOS ENCONTRADOS
            this.$store.dispatch("setGrupoPerguntas", grupo_pergunta_vuex);

            // PASSO OS DADOS PARA FUNCAO PREENCHER A VARIAVEL TABS
            this.preencheVarivelTabs(response.data);
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
        });
    },
    preencheVarivelTabs(dados) {
      const grupo_pergunta = [];

      // MONTO UM ARRAY COM OS GRUPOS DE PERGUNTAS
      $.each(dados, (index, value) => {
        grupo_pergunta.push({
          cod_faq_grupo: value.cod_faq_grupo,
          descricao: value.descricao,
          buscou_perguntas: false,
          exibir_conteudo: false,
          subtab: [],
        });
      });

      this.tabs = grupo_pergunta;
      // PEGO O CODIGO DO GRUPO QUE ESTA NA URL
      const cod_faq_grupo = this.$route.query.grupo;
      // VERIFICO SE O COD_FAQ_GRUPO NAO ESTA VAZIO
      const cod_grupo_existe = global.paramExist(cod_faq_grupo);
      // SE EXISTIR O CODIGO DE GRUPO
      if (cod_grupo_existe) {
        let posicao_grupo = "";
        // PERCORO PARA SABER QUAL A POSICAO DO GRUPO QUE VEIO NA URL
        $.each(this.tabs, (index, value) => {
          if (value.cod_faq_grupo == cod_faq_grupo) {
            posicao_grupo = index;
          }
        });
        // BUSCO AS PERGUNTAS REFERENTES AO GRUPO
        this.buscaPerguntasRespostaViaApi(posicao_grupo, cod_faq_grupo);
      }
    },
    buscaPerguntaResposta(cod_faq_grupo) {
      let posicao_grupo = "";
      // VERIFICO A POSICAO DO GRUPO

      $.each(this.tabs, (index, value) => {
        if (value.cod_faq_grupo === cod_faq_grupo) {
          posicao_grupo = index;
        }
      });

      // SE TIVER ENCONTRADO POSICAO DO GRUPO NO ARRAY E SE NAO TIVER BUSCADO PERGUNTAS E RESPOSTA
      if (
        posicao_grupo >= 0 &&
        this.tabs.length &&
        this.tabs[posicao_grupo].buscou_perguntas === false
      ) {
        this.buscaPerguntasRespostaViaApi(posicao_grupo, cod_faq_grupo);
      }
    },
    buscaPerguntasRespostaViaApi(posicao_grupo, cod_faq_grupo) {
      dpcAxios
        .connection({ withCredentials: false })
        .post(
          process.env.VUE_APP_ENDERECO + api.env.VIEW_FAQ_PERGUNTA_RESPOSTA,
          {
            cod_faq_grupo,
          }
        )
        .then((response) => {
          if (response.data.length > 0) {
            const grupo_perguntas = [];

            // PEGO O CODIGO DA PERGUNTA QUE ESTA NA URL
            const cod_faq_pergunta = this.$route.query.pergunta;
            // VERIFICO SE O COD_FAQ_PERGUNTA NAO ESTA VAZIO
            const cod_pergunta_existe = global.paramExist(cod_faq_pergunta);

            // PERCORRE O GRUPO
            $.each(response.data, (index, dados) => {
              // PERCORRE AS PERGUNTAS DO GRUPO
              $.each(dados.perguntas, (index, pergunta) => {
                // SE EXISTIR COD PERGUNTA NA URL E SE O CODIGO FOR IGUAL AO DA PERGUNTA
                if (
                  cod_pergunta_existe &&
                  pergunta.cod_faq_pergunta == cod_faq_pergunta
                ) {
                  var exibir_conteudo = true;
                } else {
                  var exibir_conteudo = false;
                }

                grupo_perguntas.push({
                  cod_faq_pergunta: pergunta.cod_faq_pergunta,
                  cod_faq_resposta: pergunta.cod_faq_resposta,
                  titulo: pergunta.titulo,
                  conteudo: pergunta.conteudo,
                  exibir_conteudo,
                });
              });
            });

            // SALVA AS PERGUNTAS NA POSICAO DO GRUPO
            this.tabs[posicao_grupo].subtab = grupo_perguntas;
            // EXIBE AS PERGUNTAS
            this.tabs[posicao_grupo].buscou_perguntas = true;

            // PEGO O CODIGO DO GRUPO QUE ESTA NA URL
            const cod_faq_grupo = this.$route.query.grupo;
            // VERIFICO SE O COD_FAQ_GRUPO NAO ESTA VAZIO
            const cod_grupo_existe = global.paramExist(cod_faq_grupo);

            if (cod_grupo_existe) {
              this.tabs[posicao_grupo].exibir_conteudo = true;
            } else {
              this.tabs[posicao_grupo].exibir_conteudo = false;
            }
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

#top-section {
  padding-top: 20px;
  padding-bottom: 35px;
}

#content-section {
  padding-bottom: 35px;
}

.session-header {
  color: $gray;
  margin-bottom: 15px;
  font-weight: 600;
  font-family: "Montserrat";

  &.green {
    color: var(--brand-primary, $brand-primary) !important;
  }
}

.tel {
  margin: 0px 0px 10px 0px;
}

.frame {
  border: 3px solid var(--brand-accent, $brand-accent);
  border-radius: 6px;

  .dashed {
    border-bottom: 3px dashed #c2c2c2;
  }

  div > div {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;

    i {
      color: var(--brand-primary, $brand-primary);
    }
    p {
      font-weight: bold;
      -webkit-margin-before: 0em !important;
      -webkit-margin-after: 0em !important;
    }
    span {
      font-weight: 900;
      color: var(--brand-primary, $brand-primary);
      font-size: 20px;
      /* display: block; */
      position: absolute;
    }
  }
}
</style>
